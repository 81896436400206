import { Component } from '@angular/core';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { AlertController, IonRouterOutlet, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/folder/Dashboard', icon: '' },
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  subscription: any;
  constructor(private modalController:ModalController,
    public router: Router,

    private location: Location, private toastController: ToastController, private fcm: FCM, private navCtrl: NavController, private platform: Platform
  ) {

  }
  ngOnInit() {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.initializeApp()

    }

  }
  initializeApp() {
    this.platform.ready().then(() => {
      document.addEventListener("backbutton", this.goBackFromApp, false);

      //   this.fcm.getToken().then(token => {
      //     console.log(token,"Token")
      //     // this.accountService.saveDeviceToken(token);
      // });
      // this.fcm.onTokenRefresh().subscribe(token => {
      //     // this.accountService.saveDeviceToken(token);
      // });

      this.fcm.onNotification().subscribe(data => {
        if (data['wasTapped']) {
          // Handle the notification when the user taps on it
          // Show toast message
          // this.toastController.create({
          //   // header: data['bodyText'],
          //   message: data['bodyText'],
          //   duration: 3000,
          //   position: 'top'
          // }).then((toast) => {
          //   toast.present();
          // });
          // this.router.navigateByUrl('/vendor/home', { replaceUrl: true })

          // Implement your code to handle the tap event here
          this.navCtrl.navigateForward(data['link']);
            localStorage.setItem("notlINK",data['link'])
        } else {
          // Handle the notification when it's received but not tapped on
          // console.log('Notification received but not tapped on',data);
          // Show toast message
          if(data['body']){
          this.toastController.create({
            header: data['title'],
            message: data['body'],
            duration: 3000,
            position: 'top'
          }).then((toast) => {
            toast.present();
          });
        }
          this.navCtrl.navigateForward(data['link']);

        }

      })
    })

  }

  async goBackFromApp() {
    if (!window.location.href.includes('home')) {
      window.history.back()
    }

    if (window.location.href.includes('home')) {
    const alertController = new AlertController();
    const alert = await alertController.create({
      header: 'Exit App?',
      message: 'Are you sure you want to quit?',
      buttons: [
        {
          text: 'CANCEL',
          role: 'cancel'
        },
        {
          text: 'OKAY',
          handler: async () => {
            
            navigator['app'].exitApp()
          }
        }
      ]
    })
    alert.present();
  }

  }
}
