import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ArchitectsService } from '../../architects/services/architects.service';
import { CommonService } from '../../common/services/common.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-viewportfolio',
  templateUrl: './viewportfolio.component.html',
  styleUrls: ['./viewportfolio.component.scss'],
})
export class ViewportfolioComponent implements OnInit {
  portfolio
  vendorId: any;
  portfolioBrochures: any;
  slideOptsImage: { initialSlide: number; speed: number; slidesPerView: number; spaceBetween: number; prevButton: boolean; nextButton: boolean; pagination: boolean; };
  isImageModal: boolean = false;
  objectToview: any;
  object: any[];
  isCoverPic: boolean=false;
  constructor(private modalController:ModalController,private iab: InAppBrowser, private socialSharing: SocialSharing, private alertController: AlertController, private location: Location, private platform: Platform, private commonService: CommonService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.slideOptsImage = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 10,
      prevButton: true,
      nextButton: true,
      pagination: false,
    };
  }

  // download image
  downloadImageUploaded(image) {
    if(image.key){
    let link = this.commonService.download(image.key)
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link.publicUrl;
      downloadLink.setAttribute('download', "File");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }else{
    let link = `${image.publicUrl}?download=`
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link;
      downloadLink.setAttribute('download', "File");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
 }
  }
// ----View Cover Pic------------
  viewPic(url){
    this.object=[]

    if(url){
      this.isCoverPic=true
      url.document_type='image'
      this.object=[url]
    }
  }
  // ------Share Image---------
  shareImageUrl(text) {
    //console.log(this.platform)
    if (window.navigator.share) {
      window.navigator.share({
        title: 'Link shared from Octopi',
        text: text.caption,
        url: text.publicUrl
      })
        .then(() => console.log('Shared successfully')
        )
        .catch(error => console.log('Error sharing:', error)
        );

    }
    if (this.platform.is("ios") || this.platform.is("android")) {
      const link = text.publicUrl;
      // const message = text.caption;
      const message = text.caption ? text.caption : 'Link shared from Octopi';

      const subject = 'Link shared from Octopi';
      this.socialSharing.share(message, subject, null, link);
    }
    // else {
    //   // Copy the text inside the text field
    //   navigator.clipboard.writeText(text.publicUrl);

    //   // Alert the copied text
    //   this.showAlert("Success", `Link copied successfully ${text.publicUrl}`)
    // }

    // alert("Copied the text: " + text);
    // const copyElement = document.createElement("textarea");
    // copyElement.style.position = 'fixed';
    // copyElement.style.opacity = '0';
    // copyElement.textContent = text;
    // const body = document.getElementsByTagName('body')[0];
    // body.appendChild(copyElement);
    // copyElement.select();
    // document.execCommand('copy');
    // body.removeChild(copyElement);
    // alert('copied');
  }

  async showAlert(title: any, msg: any, cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass: cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }

  ionViewDidEnter() {
    this.route.queryParams.subscribe(params => {
      this.vendorId = params['vendorId'];
      this.commonService.getVendorById(params['vendorId']).then(async (value) => {
        this.portfolio = value.data[0]
      })
      if (params['vendorId'] && params['portfolioId']) {
        this.commonService.getPortfolioBrochuresByPortfolioId(params['vendorId'], params['portfolioId']).then(async (value) => {
          this.portfolioBrochures = value.data
        })
      } else {
        this.commonService.getPortfolioBrochures(params['vendorId']).then(async (value) => {
          this.portfolioBrochures = value.data
        })
      }
    })
  }
  goBack() {
    this.location.back()
  }

  play(object) {
    this.isImageModal = true
    this.objectToview = object
    //console.log(object)
  }
  openWeb(link) {
    // window.open(link, '_blank');
    //console.log(this.platform)
    if (this.platform.is("ios") || this.platform.is("android")) {
      const browser = this.iab.create(link, '_blank');
    } else {
      window.open(link, '_blank');

    }

  }

 
// -------------Dismiss All Modal------------
  async ngOnDestroy(){
    let modal = await this.modalController.getTop();
    while (modal) {
      await modal.dismiss();
      modal = await this.modalController.getTop();
    }
  }
}
