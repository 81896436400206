<ion-row size="12" class="conversationH">
<ion-col size="5"  class="flex items-center">
  <h1 class="text-1xl font-black" > &nbsp;&nbsp;CONVERSATIONS</h1> &nbsp;&nbsp;
  </ion-col>
  <ion-col size="7">

  <!-- <ion-img  *ngIf="searchTerm ==='' || !searchTerm || searchCl"  src="../../assets/icon/search.svg" style="width:24px;float: right;"></ion-img> -->
  <ion-item >
    <!-- <ion-searchbar style="padding:0" (ionChange)="filterProjects($event)"></ion-searchbar> -->
    <ion-input type="text" [(ngModel)]="searchTerm" (ionInput)="filterChat($event)"  placeholder="Search ">
    </ion-input>
    <!-- <ion-icon   (click)="popOver=false" name="search-outline" class="text-1xl mr-2"></ion-icon> -->

   </ion-item>
  <!-- <ion-popover trigger="click-trigger2" > -->
    <!-- <ng-template> -->
      <!-- <ion-list> -->
 
    <!-- </ion-list> -->
    <!-- </ng-template> -->
  <!-- </ion-popover> -->
</ion-col>
</ion-row>
<!-- <ion-buttons (click)="loadOlderMessages()" class="LoadButton ">
<span style="  border:solid 2px #dedede;border-radius:2px; padding:0.3rem;"><ion-icon name="reload-outline"></ion-icon>&nbsp;Load older messages</span> 
</ion-buttons> -->


  <ion-list lines="none" class="chatContainer" style="min-height: 100%;">

    <ion-infinite-scroll *ngIf="messages?.length>0" threshold="85%" position="top" (ionInfinite)="onIonInfinite($event)">
<p class="text-octopi-secondary text-center flex justify-center"  *ngIf="!virtualScroll && showmessage"><ion-img style="width:24px;" src="../../../../../assets/icon/gifchat.gif"></ion-img>No more messages to load</p>
    
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>   
<div *ngFor="let chat of messages;index as idx" style="align-items: center;">
  
    <div class="text-octopi-secondary dateAlign" *ngIf="messages[idx-1]?.myDate !== chat?.myDate"><span style="  border:solid 2px #dedede;border-radius:2px; padding:0.3rem;font-size: 14px;">{{chat?.created_at|date:'dd-MMM-yyyy'}}</span></div>
    <ion-item style="align-items: baseline;">
      <ion-avatar slot="start" style="margin:0" *ngIf="chat?.user_id !== userId">
        <img *ngIf="chat?.profile_photo_object_key?.publicUrl && (messages[idx-1]?.user_id !== chat?.user_id || messages[idx-1]?.myDate !== chat?.myDate)" style="aspect-ratio: 1/1;margin-bottom: 1.4rem;" src="{{chat?.profile_photo_object_key?.publicUrl
        }}">

        <img  style="aspect-ratio: 1/1;margin-bottom: 1.4rem;" *ngIf="!chat?.profile_photo_object_key?.publicUrl && messages[idx+1]?.user_id !== chat?.user_id" src="https://ionicframework.com/docs/img/demos/avatar.svg">
      </ion-avatar>
    <!-- <ion-icon class="replyBack"  (click)="reply(chat)" name="arrow-redo-sharp" *ngIf="chat?.user_id === userId"></ion-icon> -->
  
      <ion-label style="margin:3px;">
        <div class="label labelReply" *ngIf="chat?.parent_conversation_attachments?.length>0" >
          <ion-icon name="return-down-back"></ion-icon>
          <p class="text-octopi-secondary">
            {{chat?.parent_user_name
          }}</p>
          <ion-row >
<ion-col size="6"  style="text-align: center;
white-space: break-spaces;" (click)="openInmodal(idx,chat,'parent')" *ngFor="let image of chat?.parent_conversation_attachments;index as idx">
            <ion-img class="thumbnail" *ngIf="image.document_type==='image' && idx<=3" style="aspect-ratio: 1/1;width:100%;object-fit: cover;"  src="{{image?.publicUrl}}"></ion-img>
          <video  class="thumbnail"  *ngIf="image.document_type==='video' && idx<=3"  style="aspect-ratio: 1/1;object-fit: cover;" src="{{image?.publicUrl}}"  ></video>
          <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf' && idx<=3" >
            <pdf-viewer src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false" ></pdf-viewer>
          
          </div>
          <div class="thumbnail filenotsupported"  *ngIf="image.document_type!=='pdf'&& idx<=3 &&image.document_type!=='image' && image.document_type!=='video'">
            
            <ion-icon name="folder-open-outline"></ion-icon>
            <p class="text-octopi-secondary">File not supported to view.</p>
          </div>
         <ion-col  class="show-more" *ngIf="chat?.parent_conversation_attachments?.length>4 && idx===3"  >+{{getNumber(chat?.parent_conversation_attachments?.length)}} more.</ion-col>

         </ion-col>

        </ion-row>
        <p class="text-octopi-secondary" style="font-size:0.75rem">
          {{chat?.parent_conversation_created_at | date:'medium'
        }}</p>
        </div>
        <div  *ngIf="chat?.parent_conversation_id" style="position: relative;" (click)="gotoParent(chat?.parent_conversation_id)" >
          <div class="label labelReply">
            <ion-icon name="return-down-back"></ion-icon>
        <p class="text-octopi-secondary">
          {{chat?.parent_user_name
        }}</p>
    
            <!-- <h2>chat.name</h2> -->
            <p class="font-black">{{chat?.parent_conversation_message
            }}</p>
            <p class="text-octopi-secondary" style="font-size:0.75rem">
              {{chat?.parent_conversation_created_at | date:'medium'
            }}</p>
          </div>
        </div>
        <div  *ngIf="chat?.attachments.length>0" >
          <ion-row >
<ion-col size="6"  style="text-align: center;
white-space: break-spaces;" (click)="openInmodal(idx,chat,'child')" *ngFor="let image of chat?.attachments;index as idx">
            <ion-img class="thumbnail" *ngIf="image.document_type==='image' && idx<=3" style="aspect-ratio: 1/1;width:100%;object-fit: cover;"  src="{{image?.publicUrl}}"></ion-img>
          <video  class="thumbnail"  *ngIf="image.document_type==='video' && idx<=3"  style="aspect-ratio: 1/1;object-fit: cover;" src="{{image?.publicUrl}}"  ></video>
          <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf' && idx<=3" >
            <pdf-viewer src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false" ></pdf-viewer>
          
          </div>
          <div class="thumbnail filenotsupported"  *ngIf="image.document_type!=='pdf'&& idx<=3 &&image.document_type!=='image' && image.document_type!=='video'">
            
            <ion-icon name="folder-open-outline"></ion-icon>
            <p class="text-octopi-secondary">File not supported to view.</p>
          </div>
         <ion-col  class="show-more" *ngIf="chat?.attachments?.length>4 && idx===3"  >+{{getNumber(chat?.attachments?.length)}} more.</ion-col>

         </ion-col>

        </ion-row>
        </div>
        <div class="label chatBubble" *ngIf="chat?.message" [id]=chat.id [ngClass]="{'replyChatBubble':chat?.user_id !== userId}"    [ngStyle]="chat?.title?chat?.title==='New'? {'border-left':'solid 4px green'}:chat?.title ==='Closed'?{'border-left':'solid 4px red'}:{'border-left':'solid 4px #FFC107'}:{}">
      <p class="text-octopi-secondary" *ngIf="chat?.user_id !== userId">{{chat?.user_name}}</p>
      <p class="text-octopi-secondary" *ngIf="chat?.user_id === userId" > </p>

          <!-- <h2>chat.name</h2> -->
          <!-- <ion-badge  [ngStyle]="chat?.title==='Reopened'?{'background-color':'#FFC107'}:chat?.title==='Closed'?{'background-color':'red'}:{'background-color':'green'}" *ngIf="chat?.title || idx===0"> -->
            <!-- <ion-icon *ngIf="chat?.title === 'Closed' " name="close-circle-outline"></ion-icon> -->
            <!-- <ion-icon *ngIf="chat?.title === 'Reopened' " name="reload-circle-outline"></ion-icon> -->
            <!-- <ion-icon *ngIf="idx===0 " name="checkmark-circle-outline"></ion-icon>{{chat?.title? chat?.title:'Opened'}}</ion-badge> -->

          <p class="font-black" >{{chat?.message}}</p>
        </div>
      <p class="time" *ngIf="messages[idx+1]?.myTime !== chat?.myTime || messages[idx+1]?.user_id !== chat?.user_id">{{chat?.created_at | date: "h:mm a" }}</p>
    </ion-label>
    <ion-icon class="replyBack" (click)="reply(chat)" name="arrow-undo-sharp" *ngIf="chat?.user_id !== userId"></ion-icon>

    <ion-avatar slot="end" style="margin:0" *ngIf="chat?.user_id === userId ">
      <img *ngIf="chat?.profile_photo_object_key?.publicUrl && (messages[idx-1]?.user_id !== chat?.user_id || messages[idx-1]?.myDate !== chat?.myDate)" style="aspect-ratio: 1/1;margin-bottom: 1.4rem;" src="{{chat?.profile_photo_object_key?.publicUrl
      }}">
      <img *ngIf="!chat?.profile_photo_object_key?.publicUrl && messages[idx-1]?.user_id !== chat?.user_id" style="aspect-ratio: 1/1;margin-bottom: 1.4rem;" src="https://ionicframework.com/docs/img/demos/avatar.svg">
    </ion-avatar>
      
    

    </ion-item>
  </div>


     <ion-row class="message" *ngIf="messages?.length==0">
      <p class="font-black">No message found</p>
        <!-- <p>Type your first message below.</p> -->
     </ion-row>
  
     <p  #sendButton></p>
 
  </ion-list>

  <ion-footer class="stickyFooter">
    <ion-label  *ngIf="showReply">
      <span class="close-icon" *ngIf="showReply" style="float: right;
      font-size: 2rem;
      margin: 0.5rem;" (click)="removeReply()">&times;</span>

        <div *ngIf="showReply" class="replyPart">
          
      <p class="text-octopi-secondary">{{replyChat?.user_name}}</p>

          <!-- <h2>chat.name</h2> -->
          <p class="font-black">{{replyChat?.message}}</p>
        </div>

    </ion-label>
    <ion-label>
      <ion-row>
        <!-- <span > -->
        <ion-col size="4" style="aspect-ratio: 1/1;  
        white-space: break-spaces;" [ngClass]="{'displayNone': idx>5}" (click)="openInModalUploaded(idx)" *ngFor="let image of uploadedImages; index as idx">
          <ion-img class="thumbnail" *ngIf="image.document_type==='image' && idx<6" style="aspect-ratio: 1/1;width:100%;"  src="{{image?.publicUrl}}"></ion-img>
          <video  class="thumbnail"  *ngIf="image.document_type==='video' && idx<6"  style="aspect-ratio: 1/1;" src="{{image?.publicUrl}}"  ></video>
          <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf' && idx<6" >
            <pdf-viewer src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false" ></pdf-viewer>
          
          </div>
          <div class="thumbnail filenotsupported"  *ngIf="image.document_type!=='pdf' &&image.document_type!=='image' && image.document_type!=='video'&& idx<6">
            
            <ion-icon name="folder-open-outline"></ion-icon>
            <p class="text-octopi-secondary">File not supported to view.</p>
          </div>
         <ion-col  class="show-more" *ngIf="uploadedImages?.length>6 && idx===5"  >+{{getNumberUpload(uploadedImages?.length)}} more.</ion-col>

               </ion-col>
              <!-- </span> -->
             </ion-row>
             

      <input  multiple type='file'  id="fileInput" #fileInput style="display: none;" (change)="uploadFileFromInput($event)"/>
    </ion-label>
    <ion-toolbar  >
     <div class="displaySend">
      
      <ion-buttons id="chat-status" slot="start" class="alignButton" *ngIf="statusChanger" >
        <div ><ion-icon [ngStyle]="status === 'Closed'?{'color':'red'}:status === 'Reopened'?{'color':'#FFC107'}:{}" name="sync-circle-outline"  ></ion-icon></div>
      </ion-buttons>
      <ion-popover trigger="chat-status" triggerAction="click" *ngIf="statusChanger">
        <ng-template>
          <ion-list>
            <ion-item   [button]="true" [detail]="false" style="color:#FFC107">
              <ion-checkbox (ionChange)="statusChange('Reopened')" slot="start" [checked]="status === 'Reopened'" style="margin: 0;"></ion-checkbox>&nbsp;
              <ion-icon name="reload-circle-outline" style="color:#FFC107"></ion-icon>
              &nbsp;Reopen</ion-item>
            <ion-item   [button]="true" [detail]="false" style="color:red"> 
              <ion-checkbox (ionChange)="statusChange('Closed')" slot="start" [checked]="status === 'Closed'" style="margin: 0;"></ion-checkbox>&nbsp;
              <ion-icon  name="checkmark-circle-outline" style="color:red"></ion-icon>&nbsp; Close</ion-item>
    </ion-list>
        </ng-template>
      </ion-popover>
      <!-- <ion-buttons id="click-trigger"  slot="start" class="alignButton"  >

</ion-buttons> -->
      <ion-textarea   class="sendLabel"  placeholder="Type your message" [(ngModel)]="message"></ion-textarea>
      <ion-buttons slot="end" class="alignButton" (click)="uploadFile()" >
        <div ><ion-icon src="../../../../assets/icon/icon-attachment.svg"   class="ml-1" ></ion-icon></div>
      </ion-buttons>
      <ion-buttons slot="end" class="alignButton" (click)="sendMessage()" >
        <div><ion-icon src="../../../../assets/icon/icon-send.svg" class="ml-1" ></ion-icon></div>
      </ion-buttons>
    </div>
    </ion-toolbar>
  </ion-footer>
<!-- Ion ion-modal -->
<ion-modal [isOpen]="uploadOpen||isImageModal">

  <ng-template>
    <ion-header  style="z-index: auto;">
      <ion-toolbar>
        <!-- <ion-title>{{(!isView)?'Add an MOM':'View MOM'}}</ion-title> -->
        <ion-buttons slot="end">
          <ion-icon name="close-circle-outline" style="font-size:xx-large" (click)="closeModal()"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding">

<app-galleryupload [portfolioImages]="false" (UploadedAllImage)="uploadMore($event)" (onDialogClose)="closeModal()" [isEdit]="isEdit" *ngIf="isImageModal||uploadOpen" [slidesImage]="slidesImage" [activeIndex]="activeIndex" [isImageModal]="isImageModal" [uploadOpen]="uploadOpen" [uploadedImagesModal]="uploadedImages" (removeUploaded)="removeUpload($event)"></app-galleryupload>
</ion-content>
    </ng-template>
    </ion-modal>
    