<div style="align-items: center;" class="flex">

  <h1 class="text-1xl  uppercase text-octopi-secondary">{{(doc)?' DOCUMENTS ':'PHOTOS/ VIDEOS '}}

  </h1>
  <ion-icon name="add-circle-outline" *ngIf="!isEdit" (click)="selectFile()" style="font-size:x-large"></ion-icon>
</div>


<ion-row *ngIf="(images?.length===0 || !images) "> <ion-col class="text-center m-2 text-octopi-secondary">
  </ion-col></ion-row>
<!-- <ion-grid>
      <ion-row>

        <ion-col size="4" style="padding:0.2rem" *ngFor="let image of images">
          <ion-icon name="close-circle-outline" style="text-align: right;" (click)="removeImage(image)"></ion-icon>

          <div class="thumb-container">

            <img class="thumbnail" *ngIf="image.document_type!=='video'" style="aspect-ratio: 1/1;"  src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)" />
            <video  class="thumbnail" *ngIf="image.document_type==='video'" style="aspect-ratio: 1/1;" src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)"></video>
</div>
        </ion-col>

      </ion-row>
    </ion-grid> -->
<!-- <ion-grid>
      <div class="masonry-container">
          <div class="masonry-item image-container" *ngFor="let image of images">

            <img  *ngIf="image.document_type!=='video'" style="height:100%;"  src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)" />
            <video  *ngIf="image.document_type==='video'"  src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)"></video>
            <span class="close-icon" (click)="removeImage(image)">&times;</span>
          </div>
        </div>
    </ion-grid> -->
<!-- <ion-slides pager="true" [options]="slideOpts" (ionSlideDidChange)="updateActiveIndex()">
    <ion-slide *ngFor="let image of images" style="flex-direction: column; min-height:15rem;" >
      <ion-img  *ngIf="image.document_type!=='video'" [src]="image.publicUrl" style="aspect-ratio: 3/2;" (click)="showImage(image.publicUrl)"></ion-img>
    <video *ngIf="image.document_type==='video'" src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)"></video>

     <ion-row>{{image?.caption}}</ion-row>
    </ion-slide>
  </ion-slides>
  <ion-slides-pager (click)="slidesPage($event)"></ion-slides-pager> -->
<!-- <ngx-gallery-preview [images]="galleryImages" [customTemplate]="previewTemplate"></ngx-gallery-preview> -->
<!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages" style="width:100%" [preview]="false" [thumbnails]="true"></ngx-gallery> -->
<ngx-masonry [options]="masonryOptions" [ordered]="true" *ngIf="loadAllImages">
  <div ngxMasonryItem [prepend]="false" class="masonry-items " (click)="showImage(image.publicUrl,idx)"
    [ngClass]="{'masonry-items-height':   masonryImages.length>3}" *ngFor="let image of masonryImages;index as idx">
    <!-- <div class="m-1"></div> -->
    <img *ngIf="image.document_type==='image'" [src]="image.publicUrl" width="100%">
    <video *ngIf="image.document_type==='video'" style="object-fit:fill;height:100%;width:100%;"
      src="{{image?.publicUrl}}">
    </video>
    <ion-icon style="width:100%;height:100%" *ngIf="image.document_type!=='image' && image.document_type!=='video'"
      class="text-octopi-secondary" name="document-text-outline"></ion-icon>

    <!-- <img  [src]="image.publicUrl"  width="100%"> -->

    <!-- <ngx-doc-viewer  *ngIf="image.document_type==='application'" 
url={{image?.publicUrl}} 

  viewer="url"
 
></ngx-doc-viewer> -->
  </div>
  <div class="show-more" *ngIf="images?.length>5" (click)="showImage('image',0)">+{{getValue()}} more.</div>


</ngx-masonry>
<ion-modal [isOpen]="uploadModal">
  <ng-template>
    <ion-header style="z-index: auto;">
      <ion-toolbar>
        <!-- <ion-title>{{(!isView)?'Add an MOM':'View MOM'}}</ion-title> -->
        <ion-buttons slot="end">
          <ion-icon name="close-circle-outline" style="font-size:xx-large" (click)="setOpen(false)"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <!-- <ion-item> -->

      <ion-col size="9">
        <!-- <button *ngIf="images.length===0"  ion-button placeholder="choose" (click)="selectFiles()">
              <ion-icon name="cloud-upload-outline" style="font-size:xx-large"></ion-icon> Upload Images/ Video
            </button> -->

        <input type="file" multiple accept="image/*, video/*" *ngIf="!doc" placeholder="choose" id="fileInput"
          #fileInput style="display: none;" (change)="uploadFileFromInput($event)">
        <input type="file" multiple *ngIf="doc" placeholder="choose" id="fileInput" #fileInput style="display: none;"
          (change)="uploadFileFromInput($event)">
      </ion-col>
      <!-- </ion-item> -->
      <!-- <ion-item> -->
      <!-- <ion-col size="9"><ion-input type="text"  [(ngModel)]="fileName" placeholder="Caption"></ion-input></ion-col> -->
      <!-- </ion-item> -->
      <!-- <ion-grid>
            <ion-row>
              <ion-col size="4" *ngFor="let image of images">

                  <ion-icon name="close-circle-outline" style="text-align: right;" (click)="removeImage(image)"></ion-icon>
                <ion-card>
                
                  <ion-img  *ngIf="image.document_type!=='video'" [src]="image.publicUrl" style="aspect-ratio: 3/2;" (click)="showImage(image.publicUrl)"></ion-img>
                  <video *ngIf="image.document_type==='video'" src="{{image?.publicUrl}}"  (click)="showImage(image.publicUrl)"></video>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid> -->
      <ion-row style="min-height: 45vh;" *ngIf="imagesUpload?.length===0 || !imagesUpload"> <ion-col
          class="text-center m-auto text-octopi-secondary">
          <p>Nothing here ...yet</p>
        </ion-col></ion-row>
      <ion-grid>
        <ion-row>
          <ion-col id="CarousalVideo" #CarousalVideo size="12" *ngFor="let image of imagesUpload">

            <!-- <div class="thumb-container"> -->
            <ion-row>
              <ion-icon name="trash" class="trash" (click)="removeImage(image)"></ion-icon>

              <ion-img class="thumbnail" *ngIf="image.document_type==='image'" style="aspect-ratio: 1/1;width:100%;"
                src="{{image?.publicUrl}}"></ion-img>
              <video class="thumbnail" *ngIf="image.document_type==='video'" controls style="aspect-ratio: 1/1;"
                src="{{image?.publicUrl}}"></video>
              <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf'">
                <pdf-viewer [zoom]="pdfZoom" src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false"></pdf-viewer>
             <div class="flex justify-center">
                  
                <ion-col size="3">
                    <ion-button class="w-full"  (click)="zoomIn()" placeholder="choose">+</ion-button>
                    </ion-col>
                    <ion-col size="3">
                      <ion-button class="w-full"  (click)="zoomOut()" placeholder="choose">-</ion-button>
                      </ion-col>
                      </div>
              </div>
              <div class="thumbnail filenotsupported"
                *ngIf="image.document_type!=='pdf' &&image.document_type!=='image' && image.document_type!=='video'">

                <ion-icon name="folder-open-outline"></ion-icon>
                <p class="text-octopi-secondary">File not supported to view.</p>
              </div>
              <!-- <ngx-doc-viewer  *ngIf="image.document_type==='application'" 
                  url={{image?.publicUrl}}
                  controls style="aspect-ratio: 1/1;"
                    viewer="url"
                    style="width:100%;height:50vh;"
                  ></ngx-doc-viewer> -->
            </ion-row>
            <!-- </div> -->
            <ion-item size="12" class="ion-no-padding">
              <ion-input type="text" class="w-100 mb-3" [(ngModel)]="image.caption" placeholder="Caption"></ion-input>
            </ion-item>
            <ion-item size="12" class="ion-no-padding w-full" *ngIf="image.document_type==='image' && !isEdit">
              <ion-checkbox slot="start" (ionChange)="onCheckboxChange(image)" [(ngModel)]="image.coverPic"
                style="margin: 0;"></ion-checkbox>&nbsp;
              Make Cover photo
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-row>
      <ion-col size="6">
        <ion-button class="w-full" (click)="selectFiles()" placeholder="choose">
          <ion-icon name="arrow-up-circle-outline"></ion-icon> {{(images?.length===0)?' Upload':' Add More'}}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button class=" w-full" placeholder="choose" (click)="saveUpload()">
          Save
        </ion-button>
      </ion-col>

    </ion-row>

  </ng-template>

</ion-modal>
<ion-modal [isOpen]="isImageModal">
  <ng-template>
    <ion-header style="z-index: auto;">
      <ion-toolbar>
        <!-- <ion-title>{{(!isView)?'Add an MOM':'View MOM'}}</ion-title> -->
        <ion-buttons slot="end">
          <ion-icon name="close-circle-outline" style="font-size:xx-large" (click)="isImageModal=false"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" #CarousalVideos id="CarousalVideos">
      <ion-slides id="slides" #slides [options]="slideOptsImage" (ionSlideDidChange)="updateActiveIndex()">
        <ion-slide *ngFor="let image of imagesUpload;index as idx" class="slideContent" style="flex-direction: column">
          <ion-row style="flex-direction: column;width:100%">
            <ion-icon name="arrow-back" *ngIf="imagesUpload[idx-1]" (click)="onPrevSlide()" style="left:0"
              class="arrow-icon"></ion-icon>
            <img *ngIf="image.document_type==='image'" [src]="image.publicUrl" style="aspect-ratio: 2/2;" />
            <video *ngIf="image.document_type==='video'" src="{{image?.publicUrl}}" controls>

            </video>

            <!-- <ngx-extended-pdf-viewer pdfSrc="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'"   backgroundColor="#ffffff"
  [height]="'90vh'"
>
</ngx-extended-pdf-viewer> -->
            <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf'">
              <pdf-viewer [zoom]="pdfZoom" src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false"></pdf-viewer>
             <div class="flex justify-center">
              <ion-col size="3">
                <ion-button class="w-full"  (click)="zoomIn()" placeholder="choose">+</ion-button>
                </ion-col>
                <ion-col size="3">
                  <ion-button class="w-full"  (click)="zoomOut()" placeholder="choose">-</ion-button>
                  </ion-col>
                </div>
            </div>
            <div class="filenotsupported" style="aspect-ratio: 2/2;"
              *ngIf="image.document_type!=='pdf' &&image.document_type!=='image' && image.document_type!=='video'">

              <ion-icon name="folder-open-outline" style="margin-top: 5.5rem;"></ion-icon>
              <p class="text-octopi-secondary">File not supported. Please download to view this document.</p>
            </div>
            <!-- <ngx-doc-viewer  *ngIf="image.document_type==='application'" 
url={{image?.publicUrl}}

  viewer="url"
  style="width:100%;height:50vh;"
></ngx-doc-viewer> -->
            <ion-icon name="arrow-forward" *ngIf="imagesUpload[idx+1]" style="right:0" (click)="onNextSlide()"
              class="arrow-icon"></ion-icon>
          </ion-row>
          <ion-row size="12" class="mt-4 ml-2 captionalign">
            <ion-col size="9">
              <h1 class="text-1xl font-bold uppercase text-octopi-secondary">Caption</h1>

            </ion-col>

          </ion-row>
          <ion-row class="captionalign ml-2">
            <ion-item style="width:93%" class="ion-no-padding">

              <ion-textarea rows="3" style="width:100%" [(ngModel)]="image.caption"  [disabled]="isEdit"
                placeholder="Caption"></ion-textarea>

            </ion-item>
            <ion-item s class="ion-no-padding w-full" *ngIf="image.document_type==='image' && !isEdit">
              <ion-checkbox slot="start" (ionChange)="onCheckboxChange(image)" [(ngModel)]="image.coverPic"
                style="margin: 0;"></ion-checkbox>&nbsp;
              Make Cover photo
            </ion-item>
          </ion-row>

          <ion-row style="width:100%;justify-content: center;">
            <ion-col size="3">
              <ion-button class=" w-full" placeholder="choose" (click)="shareImageUrl(image)">
                <ion-icon name="share-social-outline"></ion-icon>
                <!-- Share  -->
              </ion-button>
            </ion-col>
            <ion-col size="3">
              <ion-button class=" w-full" placeholder="choose" (click)="downloadImageUploaded(image)">
                <ion-icon name="arrow-down-circle-outline"></ion-icon>
                <!-- Download  -->
              </ion-button>
            </ion-col>
            <ion-col size="3" *ngIf="!isEdit">
              <ion-button class="w-full" (click)="saveCaption(image)" placeholder="Update">
                <ion-icon name="save"></ion-icon>
                <!-- Update -->
              </ion-button>
            </ion-col>
            <ion-col size="3" *ngIf="!isEdit">
              <ion-button class=" w-full" placeholder="choose" (click)="removeImageUploaded(image)">
                <ion-icon name="trash"></ion-icon>
                <!-- Delete  -->
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-slide>

      </ion-slides>
    </ion-content>
  </ng-template>
</ion-modal>