import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController, IonSlides, Platform } from '@ionic/angular';
import { CommonService } from 'src/app/modules/common/services/common.service';

@Component({
  selector: 'app-slideshow-portfolios',
  templateUrl: './slideshow-portfolios.component.html',
  styleUrls: ['./slideshow-portfolios.component.scss'],
})
export class SlideshowPortfoliosComponent implements OnInit {
  @Input()
  imagesUpload
  @Input()
  brochure
  @Input()
  autoPlay
  activeIndex: number;
  slideOptsImage = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    prevButton: true,
    nextButton: true,
    pagination: false,
  };
  constructor(private commonService:CommonService,private alertController:AlertController,private platform:Platform,private socialSharing:SocialSharing) { }
  @ViewChild(IonSlides) slides: IonSlides;
  
  ngOnInit() {}

  onNextSlide() {
    this.slides.slideNext();



    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');
      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement.pause();
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement1 = document.getElementById('CarousalVideos');

      // Get all the video elements in the carousel
      const videos = videoElement1.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement1.pause();
    }

  }

  onPrevSlide() {
    this.slides.slidePrev();

    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');

      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement = document.getElementById('CarousalVideos');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }

  }

  startAutoSlide() {
    this.slides.startAutoplay();
  }
  stopAutoPlay(){
    this.slides.stopAutoplay()
  }
  updateActiveIndex() {
    this.slides.getActiveIndex().then(index => {
      this.activeIndex = index;
    });
  }

  shareImageUrl(text) {
    if (window.navigator.share) {
      window.navigator.share({
        title:'Link shared from Octopi' ,
        text: text.caption,
        url: text.publicUrl
      })
        .then(() => console.log('Shared successfully'))
        .catch(error => console.log('Error sharing:', error));
    
  }
    if (this.platform.is("ios") || this.platform.is("android")) {

      const link = text.publicUrl;
      const message = text.caption?text.caption:'Link shared from Octopi';
      const subject = 'Link shared from Octopi';

      this.socialSharing.share(message, subject, null, link);
    }

  }

  async showAlert(title: any, msg: any,cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass:cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }
  downloadImageUploaded(image) {
    console.log(image)
    let link  
    if(image.key){

     link = this.commonService.download(image.key)
     console.log(link,"hghg")
     let downloadLink
     if (link) {
       downloadLink = document.createElement('a');
       downloadLink.href = link.publicUrl;
       downloadLink.setAttribute('download', "File");
       document.body.appendChild(downloadLink);
       downloadLink.click();
     }
  }else{
     link = `${image.publicUrl}?download=`
     console.log(link)
     let downloadLink
     if (link) {
       downloadLink = document.createElement('a');
       downloadLink.href = link;
       downloadLink.setAttribute('download', "File");
       document.body.appendChild(downloadLink);
       downloadLink.click();
     }
  }
  


  }

}
