import { Injectable } from '@angular/core';
import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientServiceService {
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);

   }
  // updateUser()

  getProjectById(clientId: any) {
    return this.supabase
    .from('projects_home_page')
    .select('*')
    .eq('client_id',clientId)
    .order('project_start_date',{ ascending: true })

    .then((result) => result.data);

  
  }
  getProjectByprojectId(projectId){
    return this.supabase
    .from('projects_home_page')
    .select('*')
    .eq('project_id',projectId)

    .then((result) => result.data);
  }
  getClientById(id){
    return this.supabase
  .from('clients')
  .select("*")
  .eq('user_id', id)
  .then((result) => result.data);

  }
  getClientDetailById(id){
    return this.supabase
    .from('client_detail')
    .select("*")
    .eq('user_id', id)
    .then((result) => result.data);
  }
  updateClient(clientId,value){
  return this.supabase
  .from('clients')
  .update(value)
  .eq('id', clientId)
  .then((result) => result);

  }
  // ----------Toget mom based on momId---------
  getAllMOMsById(projectId,momId){
    return this.supabase
    .from('project_moms_detail')
    .select('*')
    .eq('project_id', projectId)
    .eq('id', momId)
   .then((result) => result.data);
  }
// -------To get all users like ticket-
  getAlllikedusers(ticketid){
    return this.supabase
    .from('fav_event_user_details')
    .select('*')
    .eq('event_id', ticketid)
  .then((result) => result);

  }
    // ---------------To get All events based on projectId------------
    getAllEventsByRangeandFilter(userId,projectId,  start,end, range){
      return this.supabase
      .from('timeline_home_page')
      .select('*')
      .eq('project_id', projectId)
    .eq('user_id', userId)

      .range(range,range+10)
      .gte('created_at_date', start)
      .lte('created_at_date', end)
      .then((result) => result);
    }

    // -------Toget project doc by Id-
  getAllProjectDocsById(docid){
    return this.supabase
    .from('project_documents')
    .select('*')
    .eq('id', docid)
    .then((result) => result.data);
  }

  // ----Get site visit by id-----
  getSiteVisitId(siteId){
    return this.supabase
    .from('site_visits_detail_page')
    .select('*')
    .eq('id', siteId)
   

    .then((result) => result.data)
  }
// --Doc details by id--
getProjectDocsById(id) {
  return this.supabase
    .from('project_documents')
    .select('*')
    .eq('id', id)

    .then((result) => result.data);
}
  getAllProjectDocs(id) {
    return this.supabase
      .from('project_documents')
      .select('*')
      .eq('project_id', id)
      .order('created_at', { ascending: true }).then((result) => result.data);
  }
// -Get ticket by id-
  getTicketById(id) {
    return this.supabase
      .from('tickets_detail_page')
      .select('*')
      .eq('id', id)
      .then((result) => result.data);
  }

  // ---------Like post---------
  likePost(obj){
    return this.supabase
    .from('user_fav_events')
    .insert([
    obj
    ])
  .then((result) => result);

  }

  // --------Remove like from post---------
  removelikePost(ticketId,userId){
    return this.supabase
  .from('user_fav_events')
  .delete()
  .eq('event_id',ticketId )
  .eq('user_id',userId )
  .then((result) => result);

  }
}
