<ion-app>
  <ion-tabs>
    <ion-content fullscreen class="ion-padding ">
      <ion-row class="mt-10 ml-6 pt-5">
        <ion-col style="padding-left:0">
          <ion-icon class="text-3xl" (click)="back()" name="arrow-back-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <div class="cover-photo  pt-5"
        style="background-image: url({{profileDetails?.value?.cover_photo_object_key?.publicUrl}}); ">
        <h1 class="text-center text-octopi-secondary alignTextCover" style="padding:1rem;height:100%; background-color: #f1f1f1;"
          *ngIf="!profileDetails?.value?.cover_photo_object_key?.publicUrl">
          <ion-icon class="noCoverPic" name="images-outline"
            *ngIf="!profileDetails?.value?.cover_photo_object_key?.publicUrl"></ion-icon> No Cover photo added
        </h1>
        <div class="profile-pic">
          <img src="{{profileDetails?.value?.profile_photo_object_key?.publicUrl}}"
            *ngIf="profileDetails?.value?.profile_photo_object_key?.publicUrl" />
          <h1 class="text-center text-octopi-secondary alignTextCover"
            *ngIf="!profileDetails?.value?.profile_photo_object_key?.publicUrl" style="padding:1rem;">
            <ion-icon name="camera" style="font-size: 4rem;"></ion-icon>

          </h1>

        </div>
        <a class="edit-icon" *ngIf="profileDetails?.value?.profile_photo_object_key?.publicUrl" (click)="uploadOpenCoverLogo(profileDetails?.value?.profile_photo_object_key)"><ion-icon name="eye" (click)="uploadOpenCoverLogo('logo')"></ion-icon></a>
        <ion-fab vertical="top" horizontal="end" *ngIf="profileDetails?.value?.cover_photo_object_key?.publicUrl">
          <ion-fab-button color="primary" (click)="uploadOpenCoverLogo(profileDetails?.value?.cover_photo_object_key)">
            <ion-icon name="eye"  (click)="uploadOpenCoverLogo(profileDetails?.value?.cover_photo_object_key)"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
      <ion-grid class="pb-7 pl-7 pr-7 form-grid">

        <form [formGroup]="profileDetails">

          <div class="p-1"></div>
          <ion-row class="mt-7">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Name</h1>
              <ion-item class="ion-no-padding text-octopi-secondary font-black">
              {{profileDetails.value.name}}
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">About Me</h1>
              <ion-item class="ion-no-padding text-octopi-secondary font-black">
              {{profileDetails.value.about_me}}

              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Role</h1>
              <ion-item class="ion-no-padding">

                <p class="text-octopi-secondary font-black">{{roleName}}</p>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Birthday</h1>
              <ion-item class="ion-no-padding text-octopi-secondary font-black">
              {{profileDetails.value.birthday}}
                
              </ion-item>
            </ion-col>
          </ion-row>
          <!-- <ion-col>
                            <h1 class="text-1xl  uppercase text-octopi-secondary">Role</h1>
                            <ion-item  class="ion-no-padding">
                              <ion-input type="text" class="text-octopi-secondary font-black" [(ngModel)]="roleName" stand [readonly]="true"  placeholder="Role">
                              </ion-input>
                            </ion-item>
                          </ion-col> -->


          <ion-row>

            <ion-item class="w-full">
              <ion-icon name="call" slot="start"  class="m-1"></ion-icon>
              {{profileDetails.value.phone}}

             
            </ion-item>
          </ion-row>
          <ion-row class="mt-5">
            <ion-item class="w-full">

              <ion-icon name="mail" slot="start" class="m-1"></ion-icon>
              {{profileDetails.value.email}}

              
            </ion-item>
          </ion-row>


        </form>

        
      </ion-grid>
     
    </ion-content>
   <!-- --------------View cover pic & profile pic-------------- -->
   <ion-content>
    <ion-modal [isOpen]="isImageModal">
      <ng-template>
        <ion-header style="z-index: auto;">
          <ion-toolbar>
            <!-- <ion-title>{{objectToview?.title}}</ion-title> -->
            <ion-buttons slot="end">
              <ion-icon name="close-circle-outline" style="font-size:xx-large"
                (click)="isImageModal=false"></ion-icon>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
        

          <app-slideshow-portfolios [imagesUpload]="object"
            [autoPlay]=false [brochure]="[]"
            *ngIf="isImageModal"></app-slideshow-portfolios>
        </ion-content>
      </ng-template>
    </ion-modal>

  </ion-content>
  </ion-tabs>
</ion-app>