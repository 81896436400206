import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { VendorsservicesService } from '../services/vendorsservices.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-details-view',
  templateUrl: './project-details-view.component.html',
  styleUrls: ['./project-details-view.component.scss'],
})
export class ProjectDetailsViewComponent implements OnInit {
  teamMembers: any;
  team: any;
  projectTypes
  projectUpdate:FormGroup
  projectId: any;
  isImageModal: boolean=false;
  object: any;
  constructor(private route:ActivatedRoute,private projectUpdateBuilder:FormBuilder,
    private location: Location,

    private authService:AuthService,private vendorSerrvice:VendorsservicesService) {
  this.createForm()
    this.authService.getCurrentTeam().subscribe(async (value) => {
      this.team = value;
    })
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
    })
   }

  ngOnInit() {

    this.vendorSerrvice.getTeammembers(this.team?.team).then(async (data) => {
      this.teamMembers = data;
    })
    this.vendorSerrvice.projectById(this.projectId).then((data)=>{
      if(data){
        data[0].lat_value=parseFloat(data[0].lat_value)
        data[0].long_value=parseFloat(data[0].long_value)
        this.projectUpdate.patchValue(data[0])

      }
    })
  }

  // --To open google map-------
onMarkerClick(lat,long){
  window.open(`https://maps.google.com/?q=${lat},${long}`, '_blank');

}
  createForm(){
    this.projectUpdate = this.projectUpdateBuilder.group({
      project_name:['',[Validators.required]],
      project_description:['',[Validators.required]],
      tag_names:[[]],
      project_start_date:[''],
      project_end_date:[''],
      type_name:['',[Validators.required]],
      client_name
:['',[Validators.required]],
      address:['',[Validators.required]],
      internal_notes:['',[Validators.required]],
     is_active:[true],
     voice_memo_object_key:[null],
     lat_value:[''],
     long_value:[''],
     logo_object_key:[''],
     cover_photo_object_key:[''],
     project_team_members:[[]]
    })
  }
  getClientName(id){

  }
  getTagName(id){

  }
  goBack(){
    this.location.back();
  }

  viewPic(url){
    this.object=[]

    if(url){
      this.isImageModal=true
      url.document_type='image'
      this.object=[url]
    }
  }
}
