import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientServiceService } from '../services/client-service.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-tickets',
  templateUrl: './client-tickets.component.html',
  styleUrls: ['./client-tickets.component.scss'],
})
export class ClientTicketsComponent implements OnInit {
  ticketView: any;
  projectDocs: any;
  slidesImage: any;
  docView: boolean=false;

  constructor(private route:ActivatedRoute,private clientService:ClientServiceService,private router:Router,private modalController:ModalController) {
    this.route.queryParams.subscribe(params => {
      if (params['view'] && params['ticketId']) {
        this.clientService.getTicketById(params['ticketId']).then(async (data) => {
         this.ticketView=data[0]
        })
      }
      this.clientService.getAllProjectDocs(params['projectId']).then(async (data) => {
        this.projectDocs = data
      })
    })
   
   }
   goback(){
    this.router.navigate(['/client/home'])

  }
  ngOnInit() {}
// --To view doc in slide--------------
openViewModal(projectDocId) {
  this.clientService.getProjectDocsById(projectDocId).then(async (data) => {
    if(data[0]){
    this.slidesImage = data[0].storage_object_id

    this.docView = true
    }
  })
}

  getProjectDocName(pId) {
    return this.projectDocs?.filter((id) => id.id === pId)[0]?.name
  }

    // -------------Dismiss All Modal------------
  async ngOnDestroy(){
    let modal = await this.modalController.getTop();
    while (modal) {
      await modal.dismiss();
      modal = await this.modalController.getTop();
    }
  }
}
