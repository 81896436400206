/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { createClient, SupabaseClient, User } from '@supabase/supabase-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private supabase: SupabaseClient;
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentTeam: BehaviorSubject<any> = new BehaviorSubject(null);
  private profileUpdated=new BehaviorSubject(false);
  private filteredValue=new BehaviorSubject(false);
  private sortUpdated=new BehaviorSubject(false);
  private timelinestartDate=new BehaviorSubject(null);
  private timelineendDate=new BehaviorSubject(null);
  profileUpdateClicked=this.profileUpdated.asObservable()
  filterValueChanged=this.filteredValue.asObservable()
  SortSelected=this.sortUpdated.asObservable()
  timeLineStartDate=this.timelinestartDate.asObservable()
  timeLineEndDate=this.timelineendDate.asObservable()
  apiCallInProgress: boolean;

  constructor(private router: Router) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);

    this.supabase.auth.onAuthStateChange((event, sess) => {
     

      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {

        this.currentUser.next(sess?.user);
    this.loadUserTeam();

      } else {
        this.currentUser.next(null);
      }
    });

    this.loadUser();
    this.loadUserTeam();
  }
  profileActivated(data){
    this.profileUpdated.next(data)
  
   }
   filterValue(e){
    this.filteredValue.next(e)
   }
   sortProject(e){
    this.sortUpdated.next(e)
   }
   startDate(startDate){
    this.timelinestartDate.next(startDate)
   }

   endDate(endDate){
    this.timelineendDate.next(endDate)

   }
  async loadUser() {
    if (this.currentUser.value) {
      // //console.log('ALREADY GOT USER');
      return;
    }
    const user  = await this.supabase.auth.getUser();

    // //console.log('🚀 ~ file: auth.service.ts ~ line 33 ~ AuthService ~ loadUser ~ session', user);

    if (user.data.user) {
      this.currentUser.next(user.data.user);
    } else {
      this.currentUser.next(null);
    }
  }

  async loadUserTeam() {
    if (this.currentTeam.value) {
      // //console.log('ALREADY GOT USER');
      return;
    }
    const currentuser:any = await this.supabase.auth.getUser();
    let currentTeam:any;
    if (currentuser.data.user) {
     
       await this.supabase
        .from('team_members_detail')
        .select(`*`)
        .match({ user_id: currentuser.data.user.id })
        .single()
        .then(async(result) => {if(result.data){
          currentTeam =result.data

        }
      else{
        await this.supabase
        .from('vendors_detail')
        .select(`*`)
        .match({ user_id: currentuser.data.user.id })
        .single()
        .then(async (vendor) => {
        if(vendor.data){
          currentTeam=vendor.data
        }    else{
          await this.supabase
          .from('client_detail')
          .select(`*`)
          .match({ user_id: currentuser.data.user.id })
          .single()
          .then((client) => {
          currentTeam=client.data

          })

        }
      }) ;
      }
      if (currentTeam) {
        this.currentTeam.next(currentTeam);
      } else {
      
      this.currentTeam.next(null);
  
      }
    }) 
      
    }else{
      if(!this.router.url.includes('forgot-password')){
        this.signOut();

      }
    }
  

    // //console.log('🚀 ~ currentTeam', currentTeam);

  }

  async loadTeamDetails(teamId:any) {
    let currentOrganization:any;
    if (teamId) {
      return currentOrganization = await this.supabase
        .from('teams')
        .select(`*`)
        .match({ id: teamId })
        .single()
        .then((result) => result.data);

      // //console.log('currentOrganization', currentOrganization);
      // return currentOrganization;
    }
  }
  updatePassowrd(email,password){
  return this.supabase.auth.updateUser({
    email: email,
    password: password,
  })
}

  signUp(credentials: { email:any; password:any }) {
    return this.supabase.auth.signUp(credentials);
  }

  signIn(credentials: { email:any; password:any }) {
    return this.supabase.auth.signInWithPassword(credentials);
  }

  signInWithEmail(email: string) {
    const redirectTo = isPlatform('capacitor') ? 'supachat://login' : `${window.location.origin}/dashboard`;
    // //console.log('set redirect: ', redirectTo);

    return this.supabase.auth.signInWithOtp({ email, options: { emailRedirectTo: redirectTo } });
  }

  sendPwReset(email:any) {
    return this.supabase.auth.resetPasswordForEmail(email);
  }

  async signOut() {
    this.currentUser.next(null);

    await this.supabase.auth.signOut();
    await this.currentTeam.next(null)

    setTimeout(() => {
     this.router.navigateByUrl('/auth/login', { replaceUrl: true });
      
    }, 250);
  }

  getCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable();
  }
  getPublicUrl(url){
    const { data } = this.supabase
    .storage
    .from('octopi-media')
    .getPublicUrl(url)
  return data

  }
  getroletoscreenmapping(){
    return this.supabase
    .from('roletoscreenmapping')
    .select('*')
  .then((result) => result.data);
  }
  getVendorTokenById(userId){
    return this.supabase
    .from('vendors')
    .select('*')
    .eq('user_id',userId)
  .then((result) => result.data);
  }

  getVendorById(id){
   
    return this.supabase
    .from('vendors_detail')
    .select('*')
    .eq('user_id',id)
  .then((result) => result.data);
  }
  getTeamById(id){
   return this.supabase
    .from('team_members_detail')
    .select('*')
    .eq('user_id',id)
  .then((result) => result.data);

  }
  getTeamByTeamId(id){
    return this.supabase
     .from('team_members_detail')
     .select('*')
     .eq('id',id)
   .then((result) => result.data);
 
   }

  updateUser(id,obj){
    return this.supabase
    .from('team_members')
    .update(obj)
    .eq('id', id)
    .then((result) => result);
  }

  updatevendor(id,obj){
    return this.supabase
    .from('vendors')
    .update(obj)
    .eq('user_id', id)
    .then((result) => result);
  }
  // ---To mark user has logged in for first time---
  updateTemmember(userId,firstLogin){
    return this.supabase
  .from('team_members')
  .update({ first_login: firstLogin })
  .eq('user_id', userId)
  .then((result) => result);

  }
  updateClient(userId,firstLogin){
    return this.supabase
    .from('clients')
    .update({ first_login: firstLogin })
    .eq('user_id', userId)
    .then((result) => result);
  }

  updateVendor(userId,firstLogin){
    return this.supabase
    .from('vendors')
    .update({ first_login: firstLogin })
    .eq('user_id', userId)
    .then((result) => result);
  }
  getCurrentTeam(): Observable<any> {

    // if (this.currentTeam) {
    //   this.apiCallInProgress=false

      return this.currentTeam.asObservable();
    // }else{
    //   if(!this.apiCallInProgress){
    //     this.loadUser();
    //     this.loadUserTeam();
    //     this.apiCallInProgress=true
    //   }
    //   return this.currentTeam.asObservable();
    // }
  }
  getCurrentUserId(): string {
    if (this.currentUser.value) {
      return (this.currentUser.value as User).id;
    } else {
      return '';
    }
  }

  async setSession(access_token:any, refresh_token:any) {
    alert();
    return this.supabase.auth.setSession({ access_token, refresh_token });
  }

  validateEmail(emailInput){
    return this.supabase
  .from('all_users_detail')
  .select('*')
  .eq('user_email', emailInput)
  .then((data)=> data)
  }

  // GetallUserDetails 
  getUserDetails(userId){
    return this.supabase
  .from('all_users_detail')
  .select('*')
  .eq('user_id', userId)
  .then((data)=> data)
  }
}
