<ion-app>
  <ion-tabs>
    <ion-content fullscreen class="ion-padding ">
      <div class="p-2"></div>
      <div class="cover-photo mt-5 pt-5"
        style="background-image: url({{profileDetails?.value?.cover_photo_object_key?.publicUrl}}); ">
        <h1 class="text-center text-octopi-secondary alignTextCover" style="padding:1rem; background-color: #f1f1f1;"
          *ngIf="!profileDetails?.value?.cover_photo_object_key?.publicUrl">
          <ion-icon class="noCoverPic" name="images-outline"
            *ngIf="!profileDetails?.value?.cover_photo_object_key?.publicUrl"></ion-icon> Add Cover photo
        </h1>
        <div class="profile-pic">
          <img src="{{profileDetails?.value?.profile_photo_object_key?.publicUrl}}"
            *ngIf="profileDetails?.value?.profile_photo_object_key?.publicUrl" />
          <h1 class="text-center text-octopi-secondary alignTextCover"
            *ngIf="!profileDetails?.value?.profile_photo_object_key?.publicUrl" style="padding:1rem;">
            <ion-icon name="camera" style="font-size: 4rem;"></ion-icon>

          </h1>

        </div>
        <a class="edit-icon" (click)="uploadOpenCoverLogo('logo')"><ion-icon name="camera" (click)="uploadOpenCoverLogo('logo')"></ion-icon></a>
        <ion-fab vertical="top" horizontal="end">
          <ion-fab-button color="primary" (click)="uploadOpenCoverLogo('coverPhoto')">
            <ion-icon name="camera"  (click)="uploadOpenCoverLogo('coverPhoto')"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
      <ion-grid class="pb-7 pl-7 pr-7 form-grid">

        <form [formGroup]="profileDetails">

          <div class="p-1"></div>
          <ion-row class="mt-7">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Name</h1>
              <ion-item class="ion-no-padding">
                <ion-input type="text" formControlName="name" class="font-black" placeholder="Username">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">About Me</h1>
              <ion-item class="ion-no-padding">
                <ion-input type="text" class="font-black" formControlName="about_me" placeholder="About Me">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Role</h1>
              <ion-item class="ion-no-padding">

                <p class="text-octopi-secondary font-black">{{roleName}}</p>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl mt-2 font-black uppercase text-octopi-secondary">Birthday</h1>
              <ion-item class="ion-no-padding">
                <input type="date" formControlName="birthday" style="width:100%" />
              </ion-item>
            </ion-col>
          </ion-row>
          <!-- <ion-col>
                            <h1 class="text-1xl  uppercase text-octopi-secondary">Role</h1>
                            <ion-item  class="ion-no-padding">
                              <ion-input type="text" class="text-octopi-secondary font-black" [(ngModel)]="roleName" stand [readonly]="true"  placeholder="Role">
                              </ion-input>
                            </ion-item>
                          </ion-col> -->


          <ion-row>

            <ion-item class="w-full">
              <ion-icon name="call" slot="start"  class="m-1"></ion-icon>

              <ion-input type="tel" class=" font-black" formControlName="phone" placeholder="Phone">
              </ion-input>
            </ion-item>
          </ion-row>
          <ion-row class="mt-5">
            <ion-item class="w-full">

              <ion-icon name="mail" slot="start" class="m-1"></ion-icon>

              <ion-input type="text" class="text-octopi-secondary font-black" formControlName="email" [readonly]="true"
                placeholder="Email">
              </ion-input>
            </ion-item>
          </ion-row>


        </form>

        <div>
          <a ng-href="#">
            <ion-icon (click)="edit()" name="checkmark-outline" class="icon-overlay"></ion-icon>
          </a>
        </div>
      </ion-grid>
      <ion-grid class="mt-5 ml-7">
        <ion-row>
          <span (click)="cancelBck()" class="text-1xl font-black uppercase text-octopi-secondary">CANCEL</span>
        </ion-row>
      </ion-grid>
    </ion-content>
    <!-- Add image -->
    <ion-content class="ion-padding">
      <ion-modal [isOpen]="isSingleUpload">
        <ng-template>
          <ion-header style="z-index: auto;">
            <ion-toolbar>
              <!-- <ion-title>{{(!isView)?'Add an MOM':'View MOM'}}</ion-title> -->
              <ion-buttons slot="end">
                <ion-icon name="close-circle-outline" style="font-size:xx-large"
                  (click)="isSingleUpload=false"></ion-icon>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

          <ion-content>
            <app-single-upload *ngIf="isSingleUpload" [imagesUpload]="passImageValue"
              (saveUrl)="saveImageUrl($event)"></app-single-upload>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ion-content>
  </ion-tabs>
</ion-app>