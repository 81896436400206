import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientServiceService } from '../services/client-service.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-project-doc',
  templateUrl: './client-project-doc.component.html',
  styleUrls: ['./client-project-doc.component.scss'],
})
export class ClientProjectDocComponent implements OnInit {
  projectDocData: any;

  constructor(private router:Router,private route:ActivatedRoute,private clientService:ClientServiceService,private modalController:ModalController) {
    this.route.queryParams.subscribe(params => {

      if(params['projectdocId'] && params['view']){
        this.clientService.getAllProjectDocsById(params['projectdocId']).then(async (doc) => {
        this.projectDocData=doc[0]
        })
      }
    })
   }

  ngOnInit() {}
  goback(){
    this.router.navigate(['/client/home'])

  }
    // -------------Dismiss All Modal------------
    async ngOnDestroy(){
      let modal = await this.modalController.getTop();
      while (modal) {
        await modal.dismiss();
        modal = await this.modalController.getTop();
      }
    }

  
}
