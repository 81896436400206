import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-teammemberdetails',
  templateUrl: './teammemberdetails.component.html',
  styleUrls: ['./teammemberdetails.component.scss'],
})
export class TeammemberdetailsComponent implements OnInit {
  profileDetails: FormGroup;
  roleName: any;
  imageUrl: any;
  object: any[];
  isImageModal: boolean=false;

  constructor(private route: ActivatedRoute,private authService:AuthService,
    private location: Location,
    private profileDetailsBuilder:FormBuilder,
    ) { 
      this.createForm()

    this.route.queryParams.subscribe(params => {

      this.authService.getTeamByTeamId(params['teammemberId']).then(async(value)=>{
        this.profileDetails.patchValue(value[0])
        this.roleName=value[0].role_name
        if(value[0].profile_photo_object_key){
this.imageUrl=value[0].profile_photo_object_key
        }
      })
    })
  }

  ngOnInit() {
  
  }
  uploadOpenCoverLogo(url){
    this.object=[]

    if(url){
      this.isImageModal=true
      url.document_type='image'
      this.object=[url]
    }
  }
  createForm(){
    this.profileDetails = this.profileDetailsBuilder.group({
      name:['',[Validators.required]],
      email:['',[Validators.required]],
      phone:['',[Validators.required]],
      profile_photo_object_key:[''],
      cover_photo_object_key:[''],
      about_me:[''],
      birthday:['',[Validators.required]],
      website_address:[{}]
    })
  }
  back(){
    this.location.back();

  }
}
