import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-map-viewer',
  templateUrl: './google-map-viewer.component.html',
  styleUrls: ['./google-map-viewer.component.scss'],
})
export class GoogleMapViewerComponent implements OnInit {
@Input()
projectLat
@Input()
projectLong
@Input()
address
  constructor() { }

  ngOnInit() {}
// --To open google map-------
onMarkerClick(lat,long){
  window.open(`https://maps.google.com/?q=${lat},${long}`, '_blank');

}
}
