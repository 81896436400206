<!-- <ion-content class="ion-padding" > -->
  <ion-row style="min-height: 45vh;" *ngIf="!imagesUpload?.publicUrl">  <ion-col class="text-center m-auto text-octopi-secondary" >
    <p>Nothing here ...yet</p></ion-col></ion-row>
  <ion-grid *ngIf="imagesUpload?.publicUrl">
    <ion-row>
      <ion-col  size="12" >

        <!-- <div class="thumb-container"> -->
          <ion-row>
            <ion-icon name="trash" class="trash"  (click)="removeImage()"></ion-icon>

          <img class="thumbnail" style="aspect-ratio: 1/1;min-height:20rem;"  src="{{imagesUpload?.publicUrl}}"   />
        </ion-row>
<!-- </div> -->
<ion-item size="12"  class="ion-no-padding">
<ion-input type="text"   class="w-100 mb-3" [(ngModel)]="imagesUpload.caption" placeholder="Caption"></ion-input>
</ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- </ion-content> -->
  <ion-row>
    <ion-col size="6">
      <input type="file" accept="image/*" placeholder="choose" id="fileInput" #fileInput style="display: none;" (change)="uploadFileFromInput($event)">
      <ion-button class="w-full"  (click)="selectFile()" placeholder="choose" >
        <ion-icon name="arrow-up-circle-outline"></ion-icon> {{(!imagesUpload)?' Upload':' Change'}}
    </ion-button>
</ion-col>
    <ion-col size="6">
      <ion-button class=" w-full" placeholder="choose" (click)="saveUpload()">
        Update 
    </ion-button>
    </ion-col>
   
  </ion-row>