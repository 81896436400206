
<ion-app>
  <ion-tabs>
<ion-content class="ion-padding">
  <ion-grid class="pt-5 ml-6 mt-10">
    <ion-row>
      <ion-col style="padding-left:0">
        <ion-icon class="text-3xl" (click)="goback()"
           name="arrow-back-outline"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="cover-photo-form" style="background-image: url({{viewSiteVisit?.cover_pic_object_key}}); ">
    <h1 class="text-center text-octopi-secondary alignTextCover"
      style="padding:4rem;height:150px; background-color: #f1f1f1;"
      *ngIf="!viewSiteVisit?.cover_pic_object_key">
      <ion-icon class="noCoverPic" name="images-outline"
        *ngIf="!viewSiteVisit?.cover_pic_object_key"></ion-icon> Cover photo not added
    </h1>

    <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->

  </div>
  <ion-grid class="p-7 form-grid">
    <ion-row>
      <h1 class="text-2xl uppercase text-octopi-primary poppinsFamily">Site Visit</h1>
    </ion-row>

    <ion-row>
      Site Visit report by {{viewSiteVisit?.created_by_name}} on {{viewSiteVisit?.created_at
      |date:'dd-MM-yyyy'}}
      @{{time}}
    </ion-row>
    <ion-row class="mt-5">
      <ion-col class="p-0">

        <h1 class="text-1xl uppercase text-octopi-secondary">CLIENT</h1>

        <p class="font-black">{{viewSiteVisit?.client_name}}</p>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <app-file-upload [isEdit]="true" [doc]="false"
          [images]="viewSiteVisit?.site_document_object_keys" *ngIf="viewSiteVisit?.site_document_object_keys.length>0"></app-file-upload>

      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">COMMENTS</h1>
        <ion-item class="ion-no-padding font-black">
          {{viewSiteVisit?.comments}}

        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">Time</h1>
        <ion-item class="ion-no-padding font-black">
          {{time}}

        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">MEETING DATE</h1>
        <ion-item class="ion-no-padding font-black">
          {{date}}
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">Location</h1>
     
      </ion-col>

    </ion-row>
   
    <app-google-map-viewer [projectLat]=projectLat [projectLong]=projectLong [address]="address"></app-google-map-viewer>

    <ion-row class="mt-5">
      <ion-col>
        <ion-col class="flex items-center p-0">

          <h1 class="text-1xl  uppercase text-octopi-secondary">ATTENDEES</h1> &nbsp;&nbsp;

        </ion-col>
        <ion-col class="flex items-center p-0 ">

          <h1 class="text-octopi-secondary">Team Members</h1>

        </ion-col>
        <ion-row class="tags">

          <ion-col>
            <ion-item class="ion-no-padding font-black" *ngFor="let name of viewSiteVisit?.attendee_names">
              <!-- <ion-avatar class="avatar-container">
                    <ion-img #image loading-text="Loading image..."
                    class="mt-3 mb-2 avatar-thumbnail" src="{{team.url}}" 
                 
                    ></ion-img></ion-avatar> -->
              <ion-icon name="person-circle-outline" style="font-size:small"></ion-icon>
              &nbsp;{{name}}
              <!-- <ion-icon  *ngIf=" (click)="removeTeamMember(option)" name="close-circle" slot="end" ></ion-icon> -->
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-col class="flex items-center mt-5 p-0 text-octopi-secondary">

          <h1>Vendors</h1>
          <!-- <ion-icon name="add-circle-outline" *ngIf="!isView" (click)="setTeamMemberOpen(true)"
              style="font-size:x-large"></ion-icon> -->
        </ion-col>
        <ion-row class="tags">

          <ion-col *ngIf="viewSiteVisit?.attended_vendor_org_names.length>0">
            <ion-item class="ion-no-padding font-black"
              *ngFor="let vendor of viewSiteVisit?.attended_vendor_org_names">
              <ion-icon name="construct-sharp" style="font-size:small"></ion-icon>
              &nbsp;{{vendor}}
              <!-- <ion-icon  *ngIf="!isView" (click)="removeVendorFromForm(option)" name="close-circle" slot="end" ></ion-icon> -->
            </ion-item>
          </ion-col>
      
            <p *ngIf="viewSiteVisit?.attended_vendor_org_names.length===0">No vendors attended</p>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <ion-col class="flex items-center">

          <h1 class="text-1xl  uppercase text-octopi-secondary">Minutes</h1> &nbsp;&nbsp;

        </ion-col>

        <!-- <ion-content style="height:300px;overflow: auto;">
                      <div class="row header">
                        <div class="col"></div>
                        <div class="col">Description</div>
                        <div class="col">Action By</div>
                        <div class="col">Date</div>
                      </div>
                      <div class="row" *ngFor="let data of momForm?.value?.minutes; index as i">
                        <div class="col">{{i+1}}</div>
                        <div class="col">{{data?.Description}}</div>
                        <div class="col">{{data?.ActionBy}}</div>
                        <div class="col">{{data?.Date}}</div>
                      </div>
                  </ion-content> -->
        <ngx-datatable [externalSorting]="true" (sort)="onSort($event)" style="box-shadow: none;"
          class="material container striped" [rows]="viewSiteVisit?.minutes" [columnMode]="'flex'"
          [headerHeight]="50" [footerHeight]="50" rowHeight="auto">
          <ngx-datatable-column [sortable]="false" name="#" [flexGrow]="0.5">
            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
              {{(rowIndex + 1)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="true" name="Description" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.Description }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Action By" [flexGrow]="3">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="ellipssis">{{row?.ActionBy}}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="true" name="Date" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="ellipssis"> {{row?.Date | date:'dd/MM/yyyy'}}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="" [flexGrow]="0.5">
            <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
              <ion-icon (click)="setMinutesOpen(true,row)" style="float: right;padding-top: 0;"
                name="eye-outline"></ion-icon>

            </ng-template>
          </ngx-datatable-column>


        </ngx-datatable>
      </ion-col>
    </ion-row>



  </ion-grid>

</ion-content>

  <!-- View minutes -->
  <ion-content class="ion-padding">
    <ion-modal [isOpen]="isMinutesOpen">
      <ng-template>
        <ion-header style="z-index: auto;">
          <ion-toolbar>
            <!-- <ion-title>Add Minutes</ion-title> -->
            <ion-buttons slot="end">
              <ion-icon name="close-circle-outline" style="font-size:xx-large"
                (click)="isMinutesOpen=false"></ion-icon>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">

          <ion-grid class="p-7 form-grid">
            <ion-row>
              <ion-col>

                <h1 class="text-2xl uppercase text-octopi-primary font-black">
                  MINUTES</h1>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>

                <h1 class="text-1xl  uppercase text-octopi-secondary">Description</h1> &nbsp;&nbsp;
                <ion-item class="ion-no-padding font-black">

                  <p>{{rowMinutes.Description}}</p>
                </ion-item>

              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>

                <h1 class="text-1xl  uppercase text-octopi-secondary">Action By</h1> &nbsp;&nbsp;
                <ion-item class="ion-no-padding font-black">


                  <p>{{rowMinutes.ActionBy}}</p>

                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>

                <h1 class="text-1xl  uppercase text-octopi-secondary">Date</h1> &nbsp;&nbsp;
                <ion-item class="ion-no-padding font-black">

                  <p>{{rowMinutes.Date}}</p>

                </ion-item>
              </ion-col>
            </ion-row>

          </ion-grid>

        </ion-content>
      </ng-template>
    </ion-modal>

  </ion-content>
</ion-tabs>
</ion-app>
