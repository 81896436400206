<div style="height: 500px; width: 100%;" class="map-container">
  <agm-map  [latitude]="projectLat" [longitude]="projectLong" style="width:100%; height:500px;" >
    <agm-marker [latitude]="projectLat" [longitude]="projectLong"  (markerClick)="onMarkerClick(projectLat,projectLong)">
    <!-- <agm-marker [latitude]="userlatitude" [longitude]="userlongitude" (markerClick)="onMarkerClick(userlatitude,userlongitude)" [iconUrl]="'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'"></agm-marker> -->
    <agm-info-window>
      {{address}}
    </agm-info-window>
  </agm-marker>
 
  </agm-map>
  <div class="heading-container">
    <ion-icon name="location" style="color:red"></ion-icon> Address
    <h1>{{address }}</h1>
  </div>
  </div>
