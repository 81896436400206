import { Injectable } from '@angular/core';
import { SupabaseClient, createClient, RealtimeChannel } from '@supabase/supabase-js';
import { ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const TEAM_DB = 'team_members';
const MESSAGES_DB = 'messages';
export interface Message {
  created_at: string;
  group_id: number;
  id: number;
  text: string;
  user_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private supabase: SupabaseClient;
  private realtimeChannel: RealtimeChannel;

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
  }

  getTeammembers(teamId:any) {
    return this.supabase
      .from(TEAM_DB)
      .select(`
    email,
    name,
    id,
    phone,
    team,
    profile_photo_object_key,
    cover_photo_object_key,
    roles(*)
  `).match({ team: teamId })
      .then((result) => result.data);
  }
  getFilteredTem(teamId, search, sort){
    return this.supabase
    .from('team_members')
    .select(`
    email,
    name,
    id,
    phone,
    team,
    profile_photo_object_key,
    cover_photo_object_key,
    roles(*)
  `)
    .eq('team', teamId)
    .ilike('name', `%${search}%`)

    .order('created_at', { ascending: sort==='new-to-old'?false:true })
    .then((result) => result.data)
  }
 
  uploadFile(formData: any, fileName: any) {
    // return this.supaBaseUpload.storage.from('octopi-media').upload(fileName,formData)
    return this.supabase.storage.from('octopi-media').upload(fileName, formData);
  }
  getPublicUrl(url) {
    const { data } = this.supabase
      .storage
      .from('octopi-media')
      .getPublicUrl(url)
    return data

  }
  getRoles() {
    return this.supabase
    .from('roles')
    .select('*').then((result) => result.data);
  }
  getDesignation(){
    return this.supabase
    .from('roles')
    .select('*')
    .eq('is_architect',true).then((result) => result.data);
  }
  async createUser(mail:any) {
    const credentials = {
      email: mail,
      password: 'Testme123'
    };
    return this.supabase.auth.signUp(credentials);
  }
 
  async createTeamMember(name:any, mail:any, ph:any,roleId:any, userId:any, teamId:any) {
    const newgroup = {
      name: name,
      email: mail,
      phone: ph,
      role: roleId,
      team: teamId,
      user_id: userId,
      first_login:true,
      profile_photo_object_key:{publicUrl:'https://ionicframework.com/docs/img/demos/avatar.svg'}

    };
    return this.supabase.from(TEAM_DB).insert(newgroup).select().single();
  }

  getGroupById(id:any) {
    return this.supabase
      .from(TEAM_DB)
      .select(`created_at, title, id, users:creator ( email, id )`)
      .match({ id })
      .single()
      .then((result) => result.data);
  }

  async addGroupMessage(groupId:any, message:any) {
    const newMessage = {
      text: message,
      user_id: (await this.supabase.auth.getUser()).data?.user?.id,
      group_id: groupId,
    };

    //console.log('NEW: ', newMessage);

    return this.supabase.from(MESSAGES_DB).insert(newMessage);
  }

  getGroupMessages(groupId:any) {
    // TODO: Limit and sort
    return this.supabase
      .from(MESSAGES_DB)
      .select(`created_at, text, id, users:user_id ( email, id )`)
      .match({ group_id: groupId })
      .then((result) => result.data);
  }

  listenToGroup(groupId:any) {
    const changes = new Subject();

    this.realtimeChannel = this.supabase
      .channel('public:messages')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'messages' }, async (payload) => {
        //console.log('DB CHANGE: ', payload);

        if (payload.new && (payload.new as Message).group_id === +groupId) {
          const msgId = (payload.new as any).id;
          //console.log('load message: ', msgId);

          const msg = await this.supabase
            .from(MESSAGES_DB)
            .select(`created_at, text, id, users:user_id ( email, id )`)
            .match({ id: msgId })
            .single()
            .then((result) => result.data);
          changes.next(msg);
        }
      })
      .subscribe();

    return changes.asObservable();
  }
  getVendorById(vendorId){
    return this.supabase
    .from('vendors_detail')
    .select('*')

    .eq('id',vendorId)

    .then((result) => result);
  }
  getPortfolioBrochures(vendorId){
    return this.supabase
    .from('portfolio')
    .select('*')

    .eq('vendor_id',vendorId)
    .eq('publish',true)

    .then((result) => result);
  }
  getPortfolioBrochuresByPortfolioId(vendorId,portfolioId){
    return this.supabase
    .from('portfolio')
    .select('*')

    .eq('vendor_id',vendorId)
    .eq('id',portfolioId)

    .then((result) => result);
  }
  unsubscribeGroupChanges() {
    if (this.realtimeChannel) {
      //console.log('REMOVE CHANNEL');
      this.supabase.removeChannel(this.realtimeChannel);
    }
  }
  download(url) {

    const { data } = this.supabase.storage
      .from('octopi-media')
      .getPublicUrl(url, {
        download: true,
      })
    return data

  }
}
