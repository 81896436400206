import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { AlertController, IonSlides, LoadingController, Platform } from '@ionic/angular';
import { ArchitectsService } from '../../services/architects.service';
import { v4 as uuidv4 } from 'uuid';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-galleryupload',
  templateUrl: './galleryupload.component.html',
  styleUrls: ['./galleryupload.component.scss'],
})
export class GalleryuploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(IonSlides) slides: IonSlides;
  @Input()

  slidesImage
  @Input()
  portfolioImages
  @Input()
  isImageModal
  @Input()
  uploadedImagesModal
  @Input()
  isEdit
  @Input()

  uploadOpen

  @Output() removeUploaded = new EventEmitter();
  @Output() onDialogClose = new EventEmitter();
  @Output() UploadedAllImage = new EventEmitter();
  @Input()
  activeIndex
  slideOptsImage = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    prevButton: true,
    nextButton: true,
    pagination: false,
  };
  constructor(private sanitizer: DomSanitizer, private platform: Platform, private alertController: AlertController, private loadingController: LoadingController,
    private fileChooser: FileChooser, private socialSharing: SocialSharing, private architectsService: ArchitectsService) { }
  
    pdfZoom = 1.0;

  zoomIn() {
    this.pdfZoom += 0.1;
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
  }
    sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  saveCaption(image) {
    this.showAlert("Updated successfully!", '')
  }

  shareImageUrl(text) {
    if ( window.navigator.share) {
      window.navigator.share({
        title: 'Link shared from Octopi',
        text: text.caption,
        url: text.publicUrl
      })
        .then(() => console.log('Shared successfully'))
        .catch(error => console.log('Error sharing:', error));

    }
   else if (this.platform.is("ios") || this.platform.is("android")) {

      const link = text.publicUrl;
      // const message = text.caption;
      const message = text.caption ? text.caption : 'Link shared from Octopi';

      const subject = 'Link shared from Octopi';
      this.socialSharing.share(message, subject, null, link);
    }
    // } else {
    //   // Copy the text inside the text field
    //   navigator.clipboard.writeText(text.publicUrl);

    //   // Alert the copied text
    //   this.showAlert("Success", `Link copied successfully ${text.publicUrl}`)
    // }

    // alert("Copied the text: " + text);
    // const copyElement = document.createElement("textarea");
    // copyElement.style.position = 'fixed';
    // copyElement.style.opacity = '0';
    // copyElement.textContent = text;
    // const body = document.getElementsByTagName('body')[0];
    // body.appendChild(copyElement);
    // copyElement.select();
    // document.execCommand('copy');
    // body.removeChild(copyElement);
    // alert('copied');
  }

  async showAlert(title: any, msg: any) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: ['OK'],
    });
    await alert.present();
  }
  downloadImageUploaded(image) {
    if(image.key){
    let link = this.architectsService.download(image.key)
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link.publicUrl;
      downloadLink.setAttribute('download', image.fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }else{
    let link = `${image.publicUrl}?download=`
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link;
      downloadLink.setAttribute('download', "File");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
 }
  }
  async selectFiles() {

    if (this.platform.is('cordova')) {
      const file = await this.fileChooser.open();
      this.uploadFileFromInput(file);
    } else {
      this.fileInput.nativeElement.click();
    }
  }
  async uploadFileFromInput(event) {
    if (event) {
      const loading = await this.loadingController.create();

      await loading.present();
      for (let i = 0; i < event.target.files.length; i++) {
    
        this.architectsService.uploadFile(event.target.files[i], uuidv4()).then(async (data) => {
          let obj = {
            "key": data.data.path,
            "publicUrl": this.architectsService.getPublicUrl(data.data.path).publicUrl,
            "caption": "",
            "fileName": event.target.files[i].name,
            "document_type": event.target.files[i].type.slice(0, event.target.files[i].type.lastIndexOf("/")) === 'application' ? event.target.files[i].type.slice(event.target.files[i].type.lastIndexOf("/") + 1, event.target.files[i].type.length) : event.target.files[i].type.slice(0, event.target.files[i].type.lastIndexOf("/"))
          }
          if(i === event.target.files.length-1){
            await loading.dismiss();

          }

          this.uploadedImagesModal?.push(obj)



        })
      }

    }
  }
  onCheckboxChange(cover) {
    if (cover.coverPic) {
      this.uploadedImagesModal.map((e) => {
        if (e.key !== cover.key) {
          e.coverPic = false
        }
      }

      )
    }
  }
  saveUpload() {
    this.UploadedAllImage.emit(this.uploadedImagesModal);
    this.uploadOpen = false
    this.onDialogClose.emit(true)
  }
  setOpen(boolean) {
    this.uploadOpen = boolean
  }
  ngOnInit() {
    this.slideOptsImage = {
      initialSlide: this.activeIndex,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 10,
      prevButton: true,
      nextButton: true,
      pagination: false,
    }
  }
  removeUpload(urls, idx) {
    //  this.uploadedImagesModal=this.uploadedImagesModal.splice(idx,1)
    this.removeUploaded.emit(urls);

  }
  removeImage(url) {

    this.uploadedImagesModal = this.uploadedImagesModal?.filter((idx) => idx != url)
    this.slidesImage = this.slidesImage?.filter((idx) => idx != url)
    this.slidesImage ? this.UploadedAllImage.emit(this.slidesImage) : this.UploadedAllImage.emit(this.uploadedImagesModal);

    //console.log(this.uploadedImagesModal, this.slidesImage)

  }
  onNextSlide() {
    this.slides.slideNext();



    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');
      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement.pause();
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement1 = document.getElementById('CarousalVideos');

      // Get all the video elements in the carousel
      const videos = videoElement1.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement1.pause();
    }

  }
  onPrevSlide() {
    this.slides.slidePrev();

    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');

      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement = document.getElementById('CarousalVideos');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }

  }

  updateActiveIndex() {
    this.slides.getActiveIndex().then(index => {
      this.activeIndex = index;
    });
  }
}
