import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientServiceService } from '../services/client-service.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-moms',
  templateUrl: './client-moms.component.html',
  styleUrls: ['./client-moms.component.scss'],
})
export class ClientMomsComponent implements OnInit {
  projectId: any;
  momId: any;
  isModalOpen: boolean=false;
  momForm: any;
  isMinutesOpen=false;
  minutes: any;

  constructor(private route:ActivatedRoute,private router:Router,private clientService:ClientServiceService,private modalController:ModalController) { 
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.momId = params['momId'];
      this.clientService.getAllMOMsById(params['projectId'],params['momId']).then((data)=>{
        this.momForm=data[0]
      })
     this.isModalOpen=true
     
    })
  }
  goback(){
    this.router.navigate(['/client/home'])

  }
  // ------------Open minutes----\
setMinutesOpen(b,minutes){
  this.isMinutesOpen=b
  this.minutes=minutes
}
  onSort(e) {
    if (e.sorts[0].prop === 'description') {
      e.sorts[0].dir === 'asc' ? this.momForm?.minutes.sort((a, b) => a.Description.localeCompare(b.Description)) : this.momForm?.minutes.sort((a, b) => b.Description.localeCompare(a.Description));
    }
    if (e.sorts[0].prop === 'date') {
      e.sorts[0].dir === 'asc' ? this.momForm?.minutes.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()) : this.momForm?.minutes.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime())

    }
  }

  ngOnInit() {}
  // -------------Dismiss All Modal------------
  async ngOnDestroy(){
    let modal = await this.modalController.getTop();
    while (modal) {
      await modal.dismiss();
      modal = await this.modalController.getTop();
    }
  }
}
