import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { AlertController, IonSlides, LoadingController, ModalController, Platform } from '@ionic/angular';
// import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ArchitectsService } from '../../services/architects.service';
import { v4 as uuidv4 } from 'uuid';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() onDialogClose = new EventEmitter();
  @Output() onDialogRemove = new EventEmitter();
  loadAllImages = false
  pdfOptions = {
    download: false,
    print: false,
    viewBookmark: false,
    openInNewTab: false,
    useBrowserLocale: true,
  };
  public masonryOptions: NgxMasonryOptions = {
    gutter: 5,
    // fitWidth:true
  };
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  @ViewChild('CarousalVideo') CarousalVideo: ElementRef;
  @ViewChild('CarousalVideos') CarousalVideos: ElementRef;
  dummyPictures = [
    [false, 'https://source.unsplash.com/433x649/?Uruguay'],
    [false, 'https://source.unsplash.com/530x572/?Jamaica'],
    [false, 'https://source.unsplash.com/531x430/?Kuwait'],
    [false, 'https://source.unsplash.com/586x1073/?Bermuda'],
    [false, 'https://source.unsplash.com/500x571/?Ecuador'],
    [false, 'https://source.unsplash.com/579x518/?Virgin Islands (British)'],
    [false, 'https://source.unsplash.com/503x548/?Angola'],
    [false, 'https://source.unsplash.com/511x630/?Mauritania'],
  ]
  masonryImages = [];
  fileName: any = ""
  slideOpts = {
    slidesPerView: 2,
    spaceBetween: 10,
  };
  slideOptsImage = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    prevButton: true,
    nextButton: true,
    pagination: false,
  };
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input()
  doc
  @Input()
  images
  @Input()
  isEdit
  videoUrl: { publicUrl: string; };
  previewTemplate: string;
  uploadModal: boolean=false;
  imagesUpload: any;
  isImageModal: boolean=false;
  pdfUrl: any;
  pdfHeight;
  constructor(private sanitizer: DomSanitizer, private platform: Platform, private http: HttpClient, 
    private fileChooser: FileChooser, private alertController: AlertController, private socialSharing: SocialSharing,
    private modalController:ModalController,
    private architectsService: ArchitectsService, private loadingController: LoadingController,) {

  }
  activeIndex: number = 0;
  pdfZoom = 1.0;

  zoomIn() {
    this.pdfZoom += 0.1;
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
  }
  updateActiveIndex() {
    this.slides.getActiveIndex().then(index => {
      this.activeIndex = index;
    });
  }
  onDeleteImage(image) {

  }
  ngOnInit() {
    this.masonryImages = this.images?.slice(0, 5);
    this.images?.forEach(element => {
      // const url = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl.publicUrl);
      element.publicUrl = `${element.publicUrl}`;
    });
    setTimeout(() => {
    this.loadAllImages=true
      
    }, 200);
    this.pdfUrl = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    this.pdfHeight = 500;
    //  this.images.forEach(element => {
    //   //console.log(element)
    //   if(this.galleryImages){
    //     this.galleryImages.push({
    //       'small': element.publicUrl,
    //       'medium': element.publicUrl,
    //       'big': element.publicUrl,
    //       'url': element.publicUrl,
    //       'description':element.caption
    //       })
    //   }else{
    //     this.galleryImages=[{
    //       'small': element.publicUrl,
    //       'medium': element.publicUrl,
    //       'big': element.publicUrl,
    //       'url': element.publicUrl,
    //       'description':element.caption

    //       }]
    //   }

    //   })
  }
  setOpen(boolean) {
    this.uploadModal = false
  }

  async captureImage() {
    // code to capture and store image
  }
  async selectFile() {
    this.uploadModal = true
    this.imagesUpload = this.images
  }
  async selectFiles() {

    if (this.platform.is('cordova')) {
      const file = await this.fileChooser.open();
      this.uploadFile(file);
    } else {
      this.fileInput.nativeElement.click();
    }
  }
  async uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);

  

    this.architectsService.uploadFile(formData, uuidv4()).then((data) => {

      if (data.data) {
        this.getVideoImage(data.data.path, file)
      } else if (data.error) {
        this.showAlert('Error', 'Error occured while uploading file. Please try after sometime.','errorAlert')

      }
    }, error => {
      this.showAlert('Error', 'Error occured while uploading file. Please try after sometime.','errorAlert')

    });

  }
  saveUpload() {
    this.loadAllImages = false

    this.uploadModal = false
    this.images = this.imagesUpload
    this.onDialogRemove.emit(this.images)
    this.masonryImages = this.images?.slice(0, 5)
    setTimeout(() => {
    this.loadAllImages = true
      
    }, 50);

  }

  removeImage(image) {
    this.imagesUpload = this.imagesUpload.filter((id) => id.key !== image.key)

    // this.images=this.images.filter((id)=>id.key !==image.key)
    // this.onDialogRemove.emit(image)
  }

  removeImageUploaded(image) {
    this.imagesUpload = this.imagesUpload.filter((id) => id.key !== image.key)
    this.images = this.imagesUpload
    this.showAlert('Success', 'Content deleted successfully.','successAlert')

    this.masonryImages = this.images?.slice(0, 5)
    this.slides.slideNext();


    this.onDialogRemove.emit(this.images)


  }
  saveCaption(image) {
    this.images = this.images.filter((id) => id.key !== image.key)
    this.images = [...this.images, image]
    this.showAlert("Success", 'Updated successfully','successAlert')

    this.onDialogRemove.emit(this.images)

  }
  shareImageUrl(text) {
     if (window.navigator.share) {
      window.navigator.share({
        title:'Link shared from Octopi' ,
        text: text.caption,
        url: text.publicUrl
      })
        .then(() => console.log('Shared successfully'))
        .catch(error => console.log('Error sharing:', error));
    
  }
  else if (this.platform.is("ios") || this.platform.is("android")) {

      const link = text.publicUrl;
      // const message = text.caption;
      const subject = 'Link shared from Octopi';
      const message = text.caption?text.caption:'Link shared from Octopi';

      this.socialSharing.share(message, subject, null, link);
    } 
  

    // alert("Copied the text: " + text);
    // const copyElement = document.createElement("textarea");
    // copyElement.style.position = 'fixed';
    // copyElement.style.opacity = '0';
    // copyElement.textContent = text;
    // const body = document.getElementsByTagName('body')[0];
    // body.appendChild(copyElement);
    // copyElement.select();
    // document.execCommand('copy');
    // body.removeChild(copyElement);
    // alert('copied');
  }
  async getVideoImage(path, type) {
    let savedata = { "key": path, "fileName": type.name, "document_type": type.type.slice(0, type.type.lastIndexOf("/")) === 'application' ? type.type.slice(type.type.lastIndexOf("/") + 1, type.type.length) : type.type.slice(0, type.type.lastIndexOf("/")), "caption": this.fileName, "publicUrl": this.architectsService.getPublicUrl(path).publicUrl }
    // this.onDialogClose.emit(savedata);
    // this.images?.push(savedata)
    this.imagesUpload?.push(savedata)
    this.fileName = ""


  }
  async uploadFileFromInput(event: any) {
    const loading = await this.loadingController.create();

    await loading.present();
    for (let i = 0; i < event.target.files.length; i++) {
      // this.uploadFile(event.target.files[i]);
      // if(i===event.target.files.length-1){
      //   setTimeout(() => {
      //   loading.dismiss();
      //   document.getElementById("fileInput")['value'] = ""
          
      //   }, 200);

      // }
      const formData = new FormData();
      formData.append('file', event.target.files[i]);
  
    
  
      this.architectsService.uploadFile(formData, uuidv4()).then((data) => {
     if(i===event.target.files.length-1){
        setTimeout(() => {
        loading.dismiss();
        document.getElementById("fileInput")['value'] = ""
          
        }, 200);

      }
        if (data.data) {
          this.getVideoImage(data.data.path, event.target.files[i])
        } else if (data.error) {
          this.showAlert('Error', 'Error occured while uploading file. Please try after sometime.','errorAlert')
  
        }
      }, error => {
        this.showAlert('Error', 'Error occured while uploading file. Please try after sometime.','errorAlert')
  
      });

    }
  }
  async loadImages() {

  }
  async showAlert(title: any, msg: any,cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass:cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }
  slidesPage(e) {
    //console.log(e)
  }
  getValue() {
    return this.images.length - 5
  }
  onNextSlide() {
    this.slides.slideNext();



    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');
      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement.pause();
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement1 = document.getElementById('CarousalVideos');

      // Get all the video elements in the carousel
      const videos = videoElement1.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
      // videoElement1.pause();
    }

  }
  onPrevSlide() {
    this.slides.slidePrev();

    if (document.getElementById('CarousalVideo')) {

      const videoElement = document.getElementById('CarousalVideo');

      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }
    if (document.getElementById('CarousalVideos')) {

      const videoElement = document.getElementById('CarousalVideos');
      // Get all the video elements in the carousel
      const videos = videoElement.getElementsByTagName('video');

      // Loop through the video elements and pause each one
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
      }
    }

  }
  downloadImageUploaded(image) {
    if(image.key){
    let link = this.architectsService.download(image.key)
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link.publicUrl;
      downloadLink.setAttribute('download', "File");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }else{
    let link = `${image.publicUrl}?download=`
    let downloadLink
    if (link) {
      downloadLink = document.createElement('a');
      downloadLink.href = link;
      downloadLink.setAttribute('download', "File");
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
 }
  }
  async showImage(image: string, i) {
    this.slideOptsImage = {
      initialSlide: i,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 10,
      prevButton: true,
      nextButton: true,
      pagination: false,
    };
    this.imagesUpload = this.images
    this.isImageModal = true
    // const modal = await this.modalCtrl.create({
    //   component: ImageModalComponent,
    //   componentProps: {
    //     imageUrl: this.images
    //   },
    // });
    // return await modal.present();
  }
// ---------------False all other cover pic--------------
onCheckboxChange(cover){
  if(cover.coverPic){
   this.imagesUpload.map((e)=>{
     if(e.key !== cover.key){
       e.coverPic=false
     }
   }

   )
  }
 }
//  // -------------Dismiss All Modal------------
//  async ngOnDestroy(){
//   let modal = await this.modalController.getTop();
//   while (modal) {
//     await modal.dismiss();
//     modal = await this.modalController.getTop();
//   }
// }
}
