import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { ArchitectsService } from '../../architects/services/architects.service';
import { CommonService } from '../services/common.service';
import { Location } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  team: any;
  profileDetails:FormGroup
  imageUrl: string;
  teamMemberId: any;
  roleName: any;
  name: any;
  addPhone:Boolean=false
  isSingleUpload: boolean=false;
  passImageValue: any;
  instagram: any;
  facebook: any;
  link: any;
  otherValue: any;
  constructor(private authService:AuthService,
    private toastController: ToastController,
    private platform: Platform,
    private fileChooser: FileChooser,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private commonService:CommonService,
    private profileDetailsBuilder:FormBuilder,
    private http:HttpClient,
    private modalController:ModalController,
    private location: Location
    ) {
      this.createForm()
     }

  ngOnInit() {
this.imageUrl="https://ionicframework.com/docs/img/demos/avatar.svg"

    this.authService.getCurrentUser().subscribe(async (value) => {
      this.team = value;
      this.authService.getTeamById(this.team.id).then(async(value)=>{
        this.profileDetails.patchValue(value[0])
        this.instagram=value[0]?.website_address?.instagram
        this.facebook=value[0]?.website_address?.facebook
        this.link=value[0].website_address?.link
        this.teamMemberId=value[0].id
        this.roleName=value[0].role_name
        if(value[0].profile_photo_object_key){
this.imageUrl=value[0].profile_photo_object_key
        }
      })
    })
 


  }


  createForm(){
    this.profileDetails = this.profileDetailsBuilder.group({
      name:['',[Validators.required]],
      email:['',[Validators.required]],
      phone:['',[Validators.required]],
      profile_photo_object_key:[''],
      cover_photo_object_key:[''],
      about_me:[''],
      birthday:['',[Validators.required]],
      website_address:[{}]
    })
  }
  // async selectFile() {
  //    if (this.platform.is('cordova')) {
  //      const file = await this.fileChooser.open();
  //      this.uploadFile(file);
  //    } else {
  //     this.fileInput.nativeElement.click();
  //    }
  //  }
   async showAlert(title:any, msg:any,cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass:cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }
  cancelBck() {
    this.location.back();
  }
  //  async uploadFile(file: any) {
  //   if(file.size > 5 * 1024 * 1024) {
  //     //console.log(document.getElementById('fileInput'))
  //     document.getElementById('fileInput')['value'] = ""
  //     this.showAlert('Error','Maximum File size is 5Mb')

  //     return
  //   }
   
  //   const formData = new FormData();
  //   formData.append('file', file); 
  //   const loading = await this.loadingController.create();
  //   document.getElementById("fileInput")['value'] = ""

  //   await loading.present();

  //   this.commonService.uploadFile(formData,uuidv4()).then((data)=>{
  //      loading.dismiss();

  //     if(data.data){
  //       // this.addnewObj.logoobjectKey=data.data.path
  //   //  this.getImage(`octopi-media/${data.data.path}`,false)
  //    this.profileDetails.patchValue({
  //     profile_photo_object_key:this.authService.getPublicUrl(data.data.path).publicUrl
  //    })
  //    this.imageUrl=this.authService.getPublicUrl(data.data.path).publicUrl
  //   }else if(data.error){
  //     this.showAlert('Error',data.error.message)

  //   }
  //   },error=>{
  //     this.showAlert('Error',error.message)
  //   });
  // }
 async edit(){
    const loading = await this.loadingController.create();
  
    await loading.present();
    this.profileDetails.value.website_address={
      instagram:'',
      facebook:'',
      link:'',
    }
    this.profileDetails.value.website_address.instagram=this.instagram
    this.profileDetails.value.website_address.facebook=this.facebook
    this.profileDetails.value.website_address.link=this.link
    this.profileDetails.value.profile_photo_object_key= this.profileDetails.value?.profile_photo_object_key?.publicUrl? this.profileDetails.value?.profile_photo_object_key:{publicUrl:'https://ionicframework.com/docs/img/demos/avatar.svg'}
    this.authService.updateUser(this.teamMemberId,this.profileDetails.value).then(async(value)=>{
      loading.dismiss();
      //console.log(value)
     if(value.error){
      this.showAlert('Error','Profile not updated','errorAlert')
return;
     }
        this.authService.profileActivated(true)
        this.showAlert('Success','Profile updated successfully!','successAlert')
     

    })
  }
  // uploadFileFromInput(event:any) {
  //   this.uploadFile(event.target.files[0]);
  // }

  // Upload Cover Pic
  uploadOpenCoverLogo(name){
    this.name=name
this.isSingleUpload=true
this.passImageValue=name==='logo'?this.profileDetails.value.profile_photo_object_key:this.profileDetails?.value.cover_photo_object_key
this.otherValue=name!=='logo'?this.profileDetails.value.profile_photo_object_key:this.profileDetails?.value.cover_photo_object_key
//console.log(this.passImageValue,this.profileDetails.value.profile_photo_object_key,this.profileDetails?.value.cover_photo_object_key,"BeforePass")
  }
  // Saving image url

  saveImageUrl(url){
    
    this.isSingleUpload=false
  if(this.name==='logo'){
    this.profileDetails.patchValue({
      profile_photo_object_key:url,
      cover_photo_object_key:this.otherValue

    })

  }else{
    this.profileDetails.patchValue({
      profile_photo_object_key:this.otherValue,
      cover_photo_object_key:url
    })

  }

  }
      // -------------Dismiss All Modal------------
      async ngOnDestroy(){
        let modal = await this.modalController.getTop();
        while (modal) {
          await modal.dismiss();
          modal = await this.modalController.getTop();
        }
      }
}
