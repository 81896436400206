<!-- --------------Play based on icon-------------- -->
<ion-col #CarousalVideos id="CarousalVideos">
  <h1></h1>
  <ion-slides *ngIf="!autoPlay" id="slides" #slides [options]="slideOptsImage"
    (ionSlideDidChange)="updateActiveIndex()">
    <ion-slide *ngFor="let image of imagesUpload;index as idx" class="slideContent" style="flex-direction: column">
      <ion-row style="flex-direction: column;width:100%">
        <ion-icon name="arrow-back" *ngIf="imagesUpload[idx-1]" (click)="onPrevSlide()" style="left:0"
          class="arrow-icon-slide"></ion-icon>
        <img *ngIf="image.document_type==='image'" [src]="image.publicUrl" style="aspect-ratio: 2/2;" />
        <video *ngIf="image.document_type==='video'" src="{{image?.publicUrl}}" controls>

        </video>
        <ion-icon name="arrow-forward" *ngIf="imagesUpload[idx+1]" style="right:0" (click)="onNextSlide()"
          class="arrow-icon-slide"></ion-icon>

      </ion-row>
      <ion-row class="text-left w-full">
        Caption
      </ion-row>
      <ion-row  class="text-left w-full">
        <ion-item>
          {{image?.caption}}
        </ion-item>
      </ion-row>
      <!-- ------------Share and Download for Portfolio -->

      <ion-row style="width:100%;justify-content: center;">
        <ion-col size="6">
          <ion-button class=" w-full" placeholder="choose" (click)="shareImageUrl(image)">
            <ion-icon name="share-social-outline"></ion-icon>
            <!-- Share  -->
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button class=" w-full" placeholder="choose" (click)="downloadImageUploaded(image)">
            <ion-icon name="arrow-down-circle-outline"></ion-icon>
            <!-- Download  -->
          </ion-button>
        </ion-col>


      </ion-row>
    </ion-slide>
  </ion-slides>
</ion-col>
<!-- --------Autoplay------------ -->
<ion-col #CarousalVideos id="CarousalVideos">

  <ion-slides *ngIf="autoPlay" id="slides" #slides [options]="slideOptsImage" (ionSlidesDidLoad)="startAutoSlide()"
    (ionSlideDidChange)="updateActiveIndex()">
    <ion-slide *ngFor="let image of imagesUpload;index as idx" class="slideContent" style="flex-direction: column">
      <ion-row style="flex-direction: column;width:100%">
        <ion-icon name="arrow-back" *ngIf="imagesUpload[idx-1]" (click)="onPrevSlide()" style="left:0"
          class="arrow-icon-slide"></ion-icon>
        <img *ngIf="image.document_type==='image'" [src]="image.publicUrl" style="aspect-ratio: 2/2;" />
        <video *ngIf="image.document_type==='video'" (click)="stopAutoPlay()" src="{{image?.publicUrl}}" controls>

        </video>
        <ion-icon name="arrow-forward" *ngIf="imagesUpload[idx+1]" style="right:0" (click)="onNextSlide()"
          class="arrow-icon-slide"></ion-icon>
      </ion-row>

      <ion-row class="text-left w-full">
        Caption
      </ion-row>
      <ion-row class="text-left w-full">
        <ion-item class="w-full">
          {{image?.caption}}
        </ion-item>
      </ion-row>
      <!-- ------------Share and Download for Portfolio -->

      <ion-row style="width:100%;justify-content: center;">
        <ion-col size="6">
          <ion-button class=" w-full" placeholder="choose" (click)="shareImageUrl(image)">
            <ion-icon name="share-social-outline"></ion-icon>
            <!-- Share  -->
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button class=" w-full" placeholder="choose" (click)="downloadImageUploaded(image)">
            <ion-icon name="arrow-down-circle-outline"></ion-icon>
            <!-- Download  -->
          </ion-button>
        </ion-col>


      </ion-row>
    </ion-slide>
  </ion-slides>
</ion-col>


<!-- ---------------BROCHURE VIEW----------- -->
<h1 class="text-left mt-3 text-octopi-secondary" *ngIf="brochure?.length>0">Brochures</h1>

<ion-grid>
  <ion-item class="w-full" *ngFor="let file of brochure;index as index">
    <ion-col size="10">
      <span *ngIf="file.caption">{{file.caption}}</span>
      <span *ngIf="!file.caption">Brochure {{(index +1)}}</span>
    </ion-col>
    <ion-col size="3">
      <ion-icon class="m-1" (click)="shareImageUrl(file)" name="share-social-outline"></ion-icon>
      <ion-icon class="m-1" (click)="downloadImageUploaded(file)" name="arrow-down-circle-outline"></ion-icon>

    </ion-col>
  </ion-item>
</ion-grid>