import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class VendorsservicesService {
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
  }

  // to get home page data
  filterProject(id,search, sort,range) {
    return this.supabase
      .from('vendor_projects_home_page')
      .select("*")
      .eq('vendor_id', id)
      .ilike('project_name', `%${search}%`)
      .range(range, range + 10)
      .order('project_start_date', { ascending: sort==='new-to-old'?false:true })
      .then((result) => result.data)


  }

  // Dashboard details fetching
  getProjectById(id: any, teamId) {
    return this.supabase
      .from('vendor_projects_home_page')
      .select("*")
      .eq('project_id', id)
      .eq('vendor_id', teamId)

      // .ilike('project_tag_names', search)
      .then((result) => result.data)

  }


  getAllvendors() {
    return this.supabase
      .from('vendors')
      .select('*')
      .then((result) => result.data);
  }
  getTagList() {
    return this.supabase
      .from('tags')
      .select('*')
      .then((result) => result.data);
  }
  addTag(obj: any) {

    return this.supabase
      .from('tags')
      .insert([obj])
      .then((result) => result.data)
  }
  getVendorListById(item) {
    return this.supabase
      .from('portfolio')
      .select('*')
      .eq('id', item.id)
      .then((result) => result.data);
  }
  getAllPortfolios() {
    return this.supabase
      .from('portfolio')
      .select('*')
      .order('created_at', { ascending: false })
      .then((result) => result.data);
  }
  createPortfolios(data) {
    return this.supabase
      .from('portfolio')
      .insert([
        data
      ])
      .then((result) => result);

  }
  filterPortfolios(teamId,text,sort,range) {
    return this.supabase
      .from('portfolio')
      .select('*')
      .ilike('title', `%${text}%`)
      .eq('vendor_id', teamId)
      .range(range, range + 10)
      .order('created_at', { ascending: sort==='new-to-old'?false:true })
      .then((result) => result.data);
  }

  updatePortfolio(data, id) {
    return this.supabase
      .from('portfolio')
      .update(data)
      .eq('id', id)
  }
  // get All team member based on team 
  getTeammembers(teamId: any) {
    return this.supabase
      .from('team_members')
      .select(`
    email,
    id,
    name,
    phone,
    team,
    roles(*)
  `).match({ team: teamId })
      .then((result) => result.data);
  }

  projectById(id) {
    return this.supabase
      .from('projects_home_page')
      .select('*')
      .eq('project_id', id)
      .then((result) => result.data)

  }

  // VendorprofilUpdate
  updateUser(vendorId, data) {
    return this.supabase
      .from('vendors')
      .update(data)
      .eq('id', vendorId)
      .then((result) => result);
  }

  // getVendorProfileDetails
  getVendorById(id) {
    return this.supabase
      .from('vendors_detail')
      .select('*')
      .eq('user_id', id)
      .then((result) => result.data);
  }

  // get Document 
  getVendorProjectDoc(projectId,vendorId) {
    return this.supabase
      .from('vendor_project_docs_home_page')
      .select('*')
      .eq('project_id', projectId)

      .then((result) => result.data);

  }
// -----------Check whether vendor has doc access----------
getVendorProjectDocById(docId,vendorId){
  return this.supabase
  .from('vendor_project_docs_home_page')
  .select('*')
  .eq('vendor_id', vendorId)
  .eq('project_doc_id', docId)
  .then((result) => result.data);
  
}

  // ----------Based on Virtual scrol -
  getVendorProjectDocByIdandRange(vendorId,projectId,search,sort,range) {
    return this.supabase
      .from('vendor_project_docs_home_page')
      .select('*')
      .eq('project_id', projectId)
    .eq('vendor_id', vendorId)
    .ilike('project_doc_name', `%${search}%`)
    .range(range, range + 10)
    .order('created_at', { ascending: sort==='new-to-old'?false:true })
      .then((result) => result.data);

  }
  // get project details
  getProjectDataById(projId){
    return this.supabase
    .from('projects_home_page')
    .select('*')
    .eq('project_id', projId)

    .then((result) => result.data);
  
  }
// -----------To get All Mom---------
getAllMOMsByIdandRange(vendorId,projectId,searchText,sort,range){
  return this.supabase
  .from('vendor_mom_home_page')
  .select('*')
  .eq('project_id', projectId)
.eq('vendor_id', vendorId)
.ilike('name', `%${searchText}%`)
.range(range, range + 10)
.order('created_at', { ascending: sort==='new-to-old'?false:true })
  .then((result) => result.data);
}
getMOMsById(momId,vendorId){
  return this.supabase
  .from('vendor_mom_home_page')
  .select('*')
.eq('vendor_id', vendorId)
.eq('id', momId)
.then((result) => result.data);

}
  // --Get ticket by Id--------
  updateTicket(obj, id) {
    return this.supabase
      .from('tickets')
      .update(obj)
      .eq('id', id)
      .then((result) => result.data);
  }
  getTicketById(id) {
    return this.supabase
      .from('vendor_tickets_home_page')
      .select('*')
      .eq('ticket_id', id)
      .then((result) => result.data);
  }
// --Doc details by id--
  getProjectDocsById(id) {
    return this.supabase
      .from('project_documents')
      .select('*')
      .eq('id', id)

      .then((result) => result.data);
  }
// ------------TogetAll ticketsbased on vendorId---------------

getAllTicketsByRangeandFilter(vendorId,projectId,search,sort,range){
  return this.supabase
  .from('vendor_tickets_home_page')
  .select('*')
  .eq('vendor_id', vendorId)
  .eq('project_id', projectId)
  .ilike('ticket_subject', `%${search}%`)
  .range(range, range + 10)
  .order('created_at', { ascending: sort==='new-to-old'?false:true })
  .then((result) => result.data);

}
// ---------To get all siteVisit Details------
getAllSitevisitrangeFilter(vendorId,projectId,search,sort,range){
  return this.supabase
  .from('vendor_site_visits_home_page')
  .select('*')
  .eq('vendor_id', vendorId)
  .eq('project_id', projectId)
  .range(range, range + 10)
  .order('created_at', { ascending: sort==='new-to-old'?false:true })
  .then((result) => result);
}
// ---------To get site visit byId--------
getSiteVisitId(sitevisitId,vendorId){
  return this.supabase
  .from('vendor_site_visits_home_page')
  .select('*')
  .eq('vendor_id', vendorId)
  .eq('id',sitevisitId)
  .then((result) => result.data);

}
}
