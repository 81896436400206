import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}
    SERVER_API_URL = environment.supabaseUrl;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request || !request.url || (/^http/.test(request.url) && !(this.SERVER_API_URL && request.url.startsWith(this.SERVER_API_URL)))) {
            return next.handle(request);
        }
        if(request.url.startsWith(this.SERVER_API_URL + '/storage/v1/object/octopi-media/'))
        {
            request = request.clone({
                setHeaders: {
                    authorization: 'Bearer ' + environment.supabaseKey,
                    apikey:environment.supabaseKey,
                    'Content-Type': 'multipart/form-data'
                }
            });
        }else{
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + environment.supabaseKey,
                    apikey:environment.supabaseKey
                }
            });
        }
        

        return next.handle(request);
    }
}
