import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { VendorsservicesService } from '../modules/vendor-main/services/vendorsservices.service';

@Component({
  selector: 'app-vendor-layout',
  templateUrl: './vendor-layout.component.html',
  styleUrls: ['./vendor-layout.component.scss'],
})
export class VendorLayoutComponent implements OnInit {
  appPages = [
    { title: 'Home', url: '/vendor/home', icon: 'home' },
    { title: 'Profile', url: '/vendor/profile', icon: 'person' },
    { title: 'Sign Out', url: '/auth/login', icon: 'log-out', code: 'signout' },
  ]
  isVendorSelected: boolean;
  isProjectSelected: boolean;
  searchTerm: any;
  team: any;
  ionView: boolean=false;
  timeLine: boolean=false;
  startDate: any;
  endDate: any;
  minDate: string;
  constructor(private router: Router, private authService: AuthService, private vendorService: VendorsservicesService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // //console.log(`Route changed: ${event.url}`);
        if(event.url.includes('profile')|| event.url.includes('dashboard')||event.url.includes('timeline')){
         this.showFilter=false
         if (event.url.includes('timeline')) {
          this.timeLine = true
        }
        }else{
         this.showFilter=true
         this.timeLine = false

        }
        if(event.url.includes('portfolios')||event.url.includes('view-portfolio')){
          this.isProjectSelected = false;
          this.isVendorSelected = true;
        }else{
          this.isProjectSelected = true;
          this.isVendorSelected = false;
        }
      }
    });
    this.authService.getCurrentUser().subscribe(async (value) => {
      this.team = value;
    })
    this.authService.filterValueChanged.subscribe(message => {
      this.searchTerm=message
    })
    this.authService.profileUpdateClicked.subscribe(message => {
      if (message) {
        this.authService.profileActivated(false)

        this.vendorService.getVendorById(this.team.id).then(async (value) => {
          this.organizationName = value[0]?.organization_name
          if (value[0].profile_photo_object?.publicUrl) {
            this.imageUrl = value[0].profile_photo_object?.publicUrl
          }
        })

      }
    });
  }
  imageUrl
  showFilter
  organizationName
  popOver
  ngOnInit() {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1 < 10 ? `0${dtToday.getMonth() + 1}` : dtToday.getMonth() + 1;
    var day = dtToday.getDate() < 10 ? `0${dtToday.getDate()}` : dtToday.getDate();
    var year = dtToday.getFullYear();

    this.minDate = year + '-' + month + '-' + day;
    this.vendorService.getVendorById(this.team.id).then(async (value) => {
      this.organizationName = value[0]?.organization_name
      if (value[0].profile_photo_object?.publicUrl) {
        this.imageUrl = value[0].profile_photo_object?.publicUrl
      }
    })
  }
  handlerstartDate(e) {
    this.startDate=e.target.value

    this.authService.startDate(e.target.value)

  }
  handlerendDate(e) {
    this.endDate=e.target.value

    this.authService.endDate(e.target.value)

  }
  ionViewDidEnter() {
    this.ionView=true
    this.authService.timeLineStartDate.subscribe(message => {
      this.startDate=message
    })
     this.authService.timeLineEndDate.subscribe(message => {
      this.endDate=message

    })
  
    this.authService.filterValueChanged.subscribe(message => {
      this.searchTerm=message
    })
  
   
  }
  onClick(data: any) {
    if (data?.code == 'signout') {
      this.signOut();
    }
  }
  notification() {

  }
  filterProjects(e) {
    this.searchTerm = e.target.value
    if(e.target.value){
      this.authService.filterValue(e.target.value)

    }
  }
  sortProjects(e) {
    this.authService.sortProject(e)

  }
  signOut() {
    this.authService.signOut();
  }
  onClickProjectTab(): void {
    this.resetAll();
    this.isProjectSelected = true;
    this.isVendorSelected = false;
    this.router.navigate(['/vendor/home'])
  }

  resetAll() {
    this.isProjectSelected = false;
    this.isVendorSelected = false;
  }

  changeVendorIcon(): void {
    this.resetAll();
    this.isProjectSelected = false;
    this.isVendorSelected = true;
    this.router.navigate(['/vendor/portfolios'])

  }
}
