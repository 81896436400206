<ion-app>
  <ion-tabs>
 

      <ion-content class="ion-padding">
        <ion-grid class="pt-5 ml-6 mt-10">
          <ion-row>
            <ion-col style="padding-left:0">
              <ion-icon class="text-3xl" (click)="goback()"
                 name="arrow-back-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="cover-photo-form"
        style="background-image: url({{momForm?.cover_pic_object_key}}); ">
        <h1 class="text-center text-octopi-secondary alignTextCover"
        style="padding:4rem;height:150px; background-color: #f1f1f1;"
        *ngIf="!momForm?.cover_pic_object_key">
        <ion-icon class="noCoverPic" name="images-outline"
          *ngIf="!momForm?.cover_pic_object_key"></ion-icon> Cover photo not added
      </h1>

        <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->

      </div>
        <ion-grid class="p-7 form-grid">
          <ion-row>
            <ion-col>
              <h1 class="text-2xl uppercase text-octopi-primary poppinsFamily">VIEW MEETINGS</h1>
            </ion-col>
          </ion-row>
            <ion-row>
              <ion-col>
                <h1 class="text-1xl uppercase text-octopi-secondary">NAME</h1>
                <ion-item class="ion-no-padding font-black ">
               
                  <p >{{momForm?.name}}</p>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <h1 class="text-1xl  uppercase text-octopi-secondary">DESCRIPTION</h1>
                <ion-item class="ion-no-padding font-black ">
                {{momForm?.description}}
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <app-file-upload [isEdit]="true" [doc]="false" *ngIf="momForm?.mom_document_object_keys?.length>0" [images]="momForm?.mom_document_object_keys"
                ></app-file-upload>

               
              </ion-col>
            </ion-row>
            <ion-row class="mt-5" >
              <ion-col>
                <h1 class="text-1xl  uppercase text-octopi-secondary">Meeting Location</h1>
                <ion-col class="flex items-center">
                <ion-toggle [checked]="momForm?.is_online" [disabled]="true"></ion-toggle>  <h1 class="font-black">{{(!momForm?.is_online)?'OFFLINE':'ONLINE'}}</h1>
              </ion-col>
                <ion-item class="ion-no-padding font-black " *ngIf="!momForm?.is_online">
               {{momForm?.location}}
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <h1 class="text-1xl  uppercase text-octopi-secondary">Minutes Notes</h1>
                <ion-item class="ion-no-padding font-black ">
                
                  {{momForm?.minutes_notes}}
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
                <ion-col >

                  <h1 class="text-1xl  uppercase text-octopi-secondary">REFERENCE &nbsp;&nbsp;</h1>
                  <ion-item class="ion-no-padding font-black ">
                
                    {{momForm?.mom_reference_name}}
                  </ion-item>
                </ion-col>
<!--                  
                <ion-item class="font-black ">
                  <ion-row *ngFor="let option of references">
                    <p *ngIf="option?.id===momForm?.value?.mom_reference">{{option.name}}</p>
                  </ion-row>
                </ion-item> -->
             
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <h1 class="text-1xl  uppercase text-octopi-secondary">MEETING DATE</h1>
                <ion-item class="ion-no-padding font-black ">
                  <ion-datetime-button datetime="datetime" [disabled]="true"></ion-datetime-button>

                  <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                      <ion-datetime id="datetime" [value]="momForm?.meeting_date"></ion-datetime>
                    </ng-template>
                  </ion-modal>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <ion-col >

                  <h1 class="text-1xl  uppercase text-octopi-secondary">ATTENDEES</h1> &nbsp;&nbsp;
                  <!-- <ion-icon name="add-circle-outline" *ngIf="!isView" (click)="setTeamMemberOpen(true)"
                    style="font-size:x-large"></ion-icon> -->
                </ion-col>
                <ion-col class="flex items-center p-0 text-octopi-secondary">

                  <h1>Team Members</h1>
              
                  </ion-col>
            
                <ion-row>
                  <ion-col>
                    <ion-item class="ion-no-padding font-black " *ngFor="let option of momForm?.attendee_names">
                      <ion-icon name="person-circle-outline" style="font-size:small"></ion-icon>
                        &nbsp;{{option}}

                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-col class="flex items-center mt-5 p-0 text-octopi-secondary">

                  <h1>Vendors</h1>
             
                  </ion-col>
                  <ion-row class="tags">
                
                    <ion-col>
                      <ion-item class="ion-no-padding font-black"
                        *ngFor="let option of momForm?.attended_vendor_org_names">
                        <ion-icon name="construct-sharp" style="font-size:small"></ion-icon>
                          &nbsp;{{option}}
                      </ion-item>
                      <p *ngIf="momForm?.attended_vendor_org_names.length===0">No vendors attended</p>

                    </ion-col>
                  </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="mt-5">
              <ion-col>
                <ion-col class="flex items-center">

                  <h1 class="text-1xl uppercase text-octopi-secondary">Minutes</h1> &nbsp;&nbsp;
                
                </ion-col>

                <ngx-datatable [externalSorting]="true" (sort)="onSort($event)"
                  style="box-shadow: none;" class="material container striped" [rows]="momForm?.minutes"
                  [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto">
                  <ngx-datatable-column [sortable]="false" name="#" [flexGrow]="0.5">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                      {{(rowIndex + 1)}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [sortable]="true" name="Description" [flexGrow]="4">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row?.Description }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [sortable]="false" name="Action By" [flexGrow]="3">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div class="ellipssis">{{row?.ActionBy}}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [sortable]="true" name="Date" [flexGrow]="2">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div class="ellipssis"> {{row?.Date | date:'dd/MM/yyyy'}}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [sortable]="false" name="" [flexGrow]="0.5">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>

                     
                         <ion-icon (click)="setMinutesOpen(true,row)" 
                        style="float: right;padding-top: 0;" name="eye-outline"></ion-icon>
                    </ng-template>
                  </ngx-datatable-column>


                </ngx-datatable>
              </ion-col>
            </ion-row>
         
       
        </ion-grid>
     
      </ion-content>
  
<!-- Add minutes -->
<ion-content class="ion-padding">
  <!-- <ion-button id="open-modal" expand="block">Open</ion-button> -->
  <ion-modal [isOpen]="isMinutesOpen">
    <ng-template>
      <ion-header style="z-index: auto;">
        <ion-toolbar>
          <!-- <ion-title>Add Minutes</ion-title> -->
          <ion-buttons slot="end">
            <ion-icon name="close-circle-outline" style="font-size:xx-large"
              (click)="setMinutesOpen(false,null)"></ion-icon>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">

        <ion-grid class="p-7 form-grid">
          <ion-row>
            <ion-col>
            
                <h1  class="text-2xl uppercase text-octopi-primary poppinsFamily">VIEW
                  MINUTES</h1>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>

              <h1 class="text-1xl  uppercase text-octopi-secondary">Description</h1> &nbsp;&nbsp;
              <ion-item class="ion-no-padding font-black ">
               
                <p >{{minutes.Description}}</p>
              </ion-item>

            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl  uppercase text-octopi-secondary">Action By</h1> &nbsp;&nbsp;
              <ion-item class="ion-no-padding font-black ">
             
                <p >{{minutes.ActionBy}}</p>
                
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>

              <h1 class="text-1xl  uppercase text-octopi-secondary">Date</h1> &nbsp;&nbsp;
              <ion-item class="ion-no-padding font-black ">
                <p >{{minutes.Date}}</p>

              </ion-item>
            </ion-col>
          </ion-row>
        
        </ion-grid>
     
      </ion-content>
    </ng-template>
  </ion-modal>

</ion-content>
</ion-tabs>
</ion-app>
