import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { CommonService } from 'src/app/modules/common/services/common.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-single-upload',
  templateUrl: './single-upload.component.html',
  styleUrls: ['./single-upload.component.scss'],
})
export class SingleUploadComponent implements OnInit {

  constructor(private alertController:AlertController,private commonService:CommonService,private loadingController:LoadingController) { }
  @ViewChild('fileInput') fileInput: ElementRef;

@Input()
 imagesUpload
@Output()
saveUrl=new EventEmitter()
  ngOnInit() {}
  saveUpload(){
this.saveUrl.emit(this.imagesUpload)
  }

  removeImage(){
    this.imagesUpload=null
    this.saveUrl.emit(this.imagesUpload)

  }
  async selectFile() {
   
      this.fileInput.nativeElement.click();
  }
  uploadFileFromInput(event:any) {
    this.uploadFile(event.target.files[0]);
  }
  async uploadFile(file: any) {
    if(file.size > 5 * 1024 * 1024) {
      //console.log(document.getElementById('fileInput'))
      document.getElementById('fileInput')['value']?document.getElementById('fileInput')['value'] = "":document.getElementById('fileInput1')['value'] = ""
      
      this.showAlert('Error','Maximum File size is 5Mb','errorAlert')

      return
    }

    const formData = new FormData();
    formData.append('file', file); 
    const loading = await this.loadingController.create();
    document.getElementById('fileInput')['value']?document.getElementById('fileInput')['value'] = "":document.getElementById('fileInput1')['value'] = ""


    await loading.present();

    this.commonService.uploadFile(formData,uuidv4()).then((data)=>{
       loading.dismiss();

      if(data.data){
          this.imagesUpload={publicUrl:this.commonService.getPublicUrl(data.data.path).publicUrl,caption:""}
  
  
        //console.log(this.imagesUpload)

    }else if(data.error){
      this.showAlert('Error','Error occured while uploading file, Pleae try after sometime.','errorAlert')

    }
    },error=>{
      //console.log(error)
      this.showAlert('Error','Error occured while uploading file, Pleae try after sometime.','errorAlert')

    });
  }
  async showAlert(title:any, msg:any,cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass:cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
