<ion-app>
  <ion-tabs>
<ion-content class="ion-padding">
  <ion-grid class="pt-5 ml-6 mt-10">
    <ion-row>
      <ion-col style="padding-left:0">
        <ion-icon class="text-3xl" (click)="goback()"
           name="arrow-back-outline"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="cover-photo-form"
  style="background-image: url({{projectDocData?.cover_pic_object_key}}); ">
  <h1 class="text-center text-octopi-secondary alignTextCover"
  style="padding:4rem;height:150px; background-color: #f1f1f1;"
  *ngIf="!projectDocData?.cover_pic_object_key">
  <ion-icon class="noCoverPic" name="images-outline"
    *ngIf="!projectDocData?.cover_pic_object_key"></ion-icon> Cover photo not added
</h1>

  <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->

</div>
  <ion-grid class="p-7 form-grid">
    <ion-row>
      <ion-col>
        <h1 class="text-2xl uppercase text-octopi-primary poppinsFamily"> Project Docs</h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">NAME</h1>
        <ion-item class="ion-no-padding font-black ">
          {{projectDocData?.name}}
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">DESCRIPTION</h1>
        <ion-item class="ion-no-padding font-black ">
          {{projectDocData?.description}}

        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-5">
      <ion-col>
        <app-file-upload [isEdit]=true [doc]="true"
          [images]="projectDocData?.storage_object_id" *ngIf="projectDocData?.storage_object_id.length>0"></app-file-upload>

      </ion-col>
    </ion-row>

    <ion-row class="mt-5">
      <ion-col>
        <h1 class="text-1xl  uppercase text-octopi-secondary">COMMENTS</h1>
        <ion-item class="ion-no-padding font-black ">
          {{projectDocData?.comments}}
        </ion-item>
      </ion-col>
    </ion-row>

  </ion-grid>
  <!-- <app-galleryupload [portfolioImages]="false" *ngIf="docView" [isEdit]="false" [slidesImage]="slidesImage"
      [activeIndex]="0" [isImageModal]="true"></app-galleryupload> -->
</ion-content>


</ion-tabs>
</ion-app>
