<ion-app>
  <ion-menu *ngIf="ionView"  contentId="sub-content" type="overlay">
    <ion-content>
      <div class="p-8">
        <a class="mr-auto text-2xl font-semibold leading-none" [routerLink]="['/vendor/home']"  data-config-id="brand">
          <img class="h-20" src=".../../../../assets/images/logo/logo.png" alt="" width="auto">
        </a>
      </div>
      <ion-list id="inbox-list">

        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
          <ion-item  routerDirection="root" (click)="onClick(p)" [routerLink]="[p.url]" lines="none" detail="false"
            routerLinkActive="selected">
            <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
            <ion-label class="secondaryColorCode">{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-header [translucent]="true" style="z-index: auto;">
    <ion-toolbar>
      <ion-buttons slot="start" style="margin:0;">
        <ion-menu-button >
          <ion-img src=".../../../../assets/icon/icon-harmburger.svg" style="width:22px;"></ion-img>
        </ion-menu-button>
      </ion-buttons>
      <ion-col  class=" font-black ellipssis headerStyle ion-no-padding" style="text-align: left;">{{organizationName | uppercase}}</ion-col>

      <ion-buttons  slot="end">
        <ion-grid>
          <ion-row>
            <!-- <ion-col>
              <ion-icon class="text-2xl font-black text-octopi-primary" name="funnel-outline" ></ion-icon>
            </ion-col>
            <ion-col>
              <ion-icon class="text-2xl font-black text-octopi-primary" name="search-outline" ></ion-icon>
            </ion-col> -->
            <ion-col *ngIf="filter" >
            <ion-img id="date-button-date"  src="../../assets/icon/funnel.png" style="width:24px"></ion-img>

            <ion-popover trigger="date-button-date" >
              <ng-template>
                <ion-list>
                  <ion-item>
                   From Date: &nbsp; &nbsp; <input  type="date"  [value]="startDate" [max]="minDate" (change)="handlerstartDate($event)" />

                  </ion-item>
                  <ion-item>
                    End Date: &nbsp; &nbsp;  &nbsp;<input [max]="minDate" [value]="endDate" (change)="handlerendDate($event)"  type="date"  />
              </ion-item>
              
                </ion-list>
              </ng-template>
            </ion-popover>
            </ion-col>
         
            <ion-col >
              <!-- <ion-icon [disabled]="true" class="text-2xl font-black text-octopi-primary" name="notifications-outline" ></ion-icon> -->
            <ion-img (click)="notification()" src="../../assets/icon/icon-bell.svg" style="width:24px"></ion-img>
            </ion-col>
           <ion-col >
            <ion-avatar class="widthAvatar">
              <ion-img #image [routerLink]="['/client/profile']" style="aspect-ratio: 1/1;" loading-text="Loading image..." src="{{imageUrl}}" 
         
              >
              </ion-img>
            </ion-avatar>
           </ion-col>
          </ion-row>
        
        </ion-grid> 
      </ion-buttons>
    </ion-toolbar>
  </ion-header>


    <ion-tabs >
      
   
      
  <ion-router-outlet *ngIf="true" id="sub-content" >
  </ion-router-outlet>

    </ion-tabs>
</ion-app>