import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule} from "@angular/common/http";
import { VendorLayoutComponent } from '../vendor-layout/vendor-layout.component';
import { ClientLayoutComponent } from '../client-layout/client-layout.component';


@NgModule({
  declarations: [
    LayoutComponent,
    VendorLayoutComponent,
    ClientLayoutComponent
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    SharedModule,
    RouterModule,
    HttpClientModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  exports     : [
    LayoutComponent,
],
schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule { }
