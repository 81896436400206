<!-- Image Upload -->

<div *ngIf="uploadOpen">

  <ion-col size="9">


    <input type="file" multiple placeholder="choose" *ngIf="!portfolioImages" id="fileInput" #fileInput style="display: none;"
      (change)="uploadFileFromInput($event)">
      <input type="file" multiple placeholder="choose" *ngIf="portfolioImages" accept="image/*, video/*"  id="fileInput" #fileInput style="display: none;"
      (change)="uploadFileFromInput($event)">
  </ion-col>

  <ion-row style="min-height: 45vh;" *ngIf="uploadedImagesModal?.length===0 || !uploadedImagesModal"> <ion-col
      class="text-center m-auto text-octopi-secondary">
      <p>Nothing here ...yet</p>
    </ion-col></ion-row>
  <ion-grid>
    <ion-row>
      <ion-col id="CarousalVideo" #CarousalVideo size="12" *ngFor="let image of uploadedImagesModal">

        <ion-row>
          <ion-icon name="trash" class="trash" (click)="removeImage(image)"></ion-icon>

          <img class="thumbnail" *ngIf="image.document_type==='image'" style="aspect-ratio: 1/1;width:100%;"
            src="{{image?.publicUrl}}">
          <video class="thumbnail" *ngIf="image.document_type==='video'" controls style="aspect-ratio: 1/1;"
            src="{{image?.publicUrl}}"></video>
          <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf'">
            <pdf-viewer  src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false"></pdf-viewer>
            <div class="flex justify-center">
                  
              <ion-col size="3">
                  <ion-button class="w-full"  (click)="zoomIn()" placeholder="choose">+</ion-button>
                  </ion-col>
                  <ion-col size="3">
                    <ion-button class="w-full"  (click)="zoomOut()" placeholder="choose">-</ion-button>
                    </ion-col>
                    </div>
          </div>
          <div class="thumbnail filenotsupported"
            *ngIf="image.document_type!=='pdf' &&image.document_type!=='image' && image.document_type!=='video'">

            <ion-icon name="folder-open-outline"></ion-icon>
            <p class="text-octopi-secondary">File not supported to view.</p>
          </div>

        </ion-row>
        <ion-item size="12" class="ion-no-padding">
          <ion-input type="text" class="w-100 mb-3" [ngClass]="{'text-octopi-secondary':!isEdit}"
            [(ngModel)]="image.caption" [readonly]="!isEdit" placeholder="Caption"></ion-input>
        </ion-item>
        <ion-item size="12" class="ion-no-padding w-full" *ngIf="portfolioImages && image.document_type==='image'">
          <ion-checkbox slot="start" (ionChange)="onCheckboxChange(image)" [(ngModel)]="image.coverPic" style="margin: 0;"></ion-checkbox>
          Make Cover photo
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-row class="postionSticky">
    <ion-col size="6">
      <ion-button class="w-full" (click)="selectFiles()" placeholder="choose">
        <ion-icon name="arrow-up-circle-outline"></ion-icon> {{(uploadedImagesModal?.length===0)?' Upload':' Add More'}}
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button class=" w-full" placeholder="choose" (click)="saveUpload()">
        SAVE
      </ion-button>
    </ion-col>

  </ion-row>

</div>

<!-- View ion-modal -->

<div *ngIf="isImageModal">
  <div class="ion-padding" #CarousalVideos id="CarousalVideos">
    <ion-slides id="slides" #slides [options]="slideOptsImage" (ionSlideDidChange)="updateActiveIndex()">
      <ion-slide *ngFor="let image of slidesImage;index as idx" class="slideContent" style="flex-direction: column">
        <ion-row style="flex-direction: column;width:100%">
          <ion-icon name="arrow-back" (click)="onPrevSlide()" *ngIf="slidesImage[idx-1]" style="left:0"
            class="arrow-icon"></ion-icon>
          <img *ngIf="image.document_type==='image'" [src]="image.publicUrl" style="aspect-ratio: 2/2;" />
          <video *ngIf="image.document_type==='video'" src="{{image?.publicUrl}}" controls>

          </video>


          <div style="width:100%;height:100%" *ngIf="image.document_type==='pdf'">
            <pdf-viewer src="{{image?.publicUrl}}" style="aspect-ratio: 2/2;" [original-size]="false"></pdf-viewer>
            <div class="flex justify-center">
                  
              <ion-col size="3">
                  <ion-button class="w-full"  (click)="zoomIn()" placeholder="choose">+</ion-button>
                  </ion-col>
                  <ion-col size="3">
                    <ion-button class="w-full"  (click)="zoomOut()" placeholder="choose">-</ion-button>
                    </ion-col>
                    </div>
          </div>
          <div class="filenotsupported" style="aspect-ratio: 2/2;"
            *ngIf="image.document_type!=='pdf' &&image.document_type!=='image' && image.document_type!=='video'">

            <ion-icon name="folder-open-outline" style="margin-top: 5.5rem;"></ion-icon>
            <p class="text-octopi-secondary">File not supported to view. Please download to view this document.</p>
          </div>

          <ion-icon name="arrow-forward" *ngIf="slidesImage[idx+1]" style="right:3px" (click)="onNextSlide()"
            class="arrow-icon"></ion-icon>
        </ion-row>
        <ion-row size="12" class="mt-4 ml-2 captionalign">
          <ion-col size="9">
            <h1 class="text-1xl font-bold uppercase text-octopi-secondary">Caption</h1>

          </ion-col>

        </ion-row>
        <ion-row class="captionalign ml-2">
          <ion-item style="width:93%" class="ion-no-padding">

            <ion-textarea rows="3" style="width:100%" [ngClass]="{'text-octopi-secondary':!isEdit}"
              [(ngModel)]="image.caption" [readonly]="!isEdit" placeholder="Caption"></ion-textarea>
             
          </ion-item>
        </ion-row>
        <ion-item size="12" class="ion-no-padding w-full" *ngIf="portfolioImages && image.document_type==='image'">
          <ion-checkbox slot="start" (ionChange)="onCheckboxChange(image)" [(ngModel)]="image.coverPic" style="margin: 0;"></ion-checkbox>
          &nbsp;Make Cover photo
        </ion-item>
        <ion-row style="width:100%" *ngIf="!isEdit">
          <ion-col size="6">
            <ion-button class=" w-full" placeholder="choose" (click)="shareImageUrl(image)">
              <ion-icon name="share-social-outline"></ion-icon>
              <!-- Share  -->
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class=" w-full" placeholder="choose" (click)="downloadImageUploaded(image)">
              <ion-icon name="arrow-down-circle-outline"></ion-icon>
              <!-- Download  -->
            </ion-button>
          </ion-col>


        </ion-row>
        <ion-row style="width:100%" *ngIf="isEdit">
          <!-- <ion-col size="4">
            <ion-button class="w-full"  (click)="saveCaption(image)" placeholder="Update" >
              <ion-icon name="pencil-outline" ></ion-icon> -->
          <!-- Update -->
          <!-- </ion-button>
        </ion-col> -->
          <ion-col size="4">
            <ion-button class=" w-full" placeholder="choose" (click)="removeImage(image)">
              <ion-icon name="trash"></ion-icon>
              <!-- Delete  -->
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button class=" w-full" placeholder="choose" (click)="shareImageUrl(image)">
              <ion-icon name="share-social-outline"></ion-icon>
              <!-- Share  -->
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button class=" w-full" placeholder="choose" (click)="downloadImageUploaded(image)">
              <ion-icon name="arrow-down-circle-outline"></ion-icon>
              <!-- Download  -->
            </ion-button>
          </ion-col>


        </ion-row>
      </ion-slide>

    </ion-slides>
  </div>
</div>