import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleUploadComponent } from '../modules/architects/components/single-upload/single-upload.component';
import { IonBadge, IonicModule } from '@ionic/angular';
import { GalleryuploadComponent } from '../modules/architects/components/galleryupload/galleryupload.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { ImagePreloaderDirective } from '../modules/architects/image-preloader.directive';
import { ProfileComponent } from '../modules/common/profile/profile.component';
import { ViewportfolioComponent } from '../modules/vendor-main/viewportfolio/viewportfolio.component';
import { SlideshowPortfoliosComponent } from '../modules/vendor-main/sub-components/slideshow-portfolios/slideshow-portfolios.component';
import { ProjectDetailsViewComponent } from '../modules/vendor-main/project-details-view/project-details-view.component';
import { FileUploadComponent } from '../modules/architects/components/file-upload/file-upload.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { ChatWidgetComponent } from '../modules/architects/components/chat-widget/chat-widget.component';
import { TeammemberdetailsComponent } from '../modules/common/teammemberdetails/teammemberdetails.component';
import { ClientProjectDocComponent } from '../modules/clients/client-project-doc/client-project-doc.component';
import { ClientSiteVisitComponent } from '../modules/clients/client-site-visit/client-site-visit.component';
import { ClientMomsComponent } from '../modules/clients/client-moms/client-moms.component';
import { ClientTicketsComponent } from '../modules/clients/client-tickets/client-tickets.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GanttChartProjectplanComponent } from '../modules/architects/components/gantt-chart-projectplan/gantt-chart-projectplan.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapViewerComponent } from '../modules/common/google-map-viewer/google-map-viewer.component';


@NgModule({
  declarations: [
    SingleUploadComponent,
SlideshowPortfoliosComponent,
FileUploadComponent,
    GalleryuploadComponent,
    ImagePreloaderDirective,
    ProfileComponent,
    ProjectDetailsViewComponent,
    ViewportfolioComponent,
    ChatWidgetComponent,
    
    TeammemberdetailsComponent,
    ClientProjectDocComponent,
    ClientSiteVisitComponent,
    ClientTicketsComponent,
    ClientMomsComponent,
    GoogleMapViewerComponent
  ],

  imports: [
    CommonModule,
    IonicModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbkTTnXCpM7cNdgjkNIywcsffmTRzYR5c'
    }),
    FormsModule,
    PdfViewerModule,
    ReactiveFormsModule,
    NgxMasonryModule,
NgxDatatableModule,

  ],
  exports: [
    CommonModule,
    SingleUploadComponent,
    ProfileComponent,
    ReactiveFormsModule,
    GalleryuploadComponent,
    PdfViewerModule,
    ImagePreloaderDirective,
    ViewportfolioComponent,
    SlideshowPortfoliosComponent,
    ProjectDetailsViewComponent,
    FileUploadComponent,
    ChatWidgetComponent,
    TeammemberdetailsComponent,
    GoogleMapViewerComponent
  ],
  providers: [FileChooser,
  ]
})
export class SharedModule { }
