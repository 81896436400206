import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  currentUserRoleName: any=null;
  constructor(private auth: AuthService, private router: Router, private toastController: ToastController) {
    
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const roles = route.data['roles'];
    const isAuthenticated$: Observable<any> = this.auth.getCurrentUser();
    const hasAccess$: Observable<any> = this.auth.getCurrentTeam();
    return combineLatest([isAuthenticated$, hasAccess$]).pipe(
     
      filter(([isAuthenticated, hasAccess]) => isAuthenticated !== null && hasAccess !== null),// Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(([isAuthenticated, hasAccess]) => {
          if (isAuthenticated && hasAccess.role_name&& roles?.includes(hasAccess?.role_name)) {
            
          return true;
        }else if(isAuthenticated &&roles?.includes('ALL')){
          return true;

        }else if(hasAccess?.role_name && hasAccess) {
          this.toastController
            .create({
              message: 'You are not allowed to access this!',
              duration: 2000,
            })
            .then((toast) => toast.present());

          return this.router.createUrlTree(['/auth/login']);
        }else{
          return this.router.createUrlTree(['/auth/login']);

        }
      })
    );
  }
}
