import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientServiceService } from '../services/client-service.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-site-visit',
  templateUrl: './client-site-visit.component.html',
  styleUrls: ['./client-site-visit.component.scss'],
})
export class ClientSiteVisitComponent implements OnInit {
  isMinutesOpen: boolean=false;
  rowMinutes: any;
  viewSiteVisit: any;
  time: string;
  date: string;
  projectLat: number;
  projectLong: number;
  address: any;

  constructor(private route:ActivatedRoute,private clientService:ClientServiceService,private router:Router,private modalController:ModalController) {
    this.route.queryParams.subscribe(params => {

    if(params['sitevisitId'] && params['view']){
      this.clientService.getProjectByprojectId(params['projectId']).then((data)=>{
        this.projectLat=parseFloat(data[0].lat_value)
        this.projectLong=parseFloat(data[0].long_value)
        this.address=data[0].address
      })
      this.clientService.getSiteVisitId(params['sitevisitId']).then((siteVisit) => {

        this.viewSiteVisit=siteVisit[0]
        const date = new Date(siteVisit[0].created_at);
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`
        this.time = `${hours}:${minutes}`
        var month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        var day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        var year = date.getFullYear();
    
        this.date = year + '-' + month + '-' + day
      })
    }
  })
   }

  ngOnInit() {}
  setMinutesOpen(b, row) {
    this.isMinutesOpen = b
    this.rowMinutes = row
  }
  goback(){
    this.router.navigate(['/client/home'])

  }
  onSort(e) {
    if (e.sorts[0].prop === 'description') {
      e.sorts[0].dir === 'asc' ? this.viewSiteVisit?.minutes.sort((a, b) => a.Description.localeCompare(b.Description)) : this.viewSiteVisit?.minutes.sort((a, b) => b.Description.localeCompare(a.Description));
    }
    if (e.sorts[0].prop === 'date') {
      e.sorts[0].dir === 'asc' ? this.viewSiteVisit?.minutes.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()) : this.viewSiteVisit?.minutes.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime())

    }
  }

  // --To open google map-------
onMarkerClick(lat,long){
  window.open(`https://maps.google.com/?q=${lat},${long}`, '_blank');

}
    // -------------Dismiss All Modal------------
    async ngOnDestroy(){
      let modal = await this.modalController.getTop();
      while (modal) {
        await modal.dismiss();
        modal = await this.modalController.getTop();
      }
    }
}
