<ion-app>
  <ion-tabs>

    <ion-content fullscreen class="ion-padding">
      <ion-grid class="pt-5 ml-6 mt-10">
        <ion-row>
          <ion-col style="padding-left:0">
            <ion-icon class="text-3xl" (click)="goBack()" name="arrow-back-outline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div  class="cover-photo"  style="background-image: url({{portfolio?.cover_photo_object?.publicUrl}}); ">
        <h1 class="text-center text-octopi-secondary alignTextCover" 
          style="padding:4rem;height:150px; background-color: #f1f1f1;"
          *ngIf="!portfolio?.cover_photo_object?.publicUrl">
          <ion-icon class="noCoverPic" name="images-outline"
            *ngIf="!portfolio?.cover_photo_object?.publicUrl"></ion-icon> Cover photo not added
        </h1>
        <div class="profile-pic" >
          <img src="{{portfolio?.profile_photo_object?.publicUrl}}" />

        </div>
        <a class="edit-icon"  *ngIf="portfolio?.profile_photo_object?.publicUrl" ><ion-icon name="eye" (click)="viewPic(portfolio?.profile_photo_object)"></ion-icon></a>

        <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->
        <ion-fab vertical="top" horizontal="end" *ngIf="portfolio?.cover_photo_object?.publicUrl" (click)="viewPic(portfolio?.cover_photo_object)">
          <ion-fab-button color="primary" >
            <ion-icon name="eye"></ion-icon>
          </ion-fab-button>
        </ion-fab>
        <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->

      </div>
      <ion-grid class="p-7 form-grid">
        <h1 class="text-left mt-7 ml-3 font-black">{{portfolio?.vendor_org_name}}</h1>

        <ion-row class="mt-3">
          <ion-col>
            <h1 class="text-left text-octopi-secondary">About</h1>
            <ion-row>
              <ion-item class="w-full">{{portfolio?.about}}</ion-item>

            </ion-row>

          </ion-col>
          </ion-row>
       
        <ion-row class="mt-2">

          <ion-col>
            <h1 class="text-left text-octopi-secondary">Tags</h1>
            <ion-row class="mt-5">

              <ion-col id={{idx}}  class="chips " *ngFor="let item of portfolio?.tag_names;index as idx">
                <ion-popover trigger={{idx}} triggerAction="click">
                  <ng-template>
                    <ion-item> <ion-icon name="pricetags" slot="start"></ion-icon> &nbsp;{{item}}</ion-item>
                  </ng-template>
                </ion-popover>
                <div class="ellipssis" ><ion-icon name="pricetags"></ion-icon> {{item}}</div>
              
                <!-- <span class="text-1xl flex"><ion-icon name="pricetags"></ion-icon> &nbsp; {{getTagName(item)}}</span> -->
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="mt-5 flex " style="align-items: center;justify-content: center;">
        <ion-col size="12">
          <ion-item>
            <ion-icon class="font-black m-0" style="color:black" name="person-circle-outline" slot="start"></ion-icon>
            &nbsp;{{portfolio?.name}}
          </ion-item>
        </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-icon class="font-black m-0" style="color:black" name="mail" slot="start"></ion-icon>
              &nbsp;<span  id=e-mail class="ellipssis">{{portfolio?.email}}</span> 

            </ion-item>
          </ion-col>
          <ion-popover trigger=e-mail triggerAction="click">
            <ng-template>
              <ion-item> {{portfolio?.email}}</ion-item>
            </ng-template>
          </ion-popover>
          <ion-col size="12">
            <ion-item>
              <ion-icon class="font-black m-0" style="color:black" name="call" slot="start"></ion-icon>
              &nbsp;{{portfolio?.phone}}
            </ion-item>
          </ion-col>
          <ion-col size="4" class="text-center" *ngIf="portfolio?.links?.facebook" (click)="openWeb(portfolio?.links?.facebook)">
            <ion-icon name="logo-facebook" style="font-size: 1.5rem;"></ion-icon>

          </ion-col>
          <ion-col size="4" class="text-center" *ngIf="portfolio?.links?.instagram" (click)="openWeb(portfolio?.links?.instagram)">
            <ion-icon name="logo-instagram" style="font-size: 1.5rem;"></ion-icon>
          </ion-col>
          <ion-col size="4" class="text-center" *ngIf="portfolio?.links?.link" (click)="openWeb(portfolio?.links?.link)">
            <ion-icon name="globe" style="font-size: 1.5rem;"></ion-icon>

          </ion-col>
        </ion-row>
        <h1 class="text-left ml-3 text-octopi-secondary" *ngIf="portfolioBrochures?.length>0">Portfolio</h1>

        <ion-row *ngFor="let portfolio of portfolioBrochures">
          <ion-grid class="m-2" >

            <ion-card class="w-full cover-photo-portfolio"
              style="background-image: url({{portfolio.portfolio_object_keys[0].coverPicUrl}});margin-inline: 0;">
              <!-- <div class="cover-photo" style="background-image: url({{portfolio?.cover_photo_object?.publicUrl}}); ">      -->

              <ion-title class="cardTitlePortfolio ">
                <h1 class="ellipssis text-left" >{{portfolio.title}}</h1>
              </ion-title>
              <ion-card-content class="alignPlayButton">

                <ion-fab>
                  <ion-fab-button color="primary" (click)="play(portfolio)">
                    <ion-icon name="play"></ion-icon>
                  </ion-fab-button>
                </ion-fab>

              </ion-card-content>

            </ion-card>

          </ion-grid>
        </ion-row>
      </ion-grid>
    </ion-content>
    <!-- --------Slide show & view portfolios------------ -->
    <ion-content>
      <ion-modal [isOpen]="isImageModal">
        <ng-template>
          <ion-header style="z-index: auto;">
            <ion-toolbar>
              <!-- <ion-title>{{objectToview?.title}}</ion-title> -->
              <ion-buttons slot="end">
                <ion-icon name="close-circle-outline" style="font-size:xx-large"
                  (click)="isImageModal=false"></ion-icon>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <h1 class="text-left mt-3 text-octopi-primary text-3xl uppercase">{{objectToview.title}}</h1>
            <h1 class="text-left mt-3 text-octopi-secondary">{{objectToview.description}}</h1>

            <app-slideshow-portfolios [imagesUpload]="objectToview.portfolio_object_keys[0]?.object"
              [autoPlay]="objectToview.autoplay" [brochure]="objectToview.brochure[0]?.object"
              *ngIf="isImageModal"></app-slideshow-portfolios>
          </ion-content>
        </ng-template>
      </ion-modal>

    </ion-content>

    <!-- --------------View cover pic & profile pic-------------- -->
    <ion-content>
      <ion-modal [isOpen]="isCoverPic">
        <ng-template>
          <ion-header style="z-index: auto;">
            <ion-toolbar>
              <!-- <ion-title>{{objectToview?.title}}</ion-title> -->
              <ion-buttons slot="end">
                <ion-icon name="close-circle-outline" style="font-size:xx-large"
                  (click)="isCoverPic=false"></ion-icon>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
          

            <app-slideshow-portfolios [imagesUpload]="object"
              [autoPlay]=false [brochure]="[]"
              *ngIf="isCoverPic"></app-slideshow-portfolios>
          </ion-content>
        </ng-template>
      </ion-modal>

    </ion-content>
  </ion-tabs>
</ion-app>