import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { ClientServiceService } from '../modules/clients/services/client-service.service';

@Component({
  selector: 'app-client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss'],
})
export class ClientLayoutComponent implements OnInit {
  isProjectSelected=true
  searchTerm=""
  popOver
  showFilter
  organizationName
  appPages = [
    { title: 'Home', url: '/client/home', icon: 'home' },
    { title: 'Profile', url: '/client/profile', icon: 'person' },
    { title: 'Sign Out', url: '/auth/login', icon: 'log-out', code: 'signout' },
  ]
  ionView: boolean=false;
  team: any;
  startDate: any;
  endDate: any;
  minDate: string;
  projectData: any;
  clientDetails: any;
  filter: boolean;
  constructor(private router:Router,private authService:AuthService,private clientService:ClientServiceService) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
    if(event.url.includes('home')){
      this.filter=true
     }else{
      this.filter=false
  
     }
    }
  })
  }
  imageUrl="https://ionicframework.com/docs/img/demos/avatar.svg"
  ngOnInit() {
 
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1 < 10 ? `0${dtToday.getMonth() + 1}` : dtToday.getMonth() + 1;
    var day = dtToday.getDate() < 10 ? `0${dtToday.getDate()}` : dtToday.getDate();
    var year = dtToday.getFullYear();

    this.minDate = year + '-' + month + '-' + day;
    this.authService.getCurrentUser().subscribe(async (value) => {
      this.team = value;
      this.clientService.getClientDetailById(value['id']).then(async(cvalue)=>{
        this.clientDetails=cvalue[0]
        this.organizationName = cvalue[0]?.organization_name
        if(cvalue[0].profile_photo_object_key?.publicUrl){
        this.imageUrl=cvalue[0].profile_photo_object_key?.publicUrl
        }
      })
    })
  }

  handlerstartDate(e) {
    this.startDate=e.target.value

    this.authService.startDate(e.target.value)

  }
  handlerendDate(e) {
    this.endDate=e.target.value

    this.authService.endDate(e.target.value)

  }
  onClickProjectTab(){
 
    this.router.navigate(['/client/home'])
  }
  onClick(data: any) {
    if (data?.code == 'signout') {
      this.signOut();
    }
  }
  signOut() {
    this.authService.signOut();
  }
  ionViewDidEnter(){
    this.ionView=true
 
    this.authService.profileUpdateClicked.subscribe(message => {
      if(message){
this.authService.profileActivated(false)

        this.clientService.getClientById(this.team.id).then(async(value)=>{
          if(value[0].profile_photo_object_key?.publicUrl){
          this.imageUrl=value[0].profile_photo_object_key?.publicUrl
          }
        })
      }
    });
    this.authService.timeLineStartDate.subscribe(message => {
      this.startDate=message
    })
     this.authService.timeLineEndDate.subscribe(message => {
      this.endDate=message

    })
  }
  sortProjects(text){}
  notification(){}
  filterProjects(e){

  }
}
