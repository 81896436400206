<ion-app>
  <ion-tabs>

    <ion-content fullscreen class="ion-padding">
      <ion-grid class="pt-5 ml-6 mt-10">
        <ion-row>
          <ion-col style="padding-left:0">
            <ion-icon class="text-3xl" (click)="goBack()" name="arrow-back-outline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="cover-photo"
        style="background-image: url({{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}); ">
        <div class="profile-pic">
          <img src="{{projectUpdate?.value?.logo_object_key?.publicUrl}}" />

        </div>
        <a class="edit-icon"  *ngIf="projectUpdate?.value?.logo_object_key?.publicUrl" ><ion-icon name="eye" (click)="viewPic(projectUpdate?.value?.logo_object_key)"></ion-icon></a>

        <!-- <img class= "cover-photo" src="{{projectUpdate?.value?.cover_photo_object_key?.publicUrl}}" alt="My App Logo" style="aspect-ratio: 4/2;object-fit: cover;"> -->
        <ion-fab vertical="top" horizontal="end" *ngIf="projectUpdate?.value?.cover_photo_object_key?.publicUrl" (click)="viewPic(projectUpdate?.value?.cover_photo_object_key)">
          <ion-fab-button color="primary" >
            <ion-icon name="eye"></ion-icon>
          </ion-fab-button>
        </ion-fab>

      </div>
      <ion-grid class="p-7 form-grid">


        <form [formGroup]="projectUpdate">
          <ion-col>
            <h1 class="text-2xl uppercase text-octopi-primary poppinsFamily">{{projectUpdate?.value?.project_name}}</h1>
            <p>{{projectUpdate?.value?.project_start_date | date:'dd-MMM-yyyy'}} - {{projectUpdate?.value?.project_end_date | date:'dd-MMM-yyyy'}}
         </p>
          </ion-col>
          <ion-col>
          </ion-col>

          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl  uppercase text-octopi-secondary">DESCRIPTION</h1>
              <ion-item class="ion-no-padding font-black ">
                {{projectUpdate?.value?.project_description}}
                <!-- <ion-textarea rows="3" formControlName="project_description" placeholder="Description"></ion-textarea> -->
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col class="flex items-center">
              <h1 class="text-1xl  uppercase text-octopi-secondary">TAGS</h1> &nbsp;
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="chips " id={{idx}} *ngFor="let item of projectUpdate?.value?.tag_names;index as idx
            ">
            <ion-popover trigger={{idx}} triggerAction="click">
              <ng-template>
                <ion-item> <ion-icon name="pricetags" slot="start"></ion-icon> &nbsp;{{item}}</ion-item>
              </ng-template>
            </ion-popover>
         
              <div class=ellipssis ><ion-icon name="pricetags"></ion-icon> {{item}}</div>
     
              <!-- <span class="text-1xl flex"><ion-icon name="pricetags"></ion-icon> &nbsp; {{getTagName(item)}}</span> -->
            </ion-col>
          </ion-row>


          <ion-row class="mt-5">
            <ion-col class="flex">
              <h1 class="text-1xl  uppercase text-octopi-secondary">VOICE MEMO &nbsp;</h1>

            </ion-col>

          </ion-row>
          <ion-row>

            <audio id="player" controls *ngIf="projectUpdate?.value?.voice_memo_object_key"
              src="{{projectUpdate?.value?.voice_memo_object_key}}"></audio>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl uppercase text-octopi-secondary">TYPE</h1>
              <ion-item class="ion-no-padding font-black ">
                {{projectUpdate?.value?.type_name}}
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-5">
            <ion-col class="flex items-center">
              <h1 class="text-1xl  uppercase text-octopi-secondary">CLIENT</h1> &nbsp;
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item class="ion-no-padding font-black ">
                {{projectUpdate?.value?.client_name
                }}
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="mt-5">
            <ion-col>
              <div class="flex items-center">
                <h1 class="text-1xl  uppercase text-octopi-secondary">ASSIGN TEAM MEMBERS</h1> &nbsp;
              </div>
              <ion-item class="ion-no-padding font-black "
                *ngFor="let team of projectUpdate?.value?.project_team_members">
                <ion-icon name="person-circle-outline"></ion-icon> &nbsp;{{team}}
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl  uppercase text-octopi-secondary">ADDRESS</h1>
              <ion-item class="ion-no-padding font-black ">
                {{projectUpdate?.value?.address}}
              </ion-item>
            </ion-col>
          </ion-row>
          <div style="height: 500px; width: 100%;" class="map-container" *ngIf="projectUpdate?.value?.lat_value!==0">
            <agm-map  [latitude]="projectUpdate?.value?.lat_value" [longitude]="projectUpdate?.value?.long_value" [zoom]="12" style="width:100%; height:500px;" >
              <agm-marker [latitude]="projectUpdate?.value?.lat_value"  [longitude]="projectUpdate?.value?.long_value" (markerClick)="onMarkerClick(projectUpdate?.value?.lat_value,projectUpdate?.value?.long_value)"></agm-marker>
              <!-- <agm-marker [latitude]="userlatitude" *ngIf="userlatitude!==0 && userlongitude!==0" [longitude]="userlongitude" (markerClick)="onMarkerClick(userlatitude,userlongitude)" ></agm-marker> -->
            
            </agm-map>
            <div class="heading-container">
              <ion-icon name="location" style="color:red"></ion-icon> Address
              <h1>{{projectUpdate?.value?.address}}</h1>
            </div>
            </div>
          <ion-row class="mt-5">
            <ion-col>
              <h1 class="text-1xl  uppercase text-octopi-secondary">INTERNAL NOTES</h1>
              <ion-item class="ion-no-padding font-black ">
                {{projectUpdate?.value?.internal_notes}}

              </ion-item>
            </ion-col>
          </ion-row>
        </form>

      </ion-grid>

    </ion-content>
<!-- --------------View cover pic & profile pic-------------- -->
    <ion-content>
      <ion-modal [isOpen]="isImageModal">
        <ng-template>
          <ion-header style="z-index: auto;">
            <ion-toolbar>
              <!-- <ion-title>{{objectToview?.title}}</ion-title> -->
              <ion-buttons slot="end">
                <ion-icon name="close-circle-outline" style="font-size:xx-large"
                  (click)="isImageModal=false"></ion-icon>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
          

            <app-slideshow-portfolios [imagesUpload]="object"
              [autoPlay]=false [brochure]="[]"
              *ngIf="isImageModal"></app-slideshow-portfolios>
          </ion-content>
        </ng-template>
      </ion-modal>

    </ion-content>
  </ion-tabs>
</ion-app>