import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';

import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';

const TEAM_DB = 'team_members';
const MOM_REFERENCES = 'mom_references';
const MESSAGES_DB = 'messages';

@Injectable({
  providedIn: 'root'
})

export class ArchitectsService {

  private supabase: SupabaseClient;
  private supaBaseUpload: SupabaseClient;

  isRecording: boolean = false;
  recordedAudio: string;
  audioFile: any;
  fileName: string;

  constructor(private http: HttpClient,) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
    // this.supaBaseUpload=createClient(environment.supabaseuploadUrl, environment.supabaseuploadKey)
  }




  getProjectList() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/projects_home_page', {

      params: {
        select: '*'
      }
    });
  }
  updateSiteVisit(obj, id) {
    return this.supabase
      .from('site_visits')
      .update(obj)
      .eq('id', id)
      .then((result) => result)
  }
  updateProject(id, obj) {
    return this.supabase
      .from('projects')
      .update(obj)
      .eq('id', id)
      .then((result) => result.data)

  }
  getProjectTypes() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/project_types?select=*', {

      params: {
        select: '*'
      }
    });
  }

  getTagList() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/tags', {
      params: {
        select: '*'
      }
    });
  }

  getStageList(projectId) {
    return this.supabase
    .from('project_stages')
    .select('*')
    .eq('project_id', projectId)
    .then((result) => result)
  
  }

  // -----------MasterStage list-------------
  getMasterStageList(){
    return this.supabase
    .from('project_master_stages')
    .select('*')
    .then((result) => result)
  }

  getClientListByTeam(teamId: any) {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/clients?select=*&team=eq.' + teamId, {

    });
  }


  getProjectById(id: any) {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/projects_home_page?project_id=eq.' + id, {

      params: {
        select: '*'
      }
    });
  }

  // ---------To get time track data for grantt chart---------
  getTimetrackGranttChart(projectId){
    return this.supabase
    .from('project_time_tracking_chart')
    .select('*')
    .eq('project_id', projectId)
    .then((result) => result)
  }
  // ----------project doc count monthly---------

  getDashboardDetailsbyMonthly(projectId){
    return this.supabase
    .from('v_project_monthly')
    .select('*')
    .eq('project_id', projectId)
    .then((result) => result)

  }
  // ----------project doc count weekly---------
  getDashboardDetailsbyWeekly(projectid){
    return this.supabase
    .from('v_project_weekly')
    .select('*')
    .eq('project_id', projectid)
    .then((result) => result)
  }
  // ----------project doc count yearly---------

  getDashboardDetailsbyYearly(projectId){
    return this.supabase
    .from('v_project_yearly')
    .select('*')
    .eq('project_id', projectId)
  .order('year',{ ascending:true })

    .then((result) => result)
  }
  // ----------ticket count yearly count weekly---------

  getTicketDashboardValueYearly(projectId){
    return this.supabase
    .from('yearly_dashboard_tickets_count')
    .select('*')
    .eq('project_id', projectId)
  

    .then((result) => result)
  }
  // ----------tickets count monthly---------

  getTicketDashboardValueMonthly(projectId){
    return this.supabase
    .from('monthly_dashboard_tickets_count')
    .select('*')
    .eq('project_id', projectId)
  

    .then((result) => result)
  }
   // ----------tickets count weekly---------

   getTicketDashboardValueWeekly(projectId){
    return this.supabase
    .from('weekly_dashboard_tickets_count')
    .select('*')
    .eq('project_id', projectId)
  

    .then((result) => result)
  }
  getTimeTrackLeadDashboardYearly(projectId){
    return this.supabase
    .from('yearly_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)

    .then((result) => result)
  }
  // -------------To get all team mebers data monthly-
  getTimeTrackLeadDashboardMonthly(projectId){
    return this.supabase
    .from('monthly_lead_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)

    .then((result) => result)
  }// -------------To get all team mebers data weekly-
  getTimeTrackLeadDashboardWeekly(projectId){
    return this.supabase
    .from('weekly_lead_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)

    .then((result) => result)
  }
  getTimeTrackDashboardWeekly(projectId,teamMemberId){
    return this.supabase
    .from('weekly_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)
    .eq('team_member_id', teamMemberId)
    .order('month_order', { ascending: true })

    .then((result) => result)
  }
  getTimeTrackDashboardYearly(projectId,teamMemberId){
    return this.supabase
    .from('yearly_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)
    .eq('team_member_id', teamMemberId)

    .then((result) => result)
  }
  
  // -----------Dashboard TimeTrack------
  getTimeTrackDashboardMonthly(projectId,teamMemberId){
    return this.supabase
    .from('monthly_dashboard_time_tracking')
    .select('*')
    .eq('project_id', projectId)
    .eq('team_member_id', teamMemberId)
    .order('month_order', { ascending: true })

    .then((result) => result)
  }
  getTimeTrackListByRangeandFilter(projectId,searchText,sort,range){
    return this.supabase
    .from('time_tracking_home_page')
    .select('*')
    .eq('project_id', projectId)
    .range(range,range+10)
    .ilike('task_name', `%${searchText}%`)

  .order('created_at',{ ascending: sort==='new-to-old'?false:true })

    .then((result) => result.data)
   
  }

  getSiteVisitId(siteId){
    return this.supabase
    .from('site_visits_detail_page')
    .select('*')
    .eq('id', siteId)
   

    .then((result) => result.data)
  }

  getSiteVisitById(id) {
    return this.supabase
      .from('site_visits_detail_page')
      .select('*')
      .eq('project_id', id)
      .range(0,10)
    .order('created_at', { ascending: false })

      .then((result) => result.data)
  }
  filterSiteVisitList(projectId, sort, range){
    return this.supabase
    .from('site_visits_detail_page')
    .select('*')
    .eq('project_id', projectId)
    .range(range,range+10)
    .order('created_at', { ascending: sort==='new-to-old'?false:true })
    .then((result) => result.data)
  }
  projectById(id) {
    return this.supabase
      .from('projects')
      .select('*')
      .eq('id', id)
      .then((result) => result.data)

  }
  getCreateProjectStatus() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/project_status?status=eq.STARTED&select=*');
  }

  // uploadFile(formData:any,fileName:any) {
  //   return this.http.post<any>(environment.supabaseUrl +'/storage/v1/object/octopi-media/'+ fileName,formData,{
  //     headers: {
  //       'apikey': environment.supabaseKey,
  //       'Authorization': 'Bearer ' + environment.supabaseKey,
  //       'Content-Type': 'multipart/form-data',
  //     }
  //   });
  // }

  // uploadFile(formData:any,fileName:any) {
  //   const req = new HttpRequest('POST', environment.supabaseUrl +'/storage/v1/object/octopi-media/'+ fileName , formData);
  //   return this.http.request(req);
  // }

  uploadFile(formData: any, fileName: any) {
    // return this.supaBaseUpload.storage.from('octopi-media').upload(fileName,formData)
    return this.supabase.storage.from('octopi-media').upload(fileName, formData);
  }
  getLocation(lat_value, 
    long_value, 
    project_id){
      return this.supabase
      .rpc('check_project_location', {
        lat_value, 
        long_value, 
        project_id
      })
      .then((result) => result);

  }
  downloadFile(path) {
    let temp;
    temp = environment.supabaseUrl + '/storage/v1/object/octopi-media/' + path;
    //loadImage(url: string) {
    const headers = new HttpHeaders({
      apikey: environment.supabaseKey,
      Authorization: 'Bearer ' + environment.supabaseKey
    });

    this.http.get(temp, { headers, responseType: 'blob' }).subscribe(
      {
        next: (data) => {
          //console.log(URL.createObjectURL(data), data)
          return URL.createObjectURL(data);
        }, error: (error) => {
          //console.log("image error", error)
        }
      }
    );
  }
  download(url) {

    const { data } = this.supabase.storage
      .from('octopi-media')
      .getPublicUrl(url, {
        download: true,
      })
    return data

  }
  getPublicUrl(url) {
    const { data } = this.supabase
      .storage
      .from('octopi-media')
      .getPublicUrl(url)
    return data

  }


  createMoM(obj: any) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/project_moms', obj, {

    });
  }
  createTicket(obj) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/tickets', obj, {

    });
  }
  addTag(obj: any) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/tags', obj, {

    });
  }
  addTicketType(obj) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/ticket_types', obj, {

    });
  }

  filterProject(search,sort,start,teamId) {
    return this.supabase
      .from('projects_home_page')
      .select("*")
      .ilike('project_name', `%${search}%`)
      .range(start, start + 10)
      .order('project_start_date', { ascending: sort==='new-to-old'?false:true })
      .eq('team_member_id', teamId)
      // .ilike('project_tag_names', search)
      .then((result) => result.data)

  }
  addReference(obj: any) {
    // return this.http.post<any>(environment.supabaseUrl +'/rest/v1/mom_references',obj);
    return this.supabase
      .from('mom_references')
      .insert([obj])
      .then((result) => result.data)
  }

  addNewClient(obj: any) {
    obj.first_login=true
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/clients', obj, {

    });
  }

  addNewProject(obj: any) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/projects', obj, {

    });
  }

  addNewTimeLog(obj: any) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/project_time_tracking', obj, {

    });
  }

  updateMOM(obj, id) {
    return this.supabase
      .from('project_moms')
      .update(obj)
      .eq('id', id)
      .then((result) => result);
  }

  updateTicket(obj, id) {
    return this.supabase
      .from('tickets')
      .update(obj)
      .eq('id', id)
      .then((result) => result.data);
  }
  updateProjectDoc(obj, id) {
    return this.supabase
      .from('project_documents')
      .update(obj)
      .eq('id', id)
      .then((result) => result);
  }
  updateTimeLog(obj: any, id) {
    return this.http.patch<any>(environment.supabaseUrl + '/rest/v1/project_time_tracking?id=eq.' + id, obj, {

    });
  }
  getTeammemberById(id) {
    return this.supabase
      .from('team_members')
      .select('*')
      .eq('id', id)
      .then((result) => result.data);
  }
  getTicketById(id) {
    return this.supabase
      .from('tickets')
      .select('*')
      .eq('id', id)
      .then((result) => result.data);
  }
  getTeammembers(teamId: any) {
    return this.supabase
      .from(TEAM_DB)
      .select(`
    email,
    id,
    name,
    phone,
    team,
    roles(*)
  `).match({ team: teamId })
      .then((result) => result.data);
  }

  getAllReferences() {
    return this.supabase
      .from(MOM_REFERENCES)
      .select('*')
      .then((result) => result.data);
  }
  getAllTickets(id) {
    return this.supabase
      .from('tickets_detail_page')
      .select('*')
      .eq('project_id', id).then((result) => result.data);
  }

  
  getAllTicketsByRangeandFilter(projectId,searchText,sort,range){
    return this.supabase
    .from('tickets_detail_page')
    .select('*')
    .eq('project_id', projectId)
    .range(range, range + 10)
    .ilike('subject', `%${searchText}%`)
   
    .order('created_at', { ascending: sort==='new-to-old'?false:true}).then((result) => result.data);
  }
  getAllTicketTypes() {
    return this.supabase
      .from('ticket_types')
      .select('*')
      .eq('is_custom', false)
      .then((result) => result.data);
  }
  getAllMOMs(id) {
    return this.supabase
      .from('project_moms')
      .select('*')
      .eq('project_id', id).then((result) => result.data);
  }
  getAllProjectMOMs(id){
    return this.supabase
    .from('project_moms_detail')
    .select('*')
    .eq('project_id', id).then((result) => result.data);
  }
  // ----------getMom byId-------
  getMOMsById(momId){
    return this.supabase
    .from('project_moms_detail')
    .select('*')
    .eq('id', momId)
   .then((result) => result.data);
  }
  getAllMOMsByIdandRange(projectId,searchText,sort,range){
    return this.supabase
    .from('project_moms_detail')
    .select('*')
    .eq('project_id', projectId)
    .range(range, range + 10)
    .ilike('name', `%${searchText}%`)
   
    .order('created_at', { ascending: sort==='new-to-old'?false:true}).then((result) => result.data);
  }
// -------Toget project doc by Id-
  getAllProjectDocsById(docid){
    return this.supabase
    .from('project_documents')
    .select('*')
    .eq('id', docid)
    .then((result) => result.data);
  }
  getAllProjectDocsByIdandRange(id,searchtext,sort,range){
    return this.supabase
      .from('project_documents_detail')
      .select('*')
      .eq('project_id', id)
      .range(range, range + 10)
      .ilike('name', `%${searchtext}%`)
     
      .order('created_at', { ascending: sort==='new-to-old'?false:true}).then((result) => result.data);
  }

  getAllProjectDocs(id) {
    return this.supabase
      .from('project_documents')
      .select('*')
      .eq('project_id', id)
      .order('created_at', { ascending: true }).then((result) => result.data);
  }
  getProjectDocsById(id) {
    return this.supabase
      .from('project_documents')
      .select('*')
      .eq('id', id).then((result) => result.data);
  }
  getmessages(id, start, msg) {
    return this.supabase
      .from('ticket_conversations_detail_page')
      .select('*')
      .eq('ticket_id', id)
      .range(start, start + 20)
      .ilike('message', `%${msg}%`)

      .order('created_at', { ascending: false }).then((result) => result.data);
  }
  // -----------Events conversation------------
  geteventmessages(id, start, msg) {
    return this.supabase
      .from('event_conversations_detail_page')
      .select('*')
      .eq('event_id', id)
      .range(start, start + 20)
      .ilike('message', `%${msg}%`)

      .order('created_at', { ascending: false }).then((result) => result.data);
  }
  filterMessages(e, id) {
    return this.supabase
      .from('ticket_conversations_detail_page')
      .select("*")
      .eq('ticket_id', id)
      .ilike('message', `%${e}%`)
      .range(0, 20)
      .order('created_at', { ascending: false }).then((result) => result.data);

  }
  filterEventMessages(e,id){
    return this.supabase
    .from('event_conversations_detail_page')
    .select("*")
    .eq('event_id', id)
    .ilike('message', `%${e}%`)
    .range(0, 20)
    .order('created_at', { ascending: false }).then((result) => result.data);
  }
  getRoles() {
    return this.supabase
      .from('roles')
      .select('*').then((result) => result.data);
  }
  getDesignation(){
    return this.supabase
    .from('roles')
    .select('*')
    .eq('is_architect',true).then((result) => result.data);
  }
  async createUser(mail: any) {
    const credentials = {
      email: mail,
      password: 'Testme123'
    };
    return this.supabase.auth.signUp(credentials);
  }

  async createTeamMember(name: any, mail: any, ph: any, roleId: any, userId: any, teamId: any) {
    const newgroup = {
      name: name,
      email: mail,
      phone: ph,
      role: roleId,
      team: teamId,
      user_id: userId,
      first_login:true,
      profile_photo_object_key:{publicUrl:'https://ionicframework.com/docs/img/demos/avatar.svg'}

    };
    return this.supabase.from(TEAM_DB).insert(newgroup).select().single();
  }

  getTimeTrackList(id) {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/time_tracking_home_page', {
      params: {
        select: '*',
        project_id: 'eq.' + id
      }
    });
  }

  getParentStageList() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/project_master_stages', {
      params: {
        select: '*',
        is_parent: 'eq.true'
      }
    });
  }

  getChildStageList() {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/project_master_stages', {
      params: {
        select: '*',
        is_parent: 'eq.false'
      }
    });
  }
  updateStage(obj,id){
    return this.supabase
    .from('project_stages')
    .update(obj)
    .eq('id', id)
    .then((result) => result.data)
  }
  // ----Add stage details for chart-

  addNewStage(obj: any) {
    return this.http.post<any>(environment.supabaseUrl + '/rest/v1/project_stages', obj);
  }

  getStageUUIdList(projectId,uuid){
    return this.supabase
    .from('project_stages')
    .select('*')
    .eq('uuid', uuid)
    .eq('project_id', projectId)
    .then((result) => result);
  }
  // ----GEt stage details by id for chart-
  stageById(id){
    return this.supabase
    .from('project_stages')
    .select('*')
    .eq('id', id)
    .then((result) => result);
  }
  // ----Delete stage details for chart-

  deleteStage(id){
    return this.supabase
    .from('project_stages')
    .delete()
    .eq('id', id)
    .then((result) => result);

  }
  getTimeTrackById(id) {
    return this.http.get<any[]>(environment.supabaseUrl + '/rest/v1/time_tracking_home_page', {
      params: {
        select: '*',
        task_id: 'eq.' + id
      }
    });
  }
  createDoc(obj: any) {
    return this.supabase
      .from('project_documents')
      .insert([
        obj
      ])
      .then((result) => result);
  }
  saveSiteVisit(data) {
    return this.supabase
      .from('site_visits')
      .insert([
        data
      ])
      .then((result) => result);
  }

  // -----------Real time ticket messages----------
  getRealtimeMessage(ticketId) {
    const changes = new Subject()
    this.supabase
      .channel('any')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'tickets_conversations' }, payload => {
          changes.next(payload)
          //console.log(payload)
        })
      .subscribe()

    return changes.asObservable()

  }
  // -----------Real time Event messages----------

  geteventRealtimeMessage(ticketId) {
    const changes = new Subject()
    this.supabase
      .channel('any')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'events_conversations' }, payload => {
          changes.next(payload)
          //console.log(payload)
        })
      .subscribe()

    return changes.asObservable()

  }
  sendMessage(data) {

    return this.supabase
      .from('tickets_conversations')
      .insert([
        data
      ])
      .then((result) => result.data);

  }
  sendEventMessage(data){
    return this.supabase
    .from('events_conversations')
    .insert([
      data
    ])
    .then((result) => result.data);
  }
  // Vendor Inviting
  inviteVendor(datas) {
    return this.http.post<any>(environment.supabaseUrl + '/auth/v1/invite', {
      "email": datas.email
    });

    // return https://qlqwlysrdcdgmgknsker.supabase.co/auth/v1/invite
    // return this.supabase
    // .from('vendors')
    // .insert(data)
    // .then((result) => result);
    // return this.supabase.auth.api.inviteUserByEmail(datas.email)

  }
  getVendorList(id) {
    return this.supabase
      .from('vendors')
      .select('*')
      .eq('team', id)
      .then((result) => result);
  }
  getVendorDetailList(id) {
    return this.supabase
      .from('vendors_detail')
      .select('*')
      .eq('team', id)
      .then((result) => result);
  }
  // get VendorList
  getVendors(orgId) {
    return this.supabase
      .from('vendors')
      .select('*')
      .eq('referring_org', orgId)
      .order('created_at', { ascending: true })
      .then((result) => result);
  }
  // get VendorList Based On OrgId

  getVendorsFromOrganization(orgId,projectId){
    return this.supabase
    .from('vendors_not_added')
    .select('*')
    .eq('referring_org', orgId)
    .eq('project_id', projectId)
    
    .order('created_at', { ascending: true })
    .then((result) => result);
  }
  createVendorprofile(data) {
    data.first_login=true
    return this.supabase
      .from('vendors')
      .insert([data])
      .then((result) => result);
  }
  // to fetch vendorlist with isFavorite boolean 
  getVendorTeamList(id, text,sort,start) {
    return this.supabase
      .from('team_members_vendors_detail')
      .select('*')
      .eq('team_member_id', id)
      .ilike('vendor_org_name', `%${text}%`)
      .range(start,start+10)
      .order('created_at',{ ascending: sort==='new-to-old'?false:true })
     

      // .eq('vendor_org_name',text)
      .then((result) => result);
  }
  // Save Favorite to vendor
  saveFavorite(vendorId, teamemberId) {
    return this.supabase
      .from('fav_vendors')
      .insert([
        { vendor_id: vendorId, team_member_id: teamemberId }
      ])
      .then((result) => result);

  }
  // Delete Favorite to vendor
  deleteFavorite(vendorId, teamemberId) {
    return this.supabase
      .from('fav_vendors')
      .delete()
      .eq('vendor_id', vendorId)
      .eq('team_member_id', teamemberId)
      .then((result) => result);

  }


  // Adding vendor to project
  addVendorToProject(comments,project_id,shared_by_user_id,shared_project_doc_ids,subject,v_vendor_id) {
    // return this.supabase
    //   .from('project_vendors')
    //   .insert([
    //     value
    //   ])
    //   .then((result) => result);
      return this.supabase
      .rpc('project_vendors_save', {
        comments,project_id,shared_by_user_id,shared_project_doc_ids,subject,v_vendor_id
      })
      .then((result) => result);
      
  }
// ---------Share ticket with selected vendors----------
  saveAllVendorToTicket(projectid, 
    shared_by_user_id, 
    tickets_id, 
    vendor_ids){
      return this.supabase
      .rpc('add_vendors_to_tickets', {
        projectid, 
        shared_by_user_id, 
        tickets_id, 
        vendor_ids
      })
      .then((result) => result);

  }
  // share sit-visit to vendors, remove and adding done here

  shareSiteVisitVendors(projectid,shared_by_user_id,site_visits_id,vendor_ids){
    return this.supabase
    .rpc('add_vendors_to_site_visits', {
      projectid,shared_by_user_id,site_visits_id,vendor_ids
    })
    .then((result) => result);
  }
  // To get all vendors added based on project
  getVendorAddedProject(projectId) {
    return this.supabase
      .from('project_vendors_detail')
      .select('*')
      .eq('project_id', projectId)
      .then((result) => result);
  }

  // -----------To get all vendors unshared SiteVisit-----------

  getAllUnsharedVendorsList(siteVisitId){
    return this.supabase
    .from('unshared_site_visit_vendors')
    .select('*')
    .eq('site_visit_id', siteVisitId)
    .then((result) => result);
  }
  // -----------To get all vendors unshared Moms-----------

  getAllUnsharedVendorsMomList(momId){
    return this.supabase
    .from('unshared_mom_vendors')
    .select('*')
    .eq('mom_id', momId)
    .then((result) => result);
  }
    // To get all vendors added based on project VirtualScroll
    getVendorAddedProjectRange(projectId,searchText,sort,range) {
      return this.supabase
        .from('project_vendors_detail')
        .select('*')
        .eq('project_id', projectId)
        .range(range,range+10)
        .ilike('vendor_org_name', `%${searchText}%`)
    
      .order('created_at',{ ascending: sort==='new-to-old'?false:true })
        .then((result) => result);
    }
    shareSiteVisitVendorsMom(moms_id,projectid,shared_by_user_id,vendor_ids){
      
      return this.supabase
      .rpc('add_vendors_to_mom', {
        moms_id,
        projectid,
        shared_by_user_id,
        vendor_ids
      })
      .then((result) => result);
    }
  // ------------Share vendor to selected project docId------------
  saveAllVendorToProjectDoc(project_docs_id, projectid,shared_by_user_id, vendor_ids) {
  
    return this.supabase
      .rpc('add_vendors_to_project_docs', {
        project_docs_id,
        projectid,
        shared_by_user_id,
        vendor_ids
      })
      .then((result) => result);

  }
  // --------------To get all Shared Vendors List based on project Doc id--------------
  getAllVendorsSharedProjectDoc(projectDocId) {
    return this.supabase
      .from('vendor_project_docs_home_page')
      .select('*')
      .eq('project_doc_id', projectDocId)
      .then((result) => result);
  }
  // --------------To get all Shared Vendors List based on ticket id--------------

  getAllVendorsSharedTickets(ticketId){
    return this.supabase
    .from('vendor_tickets_home_page')
    .select('*')
    .eq('ticket_id', ticketId)
    .then((result) => result);
    
  }
// --to get all shared vendors for particular site visit id----
  getSharedVendor(siteVisitId){
    return this.supabase
    .from('vendor_site_visits_home_page')
    .select('*')
    .eq('id', siteVisitId)
    .eq('is_attendee', false)
    
    .then((result) => result);
  }
// --to get all shared vendors for particular site visit id----

  getSharedVendorMom(momId){
    return this.supabase
    .from('vendor_mom_home_page')
    .select('*')
    .eq('id', momId)
    .eq('is_attendee', false)
    
    .then((result) => result);
  }
  // -----------To get parent message---------
  getParentmessage(id,ticketId){
    return this.supabase
    .from('ticket_conversations_detail_page')
    .select('*')
    .eq('id', id)
    .eq('ticket_id', ticketId)
    
    .then((result) => result);
  }

  // ---------------To get All events based on projectId------------
  getAllEventsByRangeandFilter(userId,projectId,start, end, range){
    return this.supabase
    .from('timeline_home_page')
    .select('*')
    .eq('project_id', projectId)
    .eq('user_id', userId)
    .range(range,range+10)

    .gte('created_at_date', start)
    .lte('created_at_date', end)
    
    .then((result) => result);
  
  }


    // ---------Like post---------
    likePost(obj){
      return this.supabase
      .from('user_fav_events')
      .insert([
      obj
      ])
    .then((result) => result);
  
    }
  
    // --------Remove like from post---------
    removelikePost(ticketId,userId){
      return this.supabase
    .from('user_fav_events')
    .delete()
    .eq('event_id',ticketId )
    .eq('user_id',userId )
    .then((result) => result);
  
    }

    // ---------To get all users list liked for particular post id-------
    getAlllikedusers(ticketid){
      return this.supabase
      .from('fav_event_user_details')
      .select('*')
      .eq('event_id', ticketid)
    .then((result) => result);

    }
    // -------Get event by id--------
    getEventById(eventId){
      return this.supabase
      .from('timeline_home_page')
      .select('*')
      .eq('event_id', eventId)
      
      
      .then((result) => result);
    }

    // -------Get all Vendor events-----
  getAllVendorEventsByRangeandFilter(projectId,userId,vendorId,start,end,range){
    return this.supabase
    .from('timeline_home_page')
    .select('*')
    .eq('project_id', projectId)
    .eq('user_id', userId)
    .eq('vendor_id', vendorId)
    .range(range,range+10)

    .gte('created_at_date', start)
    .lte('created_at_date', end)
    
    .then((result) => result);
  }
  // ----------To get all client details for profile-------
  getAllClientDetails(searchText,sort,range){
   return this.supabase
  .from('client_detail')
  .select('*')
  .range(range,range+10)
  .ilike('name', `%${searchText}%`)

  .order('created_at',{ ascending: sort==='new-to-old'?false:true })
  .then((result) => result);

  }
// ----------Toget client details by id--------
  getClientById(id){
    return this.supabase
  .from('client_detail')
  .select("*")
  .eq('id', id)
  .then((result) => result.data);

  }
// --Project Details of client-
getProjectByClientId(clientId: any) {
    return this.supabase
    .from('projects_home_page')
    .select('*')
    .eq('client_id',clientId)
    .order('project_start_date',{ ascending: true })

    .then((result) => result.data);

  
  }
}
