<ion-app>
  <ion-tabs>
 

      <ion-content class="ion-padding">
        <ion-grid class="pt-5 ml-6 mt-10">
          <ion-row>
            <ion-col style="padding-left:0">
              <ion-icon class="text-3xl" (click)="goback()"
                 name="arrow-back-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
  <ion-grid class="p-7 form-grid">
    <ion-row>
      <ion-col>
        <h1 class="text-2xl uppercase poppinsFamily">VIEW TICKET</h1>
      </ion-col>
    </ion-row>
 
      <ion-row>
        <ion-col>
          <h1 class="text-1xl  uppercase text-octopi-secondary">SUBJECT</h1>
          <ion-item class="ion-no-padding font-black">
           {{ticketView?.subject}}
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="mt-5">
        <ion-col class="flex">
          <h1 class="text-1xl  uppercase text-octopi-secondary">TICKET TYPE</h1>
          <!-- <ion-icon name="add-circle-outline" *ngIf="addEdit !== 'EDIT'" (click)="ticketNew()" style="font-size:x-large;"></ion-icon> -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-padding font-black">
           
            <p class="font-black">{{ticketView?.ticket_type_name}}</p>
          </ion-item>
        </ion-col>
      </ion-row>
   
      <ion-row class="mt-5">
        <ion-col class="flex " style="align-items: center;">
          <h1 class="text-1xl  uppercase text-octopi-secondary">PRIORITY</h1>
    
          <ion-range style="padding-top: 0;" [value]="ticketView?.priority" pin="true" min="0"
            max="10" step="1" snaps="true" ticks="true" 
             disabled="true"></ion-range>
          <!-- </ion-item> -->
        </ion-col>
      </ion-row>

      <ion-row class="mt-5">
        <ion-col class="flex items-center">
          <h1 class="text-1xl  uppercase text-octopi-secondary">PROJECT DOCs</h1>
      

        </ion-col>
      </ion-row>
  
      <ion-row *ngIf="ticketView?.project_doc_ids?.length>0">
        <ion-item class="ion-no-padding font-black" (click)="openViewModal(projectId)" style="width:100%"
          *ngFor="let projectId of ticketView?.project_doc_ids">
          <ion-icon  name="document-text-outline"></ion-icon>
          <span>{{getProjectDocName(projectId)}}</span>
        </ion-item>
      </ion-row>
      <ion-row class="mt-5" >
        <h1 class="text-1xl  uppercase text-octopi-secondary">STATUS</h1>
      </ion-row>
      <ion-row >
       
        <ion-item  class="w-full ion-no-padding font-black">
          {{ticketView?.status}}
        </ion-item>
      </ion-row>
      <ion-row class="mt-5">
        <ion-col>
          <h1 class="text-1xl  uppercase text-octopi-secondary">COMMENTS</h1>
          <ion-item class="ion-no-padding font-black">
           {{ticketView?.comments}}
          </ion-item>
        </ion-col>
      </ion-row>

  </ion-grid>

</ion-content>
  <!-- ProjectDocViewModal -->
  <ion-content class="ion-padding">
    <ion-modal [isOpen]="docView">

      <ng-template>
        <ion-header style="z-index: auto;">
          <ion-toolbar>
            <!-- <ion-title>{{(!isView)?'Add an MOM':'View MOM'}}</ion-title> -->
            <ion-buttons slot="end">
              <ion-icon name="close-circle-outline" style="font-size:xx-large" (click)="docView=false"></ion-icon>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">

          <app-galleryupload [portfolioImages]="false" *ngIf="docView" [isEdit]="false" [slidesImage]="slidesImage"
            [activeIndex]="0" [isImageModal]="true"></app-galleryupload>
        </ion-content>
      </ng-template>
    </ion-modal>
    </ion-content>
</ion-tabs>
</ion-app>