import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { AlertController, InfiniteScrollCustomEvent, LoadingController, ModalController, Platform } from '@ionic/angular';
import { ArchitectsService } from '../../services/architects.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
  providers: [DatePipe]
})

export class ChatWidgetComponent implements OnInit {
  messages: any = []
  message = null
  @Input()
  event
  @Input()
  statusChanger
  @Input()
  projectId
  @Input()
  ticketId
  @Input()
  userId
  @ViewChild('sendButton') myElementRef: ElementRef;
  replyChat: any;
  showReply: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('uploaded') uploaded: ElementRef;
  uploadedImages: any = [];
  fileAttachment: any = [];
  uploadOpen: boolean = false;
  uploadCompleted: boolean = false;
  isImageModal: boolean = false;
  activeIndex: any;
  slidesImage: any;
  isEdit: boolean;
  status: any = null;
  @Output() saveStatus = new EventEmitter();
  searchTerm: any = "";
  virtualScroll: boolean = true;
  showmessage: boolean;

  constructor(private architectsService: ArchitectsService, private datePipe: DatePipe, private platform: Platform,
    private modalController:ModalController,private authService:AuthService,
    private alertController: AlertController, private sanitizer: DomSanitizer, private loadingController: LoadingController
  ) {

  }

  async ngOnInit() {

    const loading = await this.loadingController.create();
    this.searchTerm = "";
    await loading.present();
    if(!this.event){
    this.architectsService.getmessages(this.ticketId, 0,
      "").then(async (data) => {
        let apiRes
    await loading.dismiss();

        apiRes = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < apiRes.length / 2; i++) {
          let temp = apiRes[i];
          apiRes[i] = apiRes[apiRes.length - i - 1];
          apiRes[apiRes.length - i - 1] = temp;
        }
        this.messages = apiRes
        setTimeout(() => {
          const myElement = this.myElementRef.nativeElement;

          myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 10);

      })
    this.architectsService.getRealtimeMessage(this.ticketId).subscribe(update => {
      if (update['new'].ticket_id == this.ticketId && update['new'].project_id == this.projectId) {
        if (update['new']) {
          let newlyAdded = update['new']
          if (update['new'].title) {
            this.saveStatus.emit(update['new'].title)
          }
          // }
          if(update['new'].parent_conversation_id){
          this.architectsService.getParentmessage(update['new'].parent_conversation_id,update['new'].ticket_id).then((data) => {
            newlyAdded.parent_conversation_message=data.data[0].message
            newlyAdded.parent_conversation_attachments=data.data[0].attachments
            newlyAdded.parent_user_name=data.data[0].user_name
            newlyAdded.parent_conversation_created_at=data.data[0].created_at
            
          })
            
          }
          this.authService.getUserDetails(update['new'].user_id).then((data) => {
            if(data.data){
            newlyAdded.user_name = data.data[0]?.user_name
            newlyAdded.profile_photo_object_key = { publicUrl: data.data[0]?.profile_photo_object_key?.publicUrl }

            newlyAdded.myDate = this.datePipe.transform(newlyAdded.created_at, 'dd-MMM-yyyy');
            newlyAdded.myTime = this.datePipe.transform(newlyAdded.created_at, 'h:mm a');

            this.messages.push(newlyAdded)
            setTimeout(() => {
              const myElement = this.myElementRef.nativeElement;
              myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 30);
          }

          })
        }
      }
    })
  }else{
    this.architectsService.geteventmessages(this.ticketId, 0,
      "").then(async (data) => {
        let apiRes
    await loading.dismiss();

        apiRes = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < apiRes.length / 2; i++) {
          let temp = apiRes[i];
          apiRes[i] = apiRes[apiRes.length - i - 1];
          apiRes[apiRes.length - i - 1] = temp;
        }
        this.messages = apiRes
        setTimeout(() => {
          const myElement = this.myElementRef.nativeElement;

          myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 10);

      })
    this.architectsService.geteventRealtimeMessage(this.ticketId).subscribe(update => {
      if (update['new'].event_id == this.ticketId && update['new'].project_id == this.projectId) {
        if (update['new']) {
          let newlyAdded = update['new']
          if (update['new'].title) {
            this.saveStatus.emit(update['new'].title)
          }
          // }
          if(update['new'].parent_conversation_id){
          this.architectsService.getParentmessage(update['new'].parent_conversation_id,update['new'].ticket_id).then((data) => {
            newlyAdded.parent_conversation_message=data.data[0].message
            newlyAdded.parent_conversation_attachments=data.data[0].attachments
            newlyAdded.parent_user_name=data.data[0].user_name
            newlyAdded.parent_conversation_created_at=data.data[0].created_at
            
          })
            
          }
          this.authService.getUserDetails(update['new'].user_id).then((data) => {
            if(data.data){
            newlyAdded.user_name = data.data[0]?.user_name
            newlyAdded.profile_photo_object_key = { publicUrl: data.data[0]?.profile_photo_object_key?.publicUrl }

            newlyAdded.myDate = this.datePipe.transform(newlyAdded.created_at, 'dd-MMM-yyyy');
            newlyAdded.myTime = this.datePipe.transform(newlyAdded.created_at, 'h:mm a');

            this.messages.push(newlyAdded)
            setTimeout(() => {
              const myElement = this.myElementRef.nativeElement;
              myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 30);
          }

          })
        }
      }
    })
  }
  }
  ionViewDidEnter() {
  }

  filterChat(e) {
    this.virtualScroll = true
    if(!this.event){
    this.architectsService.filterMessages(e.target.value, this.ticketId).then((data) => {
      for (let i = 0; i < data.length / 2; i++) {
        let temp = data[i];
        data[i] = data[data.length - i - 1];
        data[data.length - i - 1] = temp;
      }
      this.messages = data.map(element => {

        element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
        element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

        return element
      });
      setTimeout(() => {
        const myElement = this.myElementRef.nativeElement;
        myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 30);
    })
  }else{
    this.architectsService.filterEventMessages(e.target.value, this.ticketId).then((data) => {
      for (let i = 0; i < data.length / 2; i++) {
        let temp = data[i];
        data[i] = data[data.length - i - 1];
        data[data.length - i - 1] = temp;
      }
      this.messages = data.map(element => {

        element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
        element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

        return element
      });
      setTimeout(() => {
        const myElement = this.myElementRef.nativeElement;
        myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 30);
    })
  }
  }
  statusChange(value) {

    
    if (value === this.status) {
      this.status = null
    } else {
      this.status = value

    }
  }
  async uploadFile() {
    this.uploadOpen = true
    this.isEdit = true
    // if (this.platform.is('cordova')) {
    //   const file = await this.fileChooser.open();
    //   this.uploadFileFromInput(file);
    // } else {
    // this.fileInput.nativeElement.click();
    // }
  }
  getNumberUpload(l) {
    return l - 6

  }
  getNumber(l) {
    return l - 4
  }
  removeUpload(e) {
    this.uploadedImages = this.uploadedImages.filter((image) => image !== e)

  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  uploadMore(e) {

    this.uploadedImages = e
  }
  async uploadFileFromInput(event) {

    if (event) {
      const loading = await this.loadingController.create();
      await loading.present();
      for (let i = 0; i < event.target.files.length; i++) {
        this.architectsService.uploadFile(event.target.files[i], uuidv4()).then(async (data) => {

          let obj = {
            "publicUrl": this.architectsService.getPublicUrl(data.data.path).publicUrl,
            "caption": "",
            "fileName": event.target.files[i].name,
            "document_type": event.target.files[i].type.slice(0, event.target.files[i].type.lastIndexOf("/")) === 'application' ? event.target.files[i].type.slice(event.target.files[i].type.lastIndexOf("/") + 1, event.target.files[i].type.length) : event.target.files[i].type.slice(0, event.target.files[i].type.lastIndexOf("/"))
          }
          await loading.dismiss();

          this.uploadedImages.push(obj)

        })
      }
      setTimeout(() => {
        const myElement = this.myElementRef.nativeElement;
        myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 30);
    }
  }

  removeReply() {
    this.replyChat = null
    this.showReply = false
  }
  reply(chat) {
    this.replyChat = chat
    this.showReply = true
    setTimeout(() => {
      const myElement = this.myElementRef.nativeElement;
      myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  }
  getParent(id) {
    return this.messages.filter((chat) => chat.id === id)[0]
  }
  gotoParent(id) {
    setTimeout(() => {

    var elmnt = document.getElementById(id);
    if (elmnt !=null ) {
      elmnt?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    } else {

      let elmnt1 = document.getElementById(this.messages[0]?.id);

      elmnt1?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    }
  },150)
  }
  openInModalUploaded(idx) {
    this.isImageModal = true
    this.activeIndex = idx
    this.isEdit = true
    this.slidesImage = this.uploadedImages
  }
  openInmodal(i, chat,msg) {
    this.isImageModal = true
    this.activeIndex = i
    this.isEdit = false
if(msg==='parent'){
  this.slidesImage = chat.parent_conversation_attachments

}else{
  this.slidesImage = chat.attachments

}
  }
  scrollIntoView() {
    setTimeout(() => {
      const myElement = this.myElementRef.nativeElement;

      myElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);

  }
  closeModal() {
    this.isImageModal = false;
    this.uploadOpen = false;
  }
  async sendMessage() {
    if (this.message == null && this.uploadedImages.length === 0) {
      this.showAlert('Error', "Please enter message to send", 'errorAlert')
      return
    }
    if (this.status) {
      this.saveStatus.emit(this.status)
    }
   
    if(!this.event){
      let obj = {
        message: this.message ? this.message : "",
        parent_conversation_id: this.showReply ? this.replyChat.id : null,
        user_id: this.userId,
        ticket_id: this.ticketId,
        project_id: this.projectId,
        attachments: this.uploadedImages,
        title: this.status ? this.status : null
      }
    this.architectsService.sendMessage(obj).then((data) => {

      // this.architectsService.getmessages(this.ticketId).then((data)=>{
      this.status = null
      this.showReply = false
      this.replyChat = null
      this.uploadedImages = []
      // this.messages=data
      this.message = null
      setTimeout(() => {
        const myElement = this.myElementRef.nativeElement;
        myElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 150);

      // })
    })
  }else{
    let obj = {
      message: this.message ? this.message : "",
      parent_conversation_id: this.showReply ? this.replyChat.id : null,
      user_id: this.userId,
      event_id: this.ticketId,
      project_id: this.projectId,
      attachments: this.uploadedImages,
      title: this.status ? this.status : null
    }
    this.architectsService.sendEventMessage(obj).then((data) => {

      // this.architectsService.getmessages(this.ticketId).then((data)=>{
      this.status = null
      this.showReply = false
      this.replyChat = null
      this.uploadedImages = []
      // this.messages=data
      this.message = null
      setTimeout(() => {
        const myElement = this.myElementRef.nativeElement;
        myElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 150);

      // })
    })
  }

  }
  async showAlert(title: any, msg: any, cssClass) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      cssClass: cssClass,
      buttons: ['OK'],
    });
    await alert.present();
  }
  onIonInfinite(ev) {
    if (this.virtualScroll && this.messages.length > 0) {
    if(!this.event){

      this.architectsService.getmessages(this.ticketId, this.messages.length, this.searchTerm).then((data) => {
        if (data.length === 0) {
          this.virtualScroll = false
          this.showmessage = true

          setTimeout(() => {
            this.showmessage = false
          }, 3000);
        }
        let lazyData = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < lazyData.length / 2; i++) {
          let temp = lazyData[i];
          lazyData[i] = lazyData[lazyData.length - i - 1];
          lazyData[lazyData.length - i - 1] = temp;
        }
        this.messages = [...lazyData, ...this.messages]
        setTimeout(() => {
          this.gotoParent(this.messages[this.messages?.length - 17]?.id)

        }, 150);

        setTimeout(() => {
          (ev as InfiniteScrollCustomEvent).target.complete();
        }, 100);
      })
    }else{
      this.architectsService.geteventmessages(this.ticketId, this.messages.length, this.searchTerm).then((data) => {
        if (data.length === 0) {
          this.virtualScroll = false
          this.showmessage = true

          setTimeout(() => {
            this.showmessage = false
          }, 3000);
        }
        let lazyData = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < lazyData.length / 2; i++) {
          let temp = lazyData[i];
          lazyData[i] = lazyData[lazyData.length - i - 1];
          lazyData[lazyData.length - i - 1] = temp;
        }
        this.messages = [...lazyData, ...this.messages]
        setTimeout(() => {
          this.gotoParent(this.messages[this.messages?.length - 17]?.id)

        }, 150);

        setTimeout(() => {
          (ev as InfiniteScrollCustomEvent).target.complete();
        }, 100);
      })
    }
    } else {
      setTimeout(() => {
        (ev as InfiniteScrollCustomEvent).target.complete();
      }, 50);
    }

  }
  loadOlderMessages() {
    if (this.virtualScroll) {
      if(!this.event){
      this.architectsService.getmessages(this.ticketId, this.messages.length, "").then((data) => {
        if (data.length === 0) {
          this.virtualScroll = false

        }
        let lazyData = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < lazyData.length / 2; i++) {
          let temp = lazyData[i];
          lazyData[i] = lazyData[lazyData.length - i - 1];
          lazyData[lazyData.length - i - 1] = temp;
        }

        this.messages = [...lazyData, ...this.messages]
      })
    }else{
      this.architectsService.geteventmessages(this.ticketId, this.messages.length, "").then((data) => {
        if (data.length === 0) {
          this.virtualScroll = false

        }
        let lazyData = data.map(element => {

          element.myDate = this.datePipe.transform(element.created_at, 'dd-MMM-yyyy');
          element.myTime = this.datePipe.transform(element.created_at, 'h:mm a');

          return element
        });
        for (let i = 0; i < lazyData.length / 2; i++) {
          let temp = lazyData[i];
          lazyData[i] = lazyData[lazyData.length - i - 1];
          lazyData[lazyData.length - i - 1] = temp;
        }

        this.messages = [...lazyData, ...this.messages]
      })
    }
    }
  }

  // -------------Dismiss All Modal------------
  // async ngOnDestroy(){
  //   let modal = await this.modalController.getTop();
  //   while (modal) {
  //     await modal.dismiss();
  //     modal = await this.modalController.getTop();
  //   }
  // }
}


