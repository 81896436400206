import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { VendorLayoutComponent } from './vendor-layout/vendor-layout.component';
import { ClientLayoutComponent } from './client-layout/client-layout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'common',
        loadChildren: () => import('../app/modules/common/common.module').then(m => m.commonModule),
      }
    ],
    canActivate: [AuthGuard],
    data: { roles: ['Junior Architect', 'Lead Architect', 'Senior Architect', 'External'] }

  },
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule),
      }
    ],
    data: { roles: ['ALL'] }

  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'architects',
        loadChildren: () => import('../app/modules/architects/architects.module').then(m => m.ArchitectsModule),
      }
    ],
    canActivate: [AuthGuard],
    data: { roles: ['Junior Architect', 'Lead Architect', 'Senior Architect', 'External',] }
  },
  {
    path: '',
    component: VendorLayoutComponent,
    children: [
      {
        path: 'vendor',
        loadChildren: () => import('./modules/vendor-main/vendor-main.module').then(m => m.VendorMainPageModule)
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['Vendor'] }

  },
  {
    path: '',
    component: ClientLayoutComponent,
    children: [
      {
        path: 'client',
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule)
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['Client'] }

  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
